import React from "react";
import TelaLogin from "SGP-components/Login/TelaLogin";

const PaginaLogin = () => {

  return (
    <>
      <TelaLogin />
    </>
  );
};

export default PaginaLogin;
