import React, { createContext, useState } from "react";

export const ModalsContext = createContext();

export function ModalsContextProvider({ children }) {
  //Estado que guarda a imagem da janela de view que será mostrada
  const [image, setImage] = useState("");

  //Estado que controla quando a janela de imagens será aberta
  const [showImage, setShowImage] = useState(false);

  //Estado que controla a janela viewModal, usado por mainTable e viewModal
  const [viewModal, setViewModal] = useState(false);

  const [identificarLavagemModal, setIdentificarLavagemModal] = useState(false);

  //Estado que controla a janela createModal, usado por mainTable e createModal
  const [createModal, setCreateModal] = useState(false);

  //Estado que controla a janela de adicionar scales na balança
  const [plataformaModal, setPlataformaModal] = useState(false);

  const [deletarModal, setDeletarModal] = useState(false);

  const [editarModal, setEditarModal] = useState(false);

  const [taraModal, setTaraModal] = useState({
    estado: false,
    callback: function () {},
  });

  const [sucessoTarar, setSucessoTarar] = useState(false);

  const [falhaTarar, setFalhaTarar] = useState({
    estado: false,
    mesagemDeErro: "",
  });

  const [tararLoading, setTararLoading] = useState(false);

  const [nfModal, setNfModal] = useState({
    estado: false,
    callback: function () {},
  });
  const [sucessoCadastro, setSucessoCadastro] = useState({
    estao: false,
    mensagem: "",
  });

  const [nenhumRegistro, setNenhumRegistro] = useState({
    estado: false,
    mensagemDeErro: "",
  });

  const [falhaCadastro, setFalhaCadastro] = useState({
    estado: false,
    mensagemDeErro: "",
  });

  const [incluirRotaModal, setIncluirRotaModal] = useState(false);

  const [deletarRotaModal, setDeletarRotaModal] = useState({
    estado: false,
    rotaNome: "",
  });

  const [incluirPontoModal, setIncluirPontoModal] = useState(false);

  const [identificarModal, setIdentificarModal] = useState(false);

  const [corrigirIdentificacaoModal, setCorrigirIdentificacaoModal] = useState(false);

  const [rotaInfoModal, setRotaInfoModal] = useState({
    estado: false,
    route: {},
  });

  const [adicionarCaminhaoNaRota, setAdicionarCaminhaoNaRota] = useState({
    estado: false,
    data: {},
  });

  const [cancelarPesagemModal, setCancelarPesagemModal] = useState(false);

  const [retirarNF, setRetirarNF] = useState(false);

  const [trocarRotaModal, setTrocarRotaModal] = useState(false);

  const [adicionarRotaManualModal, setAdicionarRotaManualModal] =
    useState(false);

  const [resultadoListaCaminhoesModal, setResultadoListaCaminhoesModal] =
    useState({
      estado: false,
      data: {},
    });
  const [cancelamentoModal, setCancelamentoModal] =
    useState({
      estado: false,
      data: {},
    });

  const [trocarCaminhoesDeRotaModal, setTrocarCaminhoesDeRotaModal] = useState({
    estado: false,
    data: {},
  });

  const [limparRotaModal, setLimparRotaModal] = useState({
    estado: false,
    data: { rotaId: "", caminhoes: [], rotaNome: "" },
  });

  //Função que troca o estado da janela view, chamada por maintable e viewModal
  const switchViewModal = () => {
    setViewModal(!viewModal);
  };

  const lavagemIdentificarModal = () => {
    setIdentificarLavagemModal(!identificarLavagemModal);
  };

  //Função que troca o estado da janela create, chamada por maintable e createModal
  const switchCreateModal = () => {
    setCreateModal(!createModal);
  };

  const [resultadoInclusaoManual, setResultadoInclusaoManual] = useState({estado: false, data:{}})
  return (
    <ModalsContext.Provider
      value={{
        resultadoInclusaoManual,
        setResultadoInclusaoManual,
        image,
        setImage,
        showImage,
        setShowImage,
        viewModal,
        setViewModal,
        identificarLavagemModal,
        setIdentificarLavagemModal,
        createModal,
        setCreateModal,
        switchViewModal,
        lavagemIdentificarModal,
        switchCreateModal,
        plataformaModal,
        setPlataformaModal,
        deletarModal,
        setDeletarModal,
        editarModal,
        setEditarModal,
        taraModal,
        setTaraModal,
        sucessoTarar,
        setSucessoTarar,
        falhaTarar,
        setFalhaTarar,
        tararLoading,
        setTararLoading,
        nfModal,
        setNfModal,
        sucessoCadastro,
        setSucessoCadastro,
        falhaCadastro,
        setFalhaCadastro,
        nenhumRegistro,
        setNenhumRegistro,
        incluirRotaModal,
        setIncluirRotaModal,
        deletarRotaModal,
        setDeletarRotaModal,
        incluirPontoModal,
        setIncluirPontoModal,
        identificarModal,
        setIdentificarModal,
        corrigirIdentificacaoModal,
        setCorrigirIdentificacaoModal,
        rotaInfoModal,
        setRotaInfoModal,
        cancelarPesagemModal,
        setCancelarPesagemModal,
        adicionarRotaManualModal,
        setAdicionarRotaManualModal,
        adicionarCaminhaoNaRota,
        setAdicionarCaminhaoNaRota,
        trocarRotaModal,
        setTrocarRotaModal,
        resultadoListaCaminhoesModal,
        setResultadoListaCaminhoesModal,
        cancelamentoModal,
        setCancelamentoModal,
        trocarCaminhoesDeRotaModal,
        setTrocarCaminhoesDeRotaModal,
        retirarNF,
        setRetirarNF,
        limparRotaModal,
        setLimparRotaModal,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
}
