import * as Yup from "yup";

const placaRegex = /^[A-Z]{3}-[0-9][0-9A-Z][0-9]{2}$/;
const numbersOnlyRegex = /^[0-9]+$/;

export const PlacaENumeroFrotaSchema = Yup.object().shape({
  placa: Yup.string().matches(placaRegex, "Formato de placa inválido"),
  placaCarreta: Yup.string().matches(placaRegex, "Formato de placa inválido"),
  numeroFrota: Yup.string().matches(
    numbersOnlyRegex,
    "Numero da frota deve conter apenas números"
  ),
  chaveTBe: Yup.string()
        .matches(/^[A-F0-9]{32}$/, "A chave é invalida")
});
