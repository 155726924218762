import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { ModalsContext } from "SGP-contexts/ModalsContext";

export const ImageModal = () => {
  const { image, showImage, setShowImage } = useContext(ModalsContext);

  return (
    <>
      {showImage && (
        <div
          onClick={() => {
            setShowImage(false);
          }}
        >
          <Modal
            show={showImage}
            size="xl"
            onClick={(event) => event.stopPropagation()}
          >
            <img src={image} alt="caminhão" className="rounded"></img>
            <button
              className="position-absolute top-0 end-0 btn-close "
              onClick={() => {
                setShowImage(!showImage);
              }}
              aria-label="Close"
            ></button>
          </Modal>
        </div>
      )}
    </>
  );
};
