import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import { PlacaENumeroFrotaSchema } from "Schemas/PlacaENumeroFrotaSchema";
import { placaMask } from "Schemas/Masks";

const TIPO_VEICULO = {
  externo: 1,
  interno: 2,
  avulso: 3,
};

export const TararModal = ({ dadosPesagem, tokenPesagemSelecionada }) => {
  const {
    taraModal,
    setTaraModal,
    setShowImage,
    setImage,
    setSucessoTarar,
    setFalhaTarar,
    tararLoading,
    setTararLoading,
  } = useContext(ModalsContext);

  const apiUrl = process.env.REACT_APP_APIURL;

  const [registro] = dadosPesagem.filter(
    (item) => item.tokenPesagem === tokenPesagemSelecionada
  );

  const [formCarreta, setFormCarreta] = useState(
    registro?.numeroFrota === null ? true : false
  );
  const [formFrota, setFormFrota] = useState(
    registro?.numeroFrota !== null ? true : false
  );

  const [formAvulso, setFormAvulso] = useState(false);

  const [dadosTara, setDadosTara] = useState({});

  useEffect(() => {
    if (taraModal.estado) {

      console.log('registro aqui ', registro);

      setDadosTara({
        ...dadosTara,
        TokenPesagem: registro?.tokenPesagem,
        TipoVeiculo:
          registro?.numeroFrota !== null
            ? TIPO_VEICULO["interno"]
            : TIPO_VEICULO["externo"],
        IdentificacaoVeiculo:
          registro?.numeroFrota !== null ? String(registro?.numeroFrota) : "",
      });
    }

    setFormCarreta(registro?.numeroFrota === null ? true : false);
    setFormFrota(registro?.numeroFrota !== null ? true : false);
    // eslint-disable-next-line
  }, [taraModal.estado]);

  return (
    <>
      {taraModal.estado && (
        <div
          onClick={() =>
            setTaraModal({ ...taraModal, estado: !taraModal.estado })
          }
        >
          <Modal
            show={taraModal.estado}
            onClick={(event) => event.stopPropagation()}
          >
            <Modal.Header
              closeButton
              onHide={() =>
                setTaraModal({ ...taraModal, estado: !taraModal.estado })
              }
            >
              <Modal.Title>Tara</Modal.Title>
            </Modal.Header>

            <Formik
              onSubmit={(values) => {
                const dados = dadosTara;

                if (formCarreta) {
                  dados.IdentificacaoVeiculo = values.placa;
                } else if (formFrota) {
                  dados.IdentificacaoVeiculo = values.numeroFrota;
                } else if (formAvulso) {
                  dados.IdentificacaoVeiculo = values.placa;
                  dados.TipoVeiculo = TIPO_VEICULO["avulso"];
                }


                setDadosTara(dados);

                setTararLoading(true);
                axios
                  .post(`${apiUrl}/apiwebapp/Pesagem/TararVeiculo`, dados)
                  .then((response) => {
                    taraModal.callback().then(() => {
                      setSucessoTarar(response.data.sucesso);

                      setTaraModal({ ...taraModal, estado: !taraModal.estado });

                      setTararLoading(false);
                      setFormFrota(false);
                      setFormCarreta(false);
                      setFormAvulso(false);
                    });
                  })
                  .catch((error) => {
                    setFalhaTarar({
                      estado: true,
                      mensagemDeErro: `Não foi possível salvar a tara para o veículo ${dados.IdentificacaoVeiculo}.`,
                    });

                    setTaraModal({ ...taraModal, estado: !taraModal.estado });
                    setFormFrota(false);
                    setFormCarreta(false);
                    setFormAvulso(false);
                    setTararLoading(false);
                  });
              }}
              initialValues={{
                placa: "",
                numeroFrota:
                  registro?.numeroFrota !== null
                    ? String(registro?.numeroFrota)
                    : "",
              }}
              validationSchema={PlacaENumeroFrotaSchema}
              validateOnBlur
            >
              {({ errors, setFieldValue, touched, resetForm }) => {
                return (
                  <>
                    <Modal.Body>
                      <h4 className="mt-1 mb-2">
                        Confirma tara de{" "}
                        {new Intl.NumberFormat("de-DE").format(registro?.peso)}{" "}
                        kg?
                      </h4>

                      <fieldset className="mt-5">
                        <div className="row ">
                          <div className="col-form-label col-sm-2 pt-0">
                            Tipo
                          </div>
                          <div className="col">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="frota"
                                value="Frota"
                                name="tipoVeiculo"
                                onChange={() => {
                                  setFormFrota(true);
                                  setFormCarreta(false);
                                  setFormAvulso(false);
                                  setDadosTara({
                                    ...dadosTara,
                                    IdentificacaoVeiculo: "",
                                    TipoVeiculo: TIPO_VEICULO["interno"],
                                  });
                                  resetForm();
                                }}
                                checked={formFrota ? true : false}
                                style={{ cursor: "pointer" }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="frota"
                              >
                                Frota
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="carreta"
                                value="Carreta"
                                name="tipoVeiculo"
                                onChange={() => {
                                  setFormCarreta(true);
                                  setFormFrota(false);
                                  setFormAvulso(false);
                                  setDadosTara({
                                    ...dadosTara,
                                    IdentificacaoVeiculo: "",
                                    TipoVeiculo: TIPO_VEICULO["externo"],
                                  });
                                  resetForm();
                                }}
                                checked={formCarreta ? true : false}
                                disabled={
                                  registro?.numeroFrota !== null ? true : false
                                }
                                style={{ cursor: "pointer" }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="carreta"
                              >
                                Carreta
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="avulso"
                                value="Avulso"
                                name="tipoVeiculo"
                                onChange={() => {
                                  setFormCarreta(false);
                                  setFormFrota(false);
                                  setFormAvulso(true);
                                  setDadosTara({
                                    ...dadosTara,
                                    IdentificacaoVeiculo: "",
                                    TipoVeiculo: TIPO_VEICULO["avulso"],
                                  });
                                  resetForm();
                                }}
                                checked={formAvulso ? true : false}
                                style={{ cursor: "pointer" }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="avulso"
                              >
                                Avulso
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <Form>
                        {formCarreta && (
                          <fieldset className="my-3">
                            <div className="form-group row align-items-center">
                              <div className="col-sm-2 ">
                                <label
                                  htmlFor="placa"
                                  className="col-form-label py-0"
                                >
                                  Placa
                                </label>
                              </div>
                              <div className="col">
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="placa"
                                  id="placa"
                                  placeholder="Placa do caminhão"
                                  onChange={(event) => {
                                    const value = event.target.value || "";
                                    setFieldValue(
                                      "placa",
                                      placaMask(value.toUpperCase())
                                    );
                                  }}
                                />
                                {errors.placa && touched.placa ? (
                                  <small className="d-flex w-100 text-danger py-1">
                                    *{errors.placa}
                                  </small>
                                ) : null}
                              </div>
                            </div>
                          </fieldset>
                        )}

                        {formFrota && (
                          <fieldset className="my-3">
                            <div className="form-group row align-items-center">
                              <div className="col-sm-2 ">
                                <label
                                  htmlFor="numeroFrota"
                                  className="col-form-label py-0"
                                >
                                  Número da frota
                                </label>
                              </div>
                              <div className="col">
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="numeroFrota"
                                  id="numeroFrota"
                                  placeholder="Numero da frota"
                                  onLoad={() =>
                                    setFieldValue(
                                      "numeroFrota",
                                      registro?.numeroFrota !== null
                                        ? String(registro?.numeroFrota)
                                        : dadosTara.IdentificacaoVeiculo
                                    )
                                  }
                                  disabled={
                                    registro?.numeroFrota !== null
                                      ? true
                                      : false
                                  }
                                />
                                {errors.numeroFrota && touched.numeroFrota ? (
                                  <small className="d-flex w-100 text-danger py-1">
                                    *{errors.numeroFrota}
                                  </small>
                                ) : null}
                              </div>
                            </div>
                          </fieldset>
                        )}


                        {formAvulso && (
                          <fieldset className="my-3">
                            <div className="form-group row align-items-center">
                              <div className="col-sm-2 ">
                                <label
                                  htmlFor="placa"
                                  className="col-form-label py-0"
                                >
                                  Placa
                                </label>
                              </div>
                              <div className="col">
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="placa"
                                  id="placa"
                                  placeholder="Placa do veiculo"
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    setFieldValue(
                                      "placa",
                                      placaMask(value.toUpperCase())
                                    );
                                  }}
                                />
                                {errors.placa && touched.placa ? (
                                  <small className="d-flex w-100 text-danger py-1">
                                    *{errors.placa}
                                  </small>
                                ) : null}
                              </div>
                            </div>
                          </fieldset>
                        )}

                        {registro?.urlImagens && (
                          <>
                            <Modal.Title>Fotos</Modal.Title>
                            <div className="custom-separator"></div>
                            <div className="row text-center ">
                              {registro?.urlImagens &&
                                registro?.urlImagens.map((image, index) => (
                                  <div key={index}>
                                    <div className="col">
                                      <img
                                        src={image}
                                        alt="Imagem do caminhão"
                                        key={index}
                                        className="w-75 m-2 rounded cursor-pointer"
                                        onClick={() => {
                                          setShowImage(true);
                                          setImage(image);
                                        }}
                                      />
                                    </div>
                                    <div className="w-100"></div>
                                  </div>
                                ))}
                            </div>
                          </>
                        )}

                        <Modal.Footer>
                          <Button
                            variant="outline-success"
                            type="submit"
                            disabled={tararLoading ? true : false}
                          >
                            {tararLoading ? (
                              <Spinner
                                animation="border"
                                variant="success"
                                size="sm"
                              />
                            ) : (
                              `Salvar`
                            )}
                          </Button>
                          <Button
                            variant="outline-danger"
                            disabled={tararLoading ? true : false}
                            onClick={() => {
                              setTaraModal({
                                ...taraModal,
                                estado: !taraModal.estado,
                              })
                              setFormFrota(false);
                              setFormCarreta(false);
                              setFormAvulso(false);
                            }
                            }
                          >
                            Cancelar
                          </Button>
                        </Modal.Footer>
                      </Form>
                    </Modal.Body>
                  </>
                );
              }}
            </Formik>
          </Modal>
        </div>
      )}
    </>
  );
};
