import React, { useContext, useState } from "react";
import { Modal, Button, Spinner, Table } from "react-bootstrap";
import { FilterContext } from "SGP-contexts/FilterContext";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import { IdentificarModalSchema } from "./../../Schemas/IdentificarModalSchema";
import { formatDate } from "utils/formatDate";
import ButtonWithLoading from "./ButtonWithLoading";
import { dateMask, cnpjMask, placaMask } from "Schemas/Masks";
import { set } from "lodash";

export const IdentificarModal = ({
  dadosPesagem,
  tokenPesagemSelecionada,
  setDadosPesagem,
}) => {
  const { urlUltimoRequest } = useContext(FilterContext);
  const {
    identificarModal,
    setShowImage,
    setImage,
    setIdentificarModal,
    setFalhaCadastro,
    setNenhumRegistro,
    setSucessoCadastro,
  } = useContext(ModalsContext);
  const [loading, setLoading] = useState(false);

  const [tipoVeiculo, setTipoVeiculo] = useState("interno");

  const [produtos, setProdutos] = useState([]);
  const [selectedProdutoNome, setSelectedProdutoNome] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isValidCNPJ, setIsValidCNPJ] = useState(true);

  const [caminhao] = dadosPesagem.filter(
    (item) => item.tokenPesagem === tokenPesagemSelecionada
  );

  const apiUrl = process.env.REACT_APP_APIURL;

  const getRequest = () => {
    axios
      .get(urlUltimoRequest)
      .then((res) => {
        setDadosPesagem(res.data.retorno);
        setIdentificarModal(false);

        setSucessoCadastro({
          estado: true,
          mensagem: "Veículo identificado com sucesso",
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const getProdutos = () => {
    axios
      .get(`${apiUrl}/api/Produtos?OnlyActive=true`)
      .then((res) => {
        setProdutos(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formDataEndpoint = (date) => {
    date = date.replace(/\D/g, "");
    const day = date.slice(0, 2);
    const month = date.slice(2, 4);
    const year = date.slice(4, 8);
    return `${year}-${month}-${day}`;
  };

  const putRequest = (values) => {
    if (pesagemAvulsa === true && numFrota === false) {
      const requestBody = {
        numero: responseFluig.data.pedidos[0].numero,
        dataEmissao: formDataEndpoint(
          responseFluig.data.pedidos[0].dataEmissao
        ),
        fornecedor: responseFluig.data.pedidos[0].fornecedor,
        produto: responseFluig.data.pedidos[0].produto,
        descProduto: responseFluig.data.pedidos[0].desc_produto,
        qtdSolicitada: parseInt(responseFluig.data.pedidos[0].qtdSolicitada),
        qtdEntregue: parseFloat(responseFluig.data.pedidos[0].qtdEntregue),
        nomeFornecedor: responseFluig.data.nome_fornecedor,
        quantidade: responseFluig.data.quantidade,
        status: responseFluig.data.status,
      };

      axios
        .post(
          `${apiUrl}/apiwebapp/PesagemAvulsa/IdentificarPesagemAvulsa?TokenPesagem=${caminhao?.tokenPesagem}&placa=${placa}`,
          requestBody
        )
        .then(async () => {
          await getRequest();
          clearStates();
        })
        .catch((error) => {
          clearStates();
          setIdentificarModal(false);
          setLoading(false);
          setFalhaCadastro({
            estado: true,
            mensagemDeErro: error.response.data.mensagem,
          });
        });
    } else if (pesagemAvulsa === false && numFrota === true) {
      const requestBody = {
        balanca: caminhao?.balanca,
        plataforma: caminhao?.plataforma,
        token: caminhao?.tokenPesagem,
        frota: values.numeroFrota,
      };

      axios
        .put(
          `${apiUrl}/apiwebapp/Pesagem/IdentificarPesagem?balanca=${requestBody.balanca}&plataforma=${requestBody.plataforma}&token=${requestBody.token}&frota=${requestBody.frota}`
        )
        .then(() => {
          getRequest();
          clearStates();
        })
        .catch((error) => {
          clearStates();
          setIdentificarModal(false);
          setLoading(false);
          setFalhaCadastro({
            estado: true,
            mensagemDeErro: error.response.data.mensagem,
          });
        });
    }
  };

  const pesoFormatado = new Intl.NumberFormat("pt-BR").format(caminhao?.peso);
  const pesoLiquidoFormatado = new Intl.NumberFormat("pt-BR").format(
    caminhao?.pesoLiquido
  );
  const dataFormatada = formatDate(caminhao?.dataHoraUTCWeb);

  const [numFrota, setNumFrota] = useState(false);
  const [pesagemAvulsa, setPesagemAvulsa] = useState(false);
  const [cnpjFornecedor, setCnpjFornecedor] = useState("");
  const [loadingFluig, setLoadingFluig] = useState(false);
  const [selectPedido, setSelectPedido] = useState(false);
  const [placa, setPlaca] = useState("");
  const [found, setFound] = useState(false);

  const [responseFluig, setResponseFluig] = useState({
    status: false,
    data: {},
    retornado: false,
  });

  const clearStates = () => {
    setNumFrota(false);
    setPesagemAvulsa(false);
    setCnpjFornecedor("");
    setPlaca("");
    setSelectedProdutoNome("");
    setResponseFluig({
      status: false,
      data: {},
    });
  };
  const clearStatesPed = () => {
    setCnpjFornecedor("");
    setResponseFluig({
      status: false,
      data: {},
    });
  };
const formDataFluig = (date) => {
    if (date === "") {
      const today = new Date();
      const year = today.getFullYear().toString();
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const day = today.getDate().toString().padStart(2, '0');
      return `${day}/${month}/${year}`;
    } else {
      const year = date.slice(0, 4);
      const month = date.slice(4, 6);
      const day = date.slice(6, 8);
      return `${day}/${month}/${year}`;
    }
};

  const consultaPedidoProtheus = async () => {
    setLoadingFluig(true);

    try {
      await getProdutos();
      const response = await axios.post(
        `${apiUrl}/apiwebapp/Pesagem/BuscaPedidosProtheus?cnpjFornecedor=${cnpjFornecedor}`
      );

      if (
        response.data.sucesso === true &&
        !response.data.retorno.includes("errorCode")
      ) {
        setFound(true);
        const data = JSON.parse(response.data.retorno);
        //formata datas
        data.pedidos = data.pedidos.map((pedido) => {
          pedido.dataEmissao = formDataFluig(pedido.dataEmissao);
          return pedido;
        });
        if (data.pedidos.length > 1) {
          setResponseFluig({ status: false, data: data, retornado: true });
          setSelectPedido(true);
        } else if (data.pedidos.length === 1) {
          setResponseFluig({ status: true, data: data, retornado: true });
        }
        setLoadingFluig(false);
      } else {
        setResponseFluig({
          status: true,
          data: {
            pedidos: [
              {
                numero: "",
                dataEmissao: "",
                fornecedor: removeCnpjMask(cnpjFornecedor).substring(0, 8),
                produto: "",
                desc_produto: "",
                qtdSolicitada: 0,
                qtdEntregue: 0,
              },
            ],
            nome_fornecedor: "",
            quantidade: 0,
            status: "",
          },
          retornado: false,
        });
        setNenhumRegistro({
          estado: true,
          mensagemDeErro: "Pedido não encontrado",
        });
      }
    } catch (error) {
      setResponseFluig({
        status: true,
        data: {
          pedidos: [
            { 
              numero: "",
              dataEmissao: "",
              fornecedor: removeCnpjMask(cnpjFornecedor).substring(0, 8),
              produto: "",
              desc_produto: "",
              qtdSolicitada: 0,
              qtdEntregue: 0,
            },
          ],
          nome_fornecedor: "",
          quantidade: 0,
          status: "",
        },
        retornado: false,
      });
      setNenhumRegistro({
        estado: true,
        mensagemDeErro: "Pedido não encontrado",
      });
    } finally {
      setLoadingFluig(false);
    }
  };
  function validateCNPJ(cnpj) {
    const regex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
    if (regex.test(cnpj) == true) {
      setValidationMessage("");
      consultaPedidoProtheus();
      return setIsValidCNPJ(true);
    } else {
      setValidationMessage("Digite um CNPJ válido");
      return setIsValidCNPJ(false);
    }
  }
  function validateDescProduto(descProduto) {

  }

  const removeCnpjMask = (cnpj) => {
    return cnpj.replace(/\D/g, "");
  };

  return (
    <>
      {identificarModal && (
        <div>
          {!selectPedido ? (
            <Modal
              show={identificarModal}
              scrollable={true}
              onClick={(event) => event.stopPropagation()}
            >
              <Modal.Header
                closeButton
                onHide={() => {
                  setIdentificarModal(false);
                  clearStatesPed();
                  clearStates();
                }}
              >
                <Modal.Title>Identificação do veículo</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Formik
                  validationSchema={IdentificarModalSchema}
                  onSubmit={(values) => {
                    setLoading(true);
                    putRequest(values);
                  }}
                  initialValues={{
                    numeroFrota: "",
                    placa: "",
                    pedidoAvulso: responseFluig.data,
                  }}
                >
                  {({ errors, touched }) => (
                    <Form id="idenficacaoCaminhao">
                      <div>
                        <div className="row">
                          <div className="col">
                            <label htmlFor="idPesagem">ID da pesagem</label>
                            <input
                              type="text"
                              className="form-control"
                              name="idPesagem"
                              value={caminhao?.tokenPesagem}
                              disabled
                              id="idPesagem"
                            />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-6 form-group ">
                            <label htmlFor="Data">Data</label>
                            <input
                              type="text"
                              className="form-control"
                              name="data"
                              value={dataFormatada}
                              disabled
                              id="Data"
                            />
                          </div>

                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="Plataforma">Balança</label>
                            <input
                              type="text"
                              className="form-control"
                              name="balanca"
                              value={caminhao?.balanca}
                              disabled
                              id="Balanca"
                            />
                          </div>

                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="Plataforma">Plataforma</label>
                            <input
                              type="text"
                              className="form-control"
                              name="plataforma"
                              value={caminhao?.plataforma}
                              disabled
                              id="Plataforma"
                            />
                          </div>

                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="Peso">Peso (Kg)</label>
                            <input
                              type="text"
                              className="form-control"
                              name="peso"
                              value={pesoFormatado}
                              disabled
                              id="Peso"
                            />
                          </div>

                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="pesoLiquido">
                              Peso líquido (kg)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="pesoLiquido"
                              value={pesoLiquidoFormatado}
                              disabled
                              id="pesoLiquido"
                            />
                          </div>

                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="sentido">Sentido</label>
                            <input
                              type="text"
                              className="form-control"
                              name="sentido"
                              value={
                                caminhao?.sentido ? caminhao?.sentido : "--"
                              }
                              id="sentido"
                              disabled
                            />
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frota"
                              value="Frota"
                              name="identFrota"
                              onChange={() => {
                                setNumFrota(true);
                                setPesagemAvulsa(false);
                                clearStatesPed();
                              }}
                              checked={numFrota}
                              style={{ cursor: "pointer" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="frota"
                              style={{ marginLeft: "5px" }}
                            >
                              Número Frota
                            </label>
                          </div>

                          <div className="col-md-6 form-group mb-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="pesAvulsa"
                              value="pesAvulsa"
                              name="pesAvulsa"
                              onChange={() => {
                                setPesagemAvulsa(true);
                                setNumFrota(false);
                                clearStatesPed();
                              }}
                              checked={pesagemAvulsa}
                              style={{ cursor: "pointer" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="pesAvulsa"
                              style={{ marginLeft: "5px" }}
                            >
                              Pesagem Avulsa
                            </label>
                          </div>

                          {numFrota ? (
                            <>
                              <div className="col-md-6 form-group mb-3">
                                <label htmlFor="numeroFrota">
                                  Numero da frota *
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="numeroFrota"
                                  id="numeroFrota"
                                  placeholder="Digite o número da frota"
                                  required
                                />
                                {errors.numeroFrota && touched.numeroFrota ? (
                                  <small className="d-flex w-100 text-danger py-1">
                                    *{errors.numeroFrota}
                                  </small>
                                ) : null}
                              </div>
                            </>
                          ) : null}

                          {pesagemAvulsa ? (
                            <>
                              <div className="col-md-12 form-group mb-3">
                                <label htmlFor="numeroFrota">
                                  CNPJ do fornecedor:
                                </label>
                                <input
                                  id="cnpjFornecedor"
                                  type="text"
                                  className="form-control"
                                  placeholder="Digite o CNPJ do fornecedor"
                                  value={cnpjMask(cnpjFornecedor)}
                                  maxLength={18}
                                  onChange={(e) => {
                                    setCnpjFornecedor(
                                      removeCnpjMask(e.target.value)
                                    );
                                    if (
                                      removeCnpjMask(e.target.value).length ===
                                      14
                                    ) {
                                      setIsValidCNPJ(true);
                                      setValidationMessage("");
                                    }
                                  }}
                                />
                              </div>
                              <p style={{ color: "red" }}>
                                {validationMessage}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <Button
                                  disabled={!isValidCNPJ}
                                  onClick={() => {
                                    validateCNPJ(cnpjMask(cnpjFornecedor));
                                  }}
                                >
                                  {loadingFluig ? (
                                    <Spinner size="sm" />
                                  ) : (
                                    "Consultar pedidos"
                                  )}
                                </Button>
                                {/* <Button
                                  onClick={() => {
                                    setResponseFluig({
                                      status: true,
                                      data: {
                                        pedidos: [
                                          {
                                            numero: "",
                                            dataEmissao: "",
                                            fornecedor: "",
                                            produto: "",
                                            desc_produto: "",
                                            qtdSolicitada: 0,
                                            qtdEntregue: 0
                                          },
                                        ],
                                        nome_fornecedor: "",
                                        quantidade: 0,
                                        status: ""
                                      },
                                      retornado: false
                                    });
                                  }}
                                >
                                  Preencher manualmente
                                </Button> */}
                              </div>
                              {responseFluig.status ? (
                                <section style={{ marginTop: "15px" }}>
                                  <div className="row">
                                    <div className="col-md-6 form-group mb-3">
                                      <label htmlFor="sentido">Placa</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="placa"
                                        value={placa}
                                        required
                                        id="placa"
                                        onChange={(e) => {
                                          const value = e.target.value || "";
                                          setPlaca(
                                            placaMask(value.toUpperCase())
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                      <label>Código do fornecedor:</label>
                                      <input
                                        id="fornecedor"
                                        type="text"
                                        className="form-control"
                                        value={removeCnpjMask(
                                          cnpjFornecedor
                                        ).substring(0, 8)}
                                        disabled
                                      />
                                    </div>
                                    <div className="col-md-12 form-group mb-3">
                                      <label>Nome do fornecedor:</label>
                                      <input
                                        id="nome_fornecedor"
                                        type="text"
                                        className="form-control"
                                        required
                                        value={
                                          responseFluig.data.nome_fornecedor
                                        }
                                        onChange={(e) => {
                                          setResponseFluig({
                                            ...responseFluig,
                                            data: {
                                              ...responseFluig.data,
                                              nome_fornecedor: e.target.value,
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                      <label>Data de emissão:</label>
                                      <input
                                        id="dataEmissao"
                                        type="text"
                                        className="form-control"
                                        required
                                        value={
                                          responseFluig.data.pedidos[0]
                                            .dataEmissao
                                        }
                                        onChange={(e) => {
                                          const formattedValue = dateMask(
                                            e.target.value
                                          );
                                          setResponseFluig({
                                            ...responseFluig,
                                            data: {
                                              ...responseFluig.data,
                                              pedidos: [
                                                {
                                                  ...responseFluig.data
                                                    .pedidos[0],
                                                  dataEmissao: formattedValue,
                                                },
                                              ],
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                      <label>Numero do pedido:</label>
                                      <input
                                        id="numPedido"
                                        required
                                        type="text"
                                        className="form-control"
                                        value={
                                          responseFluig.data.pedidos[0].numero
                                        }
                                        pattern="^[0-9]*$"
                                        title="Por favor, insira apenas números."
                                        onChange={(e) => {
                                          const { value } = e.target;
                                          if (
                                            value === "" ||
                                            /^[0-9]*$/.test(value)
                                          ) {
                                            setResponseFluig({
                                              ...responseFluig,
                                              data: {
                                                ...responseFluig.data,
                                                pedidos: [
                                                  {
                                                    ...responseFluig.data
                                                      .pedidos[0],
                                                    numero: value,
                                                  },
                                                ],
                                              },
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                      <label>Código do produto:</label>
                                      <input
                                        id="codProduto"
                                        type="text"
                                        className="form-control"
                                        value={
                                          responseFluig.data.pedidos[0].produto
                                        }
                                        disabled
                                      />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                      <label>Descrição do produto:</label>
                                      <select
                                        id="descProduto"
                                        className="form-control"
                                        required
                                        value={selectedProdutoNome}
                                        onChange={(e) => {
                                          if (found) {
                                            setSelectedProdutoNome(
                                              responseFluig.data.pedidos[0]
                                                .produto
                                            );
                                          } else {
                                            const selectedProduto =
                                              produtos.find(
                                                (produto) =>
                                                  produto.nome ===
                                                  e.target.value
                                              );
                                            const updatedPedido = {
                                              ...responseFluig.data.pedidos[0],
                                              produto:
                                                (selectedProduto?.produtoId).toString(),
                                              desc_produto: e.target.value,
                                            };

                                            setResponseFluig({
                                              ...responseFluig,
                                              data: {
                                                ...responseFluig.data,
                                                pedidos: [updatedPedido],
                                              },
                                            });

                                            setSelectedProdutoNome(
                                              selectedProduto?.nome
                                            );
                                          }
                                        }}
                                      >
                                        {found ? (
                                          <option
                                            value={
                                              responseFluig.data.pedidos[0]
                                                .desc_produto
                                            }
                                          >
                                            {
                                              responseFluig.data.pedidos[0]
                                                .desc_produto
                                            }
                                          </option>
                                        ) : (
                                          <>
                                            <option value="">
                                              Selecione um produto
                                            </option>
                                            {produtos && produtos.length > 0 ? (
                                              produtos.map((produto) => (
                                                <option
                                                  key={produto.produtoId}
                                                  value={produto.nome}
                                                >
                                                  {produto.nome}
                                                </option>
                                              ))
                                            ) : (
                                              <option value="">
                                                Nenhum produto encontrado
                                              </option>
                                            )}
                                          </>
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                </section>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      </div>

                      <section>
                        <br />
                        <Modal.Title>Fotos</Modal.Title>
                        <div className="custom-separator"></div>
                        <div className="row text-center ">
                          {caminhao?.urlImagens !== null &&
                            caminhao?.urlImagens.map((info, index) => (
                              <div key={index}>
                                <div className="col">
                                  <img
                                    src={info}
                                    alt="Imagem do caminhão"
                                    className="w-75 m-2 rounded cursor-pointer"
                                    onClick={() => {
                                      setShowImage(true);
                                      setImage(info);
                                    }}
                                  />
                                </div>
                                <div className="w-100"></div>
                              </div>
                            ))}
                        </div>
                      </section>
                    </Form>
                  )}
                </Formik>
              </Modal.Body>
              <Modal.Footer>
                <ButtonWithLoading
                  buttonVariant="outline-success"
                  loading={loading}
                  spinnerVariant="success"
                  form="idenficacaoCaminhao"
                >
                  Salvar
                </ButtonWithLoading>

                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setIdentificarModal(false);
                    clearStates();
                    clearStatesPed();
                  }}
                  disabled={loading}
                >
                  Cancelar
                </Button>
              </Modal.Footer>
            </Modal>
          ) : (
            <Modal show={selectPedido} size="lg" centered>
              <Modal.Header>
                <Modal.Title>Selecionar Pedido Protheus</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex justify-content-center">
                  <Table>
                    <thead>
                      <tr>
                        <th>Numero do pedido</th>
                        <th>Data de emissão</th>
                        <th>Codigo do produto</th>
                        <th>Descrição do produto</th>
                      </tr>
                    </thead>
                    <tbody>
                      {responseFluig.data.pedidos.map((pedido, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setResponseFluig({
                              status: true,
                              data: {
                                ...responseFluig.data,
                                pedidos: [pedido],
                              },
                            });
                            setSelectPedido(false);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{pedido.numero}</td>
                          <td>{pedido.dataEmissao}</td>
                          <td>{pedido.produto}</td>
                          <td>{pedido.desc_produto}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setSelectPedido(false);
                    clearStatesPed();
                    clearStates();
                  }}
                >
                  Cancelar
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};
