import { useField } from "formik";
import React from "react";
import Select from "react-select";

const CustomSelectField = (props) => {
  const [_, __, { setValue, setTouched }] = useField(props.field.name);

  const onChange = (value) => {
    setValue(value);
  };

  return (
    <Select
      options={props.options}
      defaultValue={props.defaultValue}
      noOptionsMessage={() => props.noOptionsMessage}
      onChange={onChange}
      onBlur={setTouched}
      isMulti={props.isMulti}
      placeholder={props.placeholder || "Clique para pesquisar..."}
      {...props}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          borderColor: "#CED4DA",
          backgroundColor: "#F8F9FA",
          outline: "none",
          boxShadow: "none",
          "&:hover": {
            borderColor: "#CED4DA",
          },
        }),
        option: (baseStyles, { isFocused }) => ({
          ...baseStyles,
          display: "flex",
          justifyContent: "start",
          backgroundColor: isFocused ? "#F8F9FA" : "white",
          color: "black",
        }),
      }}
    />
  );
};

export default CustomSelectField;
