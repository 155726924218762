import React, { useState, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import SimpleCard from "../../@gull/components/cards/SimpleCard";
import { UnauthenticatedTemplate } from "@azure/msal-react";
import { formatDate } from "utils/formatDate";
import Axios from "axios";
import { NavigationBarUnauthenticated } from "SGP-components/NavigationBar";

const ViewerPdfQrCode = () => {
    const location = useLocation();
    const [pdfUrl, setPdfUrl] = useState('');
    const [dataEmissao, setDataEmissao] = useState('');
    const [downloadXMLloading, setDownloadXMLloading] = useState(false);
    const [nf, setNf] = useState({
        temNf: false,
        urlNf: '',
        temXML: false,
        urlXML: ''
    });
    const [message, setMessage] = useState({
        sucesso: false,
        erro: false,
        mensagem: ''
    });
    const apiUrl = process.env.REACT_APP_APIURL;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    const [parametrosValidos, setParametrosValidos] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const parametrosEsperados = ['ano', 'mes', 'md5'];
        const parametrosRecebidos = Array.from(params.keys());

        const validos = parametrosRecebidos.length === parametrosEsperados.length &&
            parametrosRecebidos.every(param => parametrosEsperados.includes(param));

        setParametrosValidos(validos);

        if (validos) {
            const ano = params.get('ano');
            const mes = params.get('mes');
            const md5 = params.get('md5');
            requestPdf(ano, mes, md5);
        }
    }, [location.search]);

    const requestPdf = async (ano, mes, md5) => {
        try {
            const response = await Axios.get(`${apiUrl}/api/TBe/BuscaPDFTbeQrCode?mes=${mes}&ano=${ano}&md5=${md5}`);
            setPdfUrl(response.data?.retorno?.urlPdf);
            setDataEmissao(formatDate(response.data?.retorno?.dataEmissao));
            setMessage({
                sucesso: true,
                erro: false,
                mensagem: 'Documento encontrado e autenticado com sucesso.'
            });
            if (response.data?.retorno?.possuiNf === true) {
                setNf({
                    temNf: response.data?.retorno?.possuiNf,
                    urlNf: response.data?.retorno?.urlNf,
                    temXML: response.data?.retorno?.possuiXml,
                    urlXML: response.data?.retorno?.urlXml
                });
            }
        } catch (error) {
            setMessage({
                sucesso: false,
                erro: true,
                mensagem: 'Documento não encontrado ou falha na autenticação.'
            });
            console.error('Erro ao carregar PDF:', error);
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'pdfTb-e.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownloadXml = () => {
        setDownloadXMLloading(true);
        const linkXml = document.createElement('a');
        linkXml.href = nf.urlXML;
        linkXml.download = 'NF-e.xml';
        document.body.appendChild(linkXml);

        const linkPdf = document.createElement('a');
        linkPdf.href = nf.urlNf;
        linkPdf.download = 'NF-e.pdf';
        document.body.appendChild(linkPdf);

        linkXml.click();

        setTimeout(() => {
            linkPdf.click();

            document.body.removeChild(linkXml);
            document.body.removeChild(linkPdf);
        }, 3000);

        setDownloadXMLloading(false);
    };

    if (!parametrosValidos) {
        return <Navigate to="/tbe/notFound" />;
    }

    return (
        <>
            <UnauthenticatedTemplate>
                <NavigationBarUnauthenticated />
            </UnauthenticatedTemplate>
            <div className="container-fluid d-flex justify-content-center" style={{ paddingTop: 10 }}>
                <div className="col-12 col-md-8">
                    <div className="d-flex justify-content-center">
                        <h4 style={{ color: "#1E2E4F", paddingTop: 5 }}>Autenticação de Documento</h4>
                    </div>
                    <div className="p-3">
                        <SimpleCard>
                            <div style={{ padding: '20px 50px 35px 50px' }}>
                                <div className="d-flex justify-content-center">
                                    <p style={{ color: message.sucesso && !message.erro ? 'green' : 'red', fontWeight: 'bold' }}>
                                        {message.mensagem}
                                    </p>
                                </div>
                                {message.sucesso && !message.erro && (
                                    <div className="mt-3 p-3" style={{ border: '2px solid rgba(30, 46, 79, 0.5)', borderRadius: '5px', position: 'relative' }}>
                                        <div
                                            className="responsive-div"
                                            style={{
                                                backgroundColor: '#FFF',
                                                fontWeight: 'bold',
                                                position: 'absolute',
                                                top: '-1em',
                                                left: '2%',
                                                padding: '0 0.5em',
                                                color: '#1E2E4F',
                                                width: isMobile ? '96%' : '13vw',
                                                boxSizing: 'border-box',
                                                textAlign: 'center',
                                            }}
                                        >
                                            Detalhes do documento
                                        </div>
                                        <div className="mt-3">
                                            <label>
                                                <strong>Chave de Acesso:</strong>
                                            </label>
                                            <span className="ml-2" style={{ marginLeft: 10 }}>{new URLSearchParams(location.search).get('md5')}</span>
                                        </div>
                                        <div className="mt-3">
                                            <label>
                                                <strong>Data de Emissão:</strong>
                                            </label>
                                            <span className="ml-2" style={{ marginLeft: 10 }}>{dataEmissao}</span>
                                        </div>
                                        <div className="mt-3">
                                            <label>
                                                <strong>Status da NF:</strong>
                                            </label>
                                            <span className="ml-2" style={{ marginLeft: 10 }}>{nf.temNf ? 'Emitida' : 'Não emitida'}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {message.sucesso && !message.erro && (
                                <div className="d-flex justify-content-center mt-2 mb-3">
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleDownload}
                                        style={{
                                            minHeight: "6vh",
                                            minWidth: "12vw",
                                            borderRadius: "5px",
                                            width: "auto",
                                            height: "auto",
                                            padding: "0.5rem 1rem",
                                            whiteSpace: "nowrap"
                                        }}
                                    >
                                        <span className="d-inline d-md-none">Baixar PDF Tb-e</span>
                                        <span className="d-none d-md-inline">Baixar PDF Tb-e</span>
                                    </button>
                                </div>
                            )}
                            {message.sucesso && !message.erro && nf.temNf && (
                                <div className="d-flex justify-content-center mt-2 mb-3">
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleDownloadXml}
                                        style={{
                                            minHeight: "6vh",
                                            minWidth: "12vw",
                                            borderRadius: "5px",
                                            width: "auto",
                                            height: "auto",
                                            padding: "0.5rem 1rem",
                                            whiteSpace: "nowrap"
                                        }}
                                    >
                                        {downloadXMLloading ? (
                                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true">Baixando...</span>
                                        ) : (<>
                                            <span className="d-inline d-md-none">Baixar Nota + XML</span>
                                            <span className="d-none d-md-inline">Baixar Nota + XML</span>
                                        </>)}
                                    </button>
                                </div>
                            )}
                        </SimpleCard>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewerPdfQrCode;