import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { ModalsContext } from "SGP-contexts/ModalsContext";

export const ResultadoCadastroModal = () => {
  const {
    sucessoCadastro,
    setSucessoCadastro,
    falhaCadastro,
    setFalhaCadastro,
    lavagemIdentificarModal
  } = useContext(ModalsContext);

  return (
    <>
      {sucessoCadastro.estado && (
        <div
          // onClick={() => setSucessoCadastro({ estado: false, mensagem: "" })}
        >
          <Modal show={sucessoCadastro.estado}>
            <Modal.Header
              closeButton
              onHide={() => setSucessoCadastro({ estado: false, mensagem: "" })}
            >
              <Modal.Title>Registro salvo com sucesso</Modal.Title>
            </Modal.Header>
            {sucessoCadastro.mensagem && (
              <Modal.Body>
                <span>{sucessoCadastro.mensagem}</span>
              </Modal.Body>
            )}
            <Modal.Footer>
              <Button
                variant="outline-success"
                onClick={() =>
                  setSucessoCadastro({ estado: false, mensagem: "" })
                }
              >
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}

      {falhaCadastro.estado && (
        <div
          // onClick={() =>
          //   setFalhaCadastro({ estado: false, mensagemDeErro: "" })
          // }
        >
          <Modal show={falhaCadastro.estado}>
            <Modal.Header
              closeButton
              onHide={() =>
                setFalhaCadastro({ estado: false, mensagemDeErro: "" })
              }
            >
              <Modal.Title>O salvamento do registro falhou!</Modal.Title>
            </Modal.Header>
            {falhaCadastro.mensagemDeErro && (
              <Modal.Body>{falhaCadastro.mensagemDeErro}</Modal.Body>
            )}
            <Modal.Footer>
              <Button
                variant="outline-danger"
                onClick={() =>{
                  setFalhaCadastro({ estado: false, mensagemDeErro: "" });
                }
                }
              >
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};
