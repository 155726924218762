import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import ItaminasLogo from "./../../../Assets/logo-itaminas-branco.png";
import { formatDate } from "utils/formatDate";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 18,
    paddingHorizontal: 18,
    fontFamily: "Helvetica",
  },
  dashedLine: {
    borderBottomWidth: 1,
    borderBottomColor: "grey",
    borderBottomStyle: "dashed",
    marginTop: 15,
    marginBottom: 15,
  },
  header: {
    minHeight: 108,
    maxHeight: 108,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    border: "0.5px solid black",
  },
  cardHeaderLogo: {
    flex: 1,
    minWidth: 270,
    maxWidth: 270,
    borderRight: "0.5px solid black",
  },
  cardHeaderQrCode: {
    flex: 1,
    borderRight: "0.5px solid black",
    justifyContent: "center",
  },
  cardHeaderTiquete: {
    flex: 1,
    minWidth: 208,
    maxWidth: 208,
  },
  headerImageContainer: {
    backgroundColor: "#1E2E4F",
    alignItems: "center",
    marginBottom: 5,
  },
  headerImage: {
    width: "50%",
    height: "auto",
    padding: 5,
  },
  infoContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  qrCodeContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  tiqueteContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  biggerTitleNome: {
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    paddingBottom: 2,
  },
  biggerTitle: {
    fontSize: 8,
    textAlign: "center",
    paddingBottom: 1,
    paddingRight: 5,
  },
  biggerTitleCNPJ: {
    fontSize: 8,
    textAlign: "center",
    paddingBottom: 1,
    paddingRight: 5,
    paddingLeft: 16,
  },
  biggerTitleEnd: {
    fontSize: 8,
    textAlign: "center",
    paddingBottom: 1,
    paddingRight: 5,
    paddingLeft: 3,
  },
  biggerTitleBairro: {
    fontSize: 8,
    textAlign: "center",
    paddingBottom: 1,
    paddingRight: 5,
    paddingLeft: 15,
  },
  biggerTitleCep: {
    fontSize: 8,
    textAlign: "center",
    paddingBottom: 1,
    paddingRight: 5,
    paddingLeft: 20,
    paddingBottom: 4,
  },
  biggerTitleIns: {
    fontSize: 8,
    textAlign: "center",
    paddingBottom: 1,
    paddingRight: 5,
    paddingLeft: 25,
  },
  boldText: {
    fontWeight: 700, // Use 700 for bold
  },
  TitleTiquete: {
    fontSize: 18,
    textAlign: "center",
    paddingVertical: 1,
    fontFamily: "Times-Bold",
  },
  infoTiquete: {
    fontSize: 10,
    textAlign: "center",
    paddingVertical: 1,
    marginRight: 5,
  },
  infoTiqueteNumero: {
    fontSize: 10,
    textAlign: "left",
    marginRight: 5,
  },
  infoTiqueteFlex: {
    flexDirection: "row",
    alignItems: "center",
  },
  infoTiqueteFlexAbove: {
    flexDirection: "row",
    alignItems: "center",
  },
  infoTiqueteHora: {
    fontSize: 10,
    textAlign: "center",
    paddingVertical: 1,
    marginLeft: 12,
    marginRight: 5,
  },
  infoTiqueteDataHora: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    paddingVertical: 1,
  },
  infoTiqueteNum: {
    fontSize: 16,
    fontFamily: "Helvetica-Bold",
    paddingRight: 25,
  },
  infoTiqueteChave: {
    fontSize: 7,
    paddingLeft: 5,
  },
  infoTiqueteChaveData: {
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
    paddingTop: 1,
    paddingLeft: 10,
  },
  boxInfoTiquete: {
    width: "100%",
    borderTop: "0.5px solid black",
    alignItems: "flex-start",
  },
  boxInfoTiqueteCodBarras: {
    width: "100%",
    borderTop: "0.5px solid black",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  codBarras: {
    fontSize: 10,
    marginTop: 5,
  },

  headerDate: {
    marginTop: "2px",
    marginBottom: "3px",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  linha: {
    flexDirection: "row",
    width: "100%",
  },
  Row2x: {
    flexDirection: "row",
  },
});

// Create Document Component
const TemplateRelatorio = ({ relatorioData, includeDates, children }) => {
  const datasRelatorio = () => {
    if (includeDates && relatorioData) {
      const dataHoraUTC = new Date(relatorioData.data);

      const date = dataHoraUTC.toLocaleDateString("pt-BR");
      const time = dataHoraUTC.toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });

      return { date, time };
    }
    return { date: "", time: "" };
  };

  const { date, time } = datasRelatorio();

  return (
    <>
      {relatorioData ? (
        <Document>
          <Page style={styles.body}>
            <View style={styles.header} fixed>
              <View style={styles.cardHeaderLogo}>
                <View style={styles.headerImageContainer}>
                  <Image src={ItaminasLogo} style={styles.headerImage} />
                </View>
                <Text style={styles.biggerTitleNome}>
                  {relatorioData.agendamentoFluig.filial.nome}
                </Text>
                <View style={styles.infoContainer}>
                  <View style={styles.linha}>
                    <View style={styles.Row2x}>
                      <Text style={styles.biggerTitleCNPJ}>CNPJ:</Text>
                      <Text style={styles.biggerTitleNome}>
                        {relatorioData.agendamentoFluig.filial.cnpj}
                      </Text>
                    </View>
                    <View style={styles.Row2x}>
                      <Text style={styles.biggerTitleIns}>Insc. Estadual:</Text>
                      <Text style={styles.biggerTitleNome}>
                        {relatorioData.agendamentoFluig.filial.ie}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.linha}>
                    <View style={styles.Row2x}>
                      <Text style={styles.biggerTitleEnd}>Endereço:</Text>
                      <Text style={styles.biggerTitleNome}>
                        {relatorioData.agendamentoFluig.filial.endereco}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.linha}>
                    <View style={styles.Row2x}>
                      <Text style={styles.biggerTitleBairro}>Bairro:</Text>
                      <Text style={styles.biggerTitleNome}>--</Text>
                    </View>
                  </View>
                  <View style={styles.linha}>
                    <Text style={styles.biggerTitleCep}>CEP:</Text>
                    <Text style={styles.biggerTitleNome}>--</Text>
                  </View>
                </View>
              </View>

              <View style={styles.cardHeaderQrCode}>
                <View style={styles.qrCodeContainer}>
                  <Text style={styles.biggerTitle}>QR CODE</Text>
                </View>
              </View>

              <View style={styles.cardHeaderTiquete}>
                <View style={styles.tiqueteContainer}>
                  <Text style={styles.TitleTiquete}>Tíquete de Balança</Text>
                  <View style={styles.infoTiqueteFlexAbove}>
                    <Text style={styles.infoTiqueteNumero}>Número:</Text>
                    <Text style={styles.infoTiqueteNum}>
                      {relatorioData.numeroSerie}
                    </Text>
                  </View>
                  <View style={styles.infoTiqueteFlex}>
                    <Text style={styles.infoTiquete}>Data Emissão:</Text>
                    <Text style={styles.infoTiqueteDataHora}>{date}</Text>
                    <Text style={styles.infoTiqueteHora}>Hora:</Text>
                    <Text style={styles.infoTiqueteDataHora}>{time}</Text>
                  </View>
                  <View style={styles.boxInfoTiquete}>
                    <Text style={styles.infoTiqueteChave}>Chave:</Text>
                    <Text style={styles.infoTiqueteChaveData}>
                      {relatorioData.md5}
                    </Text>
                  </View>
                  <View style={styles.boxInfoTiqueteCodBarras}>
                    <Text style={styles.codBarras}>CÓDIGO DE BARRAS</Text>
                  </View>
                </View>
              </View>
            </View>

            {children}
            <View style={styles.dashedLine} />
            <View style={styles.header} fixed>
              <View style={styles.cardHeaderLogo}>
                <View style={styles.headerImageContainer}>
                  <Image src={ItaminasLogo} style={styles.headerImage} />
                </View>
                <Text style={styles.biggerTitleNome}>
                  {relatorioData.agendamentoFluig.transportadora.nome}
                </Text>
                <View style={styles.infoContainer}>
                  <View style={styles.linha}>
                    <View style={styles.Row2x}>
                      <Text style={styles.biggerTitleCNPJ}>CNPJ:</Text>
                      <Text style={styles.biggerTitleNome}>
                        {relatorioData.agendamentoFluig.transportadora.cnpj}
                      </Text>
                    </View>
                    <View style={styles.Row2x}>
                      <Text style={styles.biggerTitleIns}>Insc. Estadual:</Text>
                      <Text style={styles.biggerTitleNome}>
                        {relatorioData.agendamentoFluig.transportadora.ie}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.linha}>
                    <View style={styles.Row2x}>
                      <Text style={styles.biggerTitleEnd}>Endereço:</Text>
                      <Text style={styles.biggerTitleNome}>
                        {relatorioData.agendamentoFluig.transportadora.endereco}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.linha}>
                    <View style={styles.Row2x}>
                      <Text style={styles.biggerTitleBairro}>Bairro:</Text>
                      <Text style={styles.biggerTitleNome}>?</Text>
                    </View>
                  </View>
                  <View style={styles.linha}>
                    <Text style={styles.biggerTitleCep}>CEP:</Text>
                    <Text style={styles.biggerTitleNome}>?</Text>
                  </View>
                </View>
              </View>

              <View style={styles.cardHeaderQrCode}>
                <View style={styles.qrCodeContainer}>
                  <Text style={styles.biggerTitle}>QR CODE</Text>
                </View>
              </View>

              <View style={styles.cardHeaderTiquete}>
                <View style={styles.tiqueteContainer}>
                  <Text style={styles.TitleTiquete}>Tíquete de Balança</Text>
                  <View style={styles.infoTiqueteFlexAbove}>
                    <Text style={styles.infoTiqueteNumero}>Número:</Text>
                    <Text style={styles.infoTiqueteNum}>
                      {relatorioData.numeroSerie}
                    </Text>
                  </View>
                  <View style={styles.infoTiqueteFlex}>
                    <Text style={styles.infoTiquete}>Data Emissão:</Text>
                    <Text style={styles.infoTiqueteDataHora}>{date}</Text>
                    <Text style={styles.infoTiqueteHora}>Hora:</Text>
                    <Text style={styles.infoTiqueteDataHora}>{time}</Text>
                  </View>
                  <View style={styles.boxInfoTiquete}>
                    <Text style={styles.infoTiqueteChave}>Chave:</Text>
                    <Text style={styles.infoTiqueteChaveData}>
                      {relatorioData.md5}
                    </Text>
                  </View>
                  <View style={styles.boxInfoTiqueteCodBarras}>
                    <Text style={styles.codBarras}>CÓDIGO DE BARRAS</Text>
                  </View>
                </View>
              </View>
            </View>

            {children}
          </Page>
        </Document>
      ) : null}
    </>
  );
};

export default TemplateRelatorio;
