import React, { useEffect, useContext, useState } from "react";
import PesagemCardFiltragem from "../../SGP-components/Pesagem/PesagemCardFiltragem";
import PesagemMainTable from "../../SGP-components/Common/TabelaRegistros";
import { ViewModal as PesagemViewModal } from "SGP-components/Common/ViewModal";
import PesagemCreateModal from "../../SGP-components/Pesagem/PesagemCreateModal";
import { ImageModal as PesagemImageModal } from "SGP-components/Common/ImageModal";
import PesagemCardsBalancas from "SGP-components/Pesagem/PesagemCardsBalancas";
import { TararModal as PesagemTararModal } from "SGP-components/Common/TararModal";
import { ResultadoTararModal as PesagemSucessoTararModal } from "SGP-components/Common/ReultadoTararModal";
import { ResultadoCadastroModal as PesagemResultadoCadastroModal } from "SGP-components/Common/ResultadoCadastroModal";
import { FilterContext } from "SGP-contexts/FilterContext";
import axios from "axios";
import { NenhumRegistroModal as PesagemNenhumRegistroModal } from "SGP-components/Common/NenhumRegistroModa";
import { IdentificarModal as PesagemIdentificarModal } from "SGP-components/Common/IdentificarModal";
import { CorrigirIdentificacaoModal as PesagemCorrigirIdentificacaoModal } from "SGP-components/Common/CorrigirIdentificacaoModal";
import { NotaFiscalModal as PesagemNotaFiscalModal } from "SGP-components/Common/NotaFiscalModal";
import { LoginDataContext } from "SGP-contexts/LoginDataContext";
import PesagemCancelarPesagemModal from "SGP-components/Common/CancelarPesagemModal";
import PesagemAdicionarRotaManualModal from "SGP-components/Common/AdicionarRotaManualModal";
import { TrocarRotaModal as PesagemTrocarRotaModal } from "SGP-components/Common/TrocarRotaModal";
import PesagemRetirarNotaFiscalPesagemModal from "SGP-components/Common/RetirarNotaFiscalPesagemModal";

const Pesagem = () => {
  const { accessToken } = useContext(LoginDataContext);
  const { setBalancas } = useContext(FilterContext);

  const [dadosPesagem, setDadosPesagem] = useState([]);
  const [dadosCardsPesagem, setDadosCardsPesagem] = useState([]);
  const [tokenPesagemSelecionada, setTokenPesagemSelecionada] = useState("");

  const apiUrl = process.env.REACT_APP_APIURL;

  useEffect(() => {
    balancasRequest();
    cardsRequest();
    // eslint-disable-next-line
  }, [accessToken]);

  const balancasRequest = () => {
    axios
      .get(`${apiUrl}/apiwebapp/Balanca/BalancasEmOperacao`)
      .then((res) => {
        setBalancas([...res.data]);
      })
      .catch((e) => {
        console.log(`Ocorreu um erro no request: ${e}`);
      });
  };
  const cardsRequest = () => {
    axios
      .get(`${apiUrl}/apiwebapp/Pesagem/TotaisPorBalancaComparativoNoTempo/2/0`)
      .then((res) => {
        setDadosCardsPesagem([...res.data]);
        // balancasRequest();
      })
      .catch((e) => {
        console.log(`Ocorreu um erro no request: ${e}`);
      });
  };
  return (
    <>
      <PesagemCardsBalancas dadosCardsPesagem={dadosCardsPesagem} />
      <PesagemCardFiltragem
        cardsRequest={cardsRequest}
        setDadosPesagem={(apiData) => setDadosPesagem(apiData)}
      />
      <PesagemMainTable
        dados={dadosPesagem}
        selectItem={(idPesagem) => setTokenPesagemSelecionada(idPesagem)}
        setDadosPesagem={(apiData) => setDadosPesagem(apiData)}
      />
      <PesagemViewModal
        tokenPesagemSelecionada={tokenPesagemSelecionada}
        dadosPesagem={dadosPesagem}
      />
      <PesagemCreateModal />
      <PesagemImageModal />
      <PesagemTararModal
        dadosPesagem={dadosPesagem}
        tokenPesagemSelecionada={tokenPesagemSelecionada}
      />
      <PesagemSucessoTararModal />
      <PesagemNotaFiscalModal
        dadosPesagem={dadosPesagem}
        tokenPesagemSelecionada={tokenPesagemSelecionada}
      />
      <PesagemResultadoCadastroModal />
      <PesagemNenhumRegistroModal />
      <PesagemIdentificarModal
        dadosPesagem={dadosPesagem}
        tokenPesagemSelecionada={tokenPesagemSelecionada}
        setDadosPesagem={(apiData) => setDadosPesagem(apiData)}
      />
      <PesagemCorrigirIdentificacaoModal
        dadosPesagem={dadosPesagem}
        tokenPesagemSelecionada={tokenPesagemSelecionada}
        setDadosPesagem={(apiData) => setDadosPesagem(apiData)}
      />
      <PesagemCancelarPesagemModal
        tokenPesagemSelecionada={tokenPesagemSelecionada}
        dadosPesagem={dadosPesagem}
        setDadosPesagem={(apiData) => setDadosPesagem(apiData)}
      />
      <PesagemAdicionarRotaManualModal
        tokenPesagemSelecionada={tokenPesagemSelecionada}
        dadosPesagem={dadosPesagem}
        setDadosPesagem={(apiData) => setDadosPesagem(apiData)}
      />
      <PesagemTrocarRotaModal
        tokenPesagemSelecionada={tokenPesagemSelecionada}
        dadosPesagem={dadosPesagem}
        setDadosPesagem={(apiData) => setDadosPesagem(apiData)}
      />
      <PesagemRetirarNotaFiscalPesagemModal
        setDadosPesagem={(apiData) => setDadosPesagem(apiData)}
        dadosPesagem={dadosPesagem}
        tokenPesagemSelecionada={tokenPesagemSelecionada}
      />
    </>
  );
};

export default Pesagem;
