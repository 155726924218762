import * as Yup from "yup";

const placaRegex = /^[A-Z]{3}-[0-9][0-9A-Z][0-9]{2}$/;
const numbersOnlyRegex = /^[0-9]+$/;

export const IdentificarModalSchema = Yup.object().shape({
  numeroFrota: Yup.string().matches(
    numbersOnlyRegex,
    "Numero da frota deve conter apenas números"
  ),
  placa: Yup.string().matches(placaRegex, "Formato de placa inválido"),
});
