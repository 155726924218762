import React, { useContext, useMemo, useState } from "react";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import { Modal, Button } from "react-bootstrap";
import { Form, Formik, Field } from "formik";
import ButtonWithLoading from "./ButtonWithLoading";
import axios from "axios";
import { FilterContext } from "SGP-contexts/FilterContext";

const RetirarNotaFiscalPesagemModal = ({
  tokenPesagemSelecionada,
  dadosPesagem,
  setDadosPesagem,
}) => {
  const { retirarNF, setRetirarNF, setSucessoCadastro, setFalhaCadastro } =
    useContext(ModalsContext);
  const { urlUltimoRequest } = useContext(FilterContext);

  const [loading, setLoading] = useState(false);

  const [selectedItem] = useMemo(() => {
    if (Array.isArray(dadosPesagem)) {
      return dadosPesagem.filter(
        (item) => item.tokenPesagem === tokenPesagemSelecionada
      );
    } else return [];
  }, [dadosPesagem, tokenPesagemSelecionada]);

  const apiUrl = process.env.REACT_APP_APIURL;

  const solicitarCancelamento = ({ tokenPesagem, chaveNFE }) => {
    setLoading(true);
    axios
      .post(
        `${apiUrl}/NFEntrada/ExcluirNFEdaPesagem?tokenPesagem=${tokenPesagem}&ChaveNFe=${chaveNFE}`
      )
      .then((res) => {
        if (!res.data.sucesso) {
          setFalhaCadastro({
            estado: true,
            mensagemDeErro: res.data.mensagem,
          });
        } else {
          updateTable();
          setRetirarNF(false);
          setSucessoCadastro({
            estado: true,
            mensagem: "Nota fiscal retirada da pesagem",
          });
        }
      })
      .catch((error) =>
        setFalhaCadastro({ estado: true, mensagemDeErro: error.data.mensagem })
      )
      .finally(() => setLoading(false));
  };

  const updateTable = () => {
    axios
      .get(urlUltimoRequest)
      .then((res) => setDadosPesagem(res.data.retorno))
      .catch((error) => console.log(error));
  };

  return (
    <>
      {retirarNF && (
        <div
          onClick={() => {
            setRetirarNF(false);
          }}
        >
          <Modal show={retirarNF} onClick={(event) => event.stopPropagation()}>
            <Modal.Header closeButton onHide={() => setRetirarNF(!retirarNF)}>
              <Modal.Title>Retirar nota fiscal da pesagem</Modal.Title>
            </Modal.Header>

            <Formik
              initialValues={{
                tokenPesagem: selectedItem.tokenPesagem || "",
                chaveNFE: selectedItem.chaveNFE || "",
              }}
              onSubmit={(values) => solicitarCancelamento(values)}
            >
              {() => (
                <Form>
                  <Modal.Body>
                    <div className="form-group">
                      <label
                        htmlFor="idPesagem"
                        className="col-form-label text-center"
                      >
                        Id da pesagem
                      </label>
                      <div className="col-sm-12">
                        <Field
                          type="text"
                          className="form-control"
                          id="idPesagem"
                          placeholder="Id da pesagem"
                          name="tokenPesagem"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="chaveNFE"
                        className="col-form-label text-center"
                      >
                        Chave da nota fiscal de entrada
                      </label>
                      <div className="col-sm-12">
                        <Field
                          type="text"
                          className="form-control"
                          id="chaveNFE"
                          placeholder="Chave"
                          name="chaveNFE"
                          disabled
                        />
                      </div>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <ButtonWithLoading
                      loading={loading}
                      buttonVariant="outline-success"
                      spinnerVariant="sucess"
                    >
                      Retirar nota fiscal
                    </ButtonWithLoading>
                    <Button
                      variant="outline-danger"
                      onClick={() => setRetirarNF(!retirarNF)}
                    >
                      Cancelar
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
      )}
    </>
  );
};

export default RetirarNotaFiscalPesagemModal;
