import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import { Modal, Button } from "react-bootstrap";
import { Form, Formik, Field } from "formik";
import axios from "axios";
import ButtonWithLoading from "./ButtonWithLoading";
import { FilterContext } from "SGP-contexts/FilterContext";
import RotasSelectField from "./CustomSelectField";

const AdicionarRotaManualModal = ({
  tokenPesagemSelecionada,
  dadosPesagem,
  setDadosPesagem,
}) => {
  const {
    adicionarRotaManualModal,
    setAdicionarRotaManualModal,
    setSucessoCadastro,
    setFalhaCadastro,
  } = useContext(ModalsContext);

  const { urlUltimoRequest } = useContext(FilterContext);

  const [rotas, setRotas] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedItem] = useMemo(() => {
    if (Array.isArray(dadosPesagem)) {
      return dadosPesagem.filter(
        (item) => item.tokenPesagem === tokenPesagemSelecionada
      );
    } else return [];
  }, [dadosPesagem, tokenPesagemSelecionada]);

  const apiUrl = process.env.REACT_APP_APIURL;

  const getRoutes = useCallback(() => {
    //ToDo: map the api return to the format: {value: '', label: ''}

    axios
      .get(`${apiUrl}/api/Rotas`)
      .then((res) => {
        const formatedResponse = res.data.map((rota) => {
          return {
            value: rota.rotaId,
            label: `${rota.rotaId} - ${rota.nome}`,
          };
        });

        setRotas(formatedResponse);
      })
      .catch((error) => console.log(error));
    // eslint-disable-next-line
  }, []);

  const solicitarInclusao = async (values) => {
    setLoading(true);
    axios
      .post(
        `${apiUrl}/apiwebapp/Pesagem/SolicitarInclusaoRotaManual?RotaId=${values.rota.value}&TokenPesagem=${selectedItem.tokenPesagem}&incluirParaPesagensPosteriores=${values.pesagensPosteriores}`
      )
      .then(() => {
        updateTable();
        setAdicionarRotaManualModal(false);
        setSucessoCadastro({
          estado: true,
          mensagem:
            "Solicitação de inclusão de rota manual enviada com sucesso",
        });
      })
      .catch((error) => {
        //console.log(error);
        //setFalhaCadastro({ estado: true, mensagemDeErro: "Caminhão desativado, impossível adicionar a rota!" });
        if (error.response && error.response.data && error.response.data.mensagem) {
          setFalhaCadastro({ estado: true, mensagemDeErro: error.response.data.mensagem });
        } else {
          setFalhaCadastro({ estado: true, mensagemDeErro: "Caminhão desativado, impossível adicionar a rota!" });
        }
      })
      .finally(() => setLoading(false));
  };

  const updateTable = () => {
    axios
      .get(urlUltimoRequest)
      .then((res) => setDadosPesagem(res.data.retorno))
      .catch((error) => console.log(error));
  };
  // eslint-disable-next-line
  useEffect(() => getRoutes(), []);

  return (
    <>
      {adicionarRotaManualModal && (
        <div
          onClick={() => {
            setAdicionarRotaManualModal(false);
          }}
        >
          <Modal
            show={adicionarRotaManualModal}
            onClick={(event) => event.stopPropagation()}
          >
            <Modal.Header
              closeButton
              onHide={() =>
                setAdicionarRotaManualModal(!adicionarRotaManualModal)
              }
            >
              <Modal.Title>Janela de adição de rota manual</Modal.Title>
            </Modal.Header>

            <Formik
              initialValues={{
                tokenPesagem: selectedItem?.tokenPesagem || "",
                rota: "",
                pesagensPosteriores: false,
              }}
              onSubmit={(values) => solicitarInclusao(values)}
            >
              {() => (
                <Form>
                  <Modal.Body>
                    <div className="form-group row align-items-center">
                      <label
                        htmlFor="idPesagem"
                        className="col-sm-2 col-form-label text-center"
                      >
                        Id da pesagem
                      </label>
                      <div className="col-sm-10">
                        <Field
                          type="text"
                          className="form-control"
                          id="idPesagem"
                          placeholder="Id da pesagem"
                          name="tokenPesagem"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row align-items-center">
                      <label
                        htmlFor="pickerRota"
                        className="col-sm-2 col-form-label text-center"
                      >
                        Rota
                      </label>
                      <div className="col-sm-10">
                        <Field
                          component={RotasSelectField}
                          name="rota"
                          options={rotas}
                          noOptionsMessage={() => "Rota não encontrada!"}
                          defaultValue={null}
                          isMulti={false}
                        />
                      </div>

                      <div className="form-group row text-center">
                        <label
                          htmlFor="pesagensPosteriores"
                          className="col-sm-2 col-form-label"
                        >
                          Mudar para próximas pesagens
                        </label>
                        <div className="col-sm-1 my-auto">
                          <div className="form-check ">
                            <Field
                              type="checkbox"
                              className="form-check-input "
                              id="pesagensPosteriores"
                              name="pesagensPosteriores"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <ButtonWithLoading
                      buttonVariant="outline-success"
                      spinnerVariant="success"
                      loading={loading}
                    >
                      Confirmar
                    </ButtonWithLoading>
                    <Button
                      variant="outline-danger"
                      onClick={() =>
                        setAdicionarRotaManualModal(!adicionarRotaManualModal)
                      }
                    >
                      Cancelar
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
      )}
    </>
  );
};

export default AdicionarRotaManualModal;
