import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FilterContext } from "SGP-contexts/FilterContext";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import axios from "axios";
import { Formik, Field, Form, useField } from "formik";
import { IdentificarModalSchema } from "../../Schemas/IdentificarModalSchema";
import { formatDate } from "utils/formatDate";
import ButtonWithLoading from "./ButtonWithLoading";

export const CorrigirIdentificacaoModal = ({
  dadosPesagem,
  tokenPesagemSelecionada,
  setDadosPesagem,
}) => {
  const { urlUltimoRequest } = useContext(FilterContext);
  const {
    corrigirIdentificacaoModal,
    setShowImage,
    setImage,
    setCorrigirIdentificacaoModal,
    setFalhaCadastro,
    setSucessoCadastro,
  } = useContext(ModalsContext);
  const [loading, setLoading] = useState(false);
  const [confirmar, setConfirmar] = useState(false);
  const [novaFrota, setNovaFrota] = useState();

  const [caminhao] = dadosPesagem.filter(
    (item) => item.tokenPesagem === tokenPesagemSelecionada
  );

  const apiUrl = process.env.REACT_APP_APIURL;

  const getRequest = () => {
    axios
      .get(urlUltimoRequest)
      .then((res) => {
        setDadosPesagem(res.data.retorno);
        setCorrigirIdentificacaoModal(false);
        setConfirmar(false);
        setNovaFrota();
        setSucessoCadastro({
          estado: true,
          mensagem: "Número da frota atualizado com sucesso!",
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const putRequest = () => {
    setLoading(true);
    console.log("putRequest");
    const requestBody = {
      token: caminhao?.tokenPesagem,
      frota: novaFrota,
    };

    axios
      .put(
        `${apiUrl}/apiwebapp/Pesagem/CorrigirIdentificacaoPesagem?token=${requestBody.token}&frota=${requestBody.frota}`
      )
      .then(() => {
        console.log("Atualiza a tabela");
        getRequest();
      })
      .catch((error) => {
        setCorrigirIdentificacaoModal(false);
        setNovaFrota();
        setConfirmar(false);
        setLoading(false);
        setFalhaCadastro({
          estado: true,
          mensagemDeErro: error.response.data.mensagem,
        });
      });
  };

  const pesoFormatado = new Intl.NumberFormat("pt-BR").format(caminhao?.peso);
  const pesoLiquidoFormatado = new Intl.NumberFormat("pt-BR").format(
    caminhao?.pesoLiquido
  );
  const dataFormatada = formatDate(caminhao?.dataHoraUTCWeb);
  return (
    <>
      <div
        onClick={() => {
          setNovaFrota();
          setCorrigirIdentificacaoModal(false);
        }}
      >
        <Modal
          show={corrigirIdentificacaoModal}
          scrollable={true}
          onClick={(event) => event.stopPropagation()}
        >
          <Modal.Header
            closeButton
            onHide={() => {
              setNovaFrota();
              setCorrigirIdentificacaoModal(false);
            }}
          >
            <Modal.Title>Identificação do caminhão</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              validationSchema={IdentificarModalSchema}
              validateOnChange={true}
              initialValues={{
                numeroFrota: "",
                placa: caminhao?.placa,
              }}
            >
              {({ errors, touched }) => (
                <Form id="corrigirIdentificacaoCaminhao">
                  <div>
                    <div className="row">
                      <div className="col">
                        <label htmlFor="idPesagem">ID da pesagem</label>
                        <input
                          type="text"
                          className="form-control"
                          name="idPesagem"
                          value={caminhao?.tokenPesagem}
                          disabled
                          id="idPesagem"
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-md-6 form-group ">
                        <label htmlFor="Data">Data</label>
                        <input
                          type="text"
                          className="form-control"
                          name="data"
                          value={dataFormatada}
                          disabled
                          id="Data"
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="Plataforma">Balança</label>
                        <input
                          type="text"
                          className="form-control"
                          name="balanca"
                          value={caminhao?.balanca}
                          disabled
                          id="Balanca"
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="Plataforma">Plataforma</label>
                        <input
                          type="text"
                          className="form-control"
                          name="plataforma"
                          value={caminhao?.plataforma}
                          disabled
                          id="Plataforma"
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="Peso">Peso (Kg)</label>
                        <input
                          type="text"
                          className="form-control"
                          name="peso"
                          value={pesoFormatado}
                          disabled
                          id="Peso"
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="pesoLiquido">Peso líquido (kg)</label>
                        <input
                          type="text"
                          className="form-control"
                          name="pesoLiquido"
                          value={pesoLiquidoFormatado}
                          disabled
                          id="pesoLiquido"
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="sentido">Sentido</label>
                        <input
                          type="text"
                          className="form-control"
                          name="sentido"
                          value={caminhao?.sentido ? caminhao?.sentido : "--"}
                          id="sentido"
                          disabled
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="numeroFrotaAtual">
                          Número da frota atual
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="numeroFrotaAtual"
                          value={caminhao?.numeroFrota}
                          id="numeroFrotaAtual"
                          disabled
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="numeroFrota">
                          Atualize o número da frota
                        </label>
                        <Field
                          type="text"
                          name="numeroFrota"
                          id="numeroFrota"
                          required
                        >
                          {({ field }) => (
                            <input
                              className="form-control"
                              placeholder="Digite o número da frota"
                              {...field}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                field.onChange(e);
                                setNovaFrota(e.target.value);
                              }}
                            />
                          )}
                        </Field>
                        {errors.numeroFrota && touched.numeroFrota ? (
                          <small className="d-flex w-100 text-danger py-1">
                            *{errors.numeroFrota}
                          </small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <section>
                    <br />
                    <Modal.Title>Fotos</Modal.Title>
                    <div className="custom-separator"></div>
                    <div className="row text-center ">
                      {caminhao?.urlImagens !== null &&
                        caminhao?.urlImagens.map((info, index) => (
                          <div key={index}>
                            <div className="col">
                              <img
                                src={info}
                                alt="Imagem do caminhão"
                                className="w-75 m-2 rounded cursor-pointer"
                                onClick={() => {
                                  setShowImage(true);
                                  setImage(info);
                                }}
                              />
                            </div>
                            <div className="w-100"></div>
                          </div>
                        ))}
                    </div>
                  </section>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-success"
              onClick={() => {
                setCorrigirIdentificacaoModal(false);
                setConfirmar(true);
              }}
            >
              Salvar
            </Button>

            <Button
              variant="outline-danger"
              onClick={() => {
                setNovaFrota();
                setCorrigirIdentificacaoModal(false);
                setConfirmar(false);
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        onClick={() => {
          setNovaFrota();
          setCorrigirIdentificacaoModal(true);
          setConfirmar(false);
        }}
      >
        <Modal
          show={confirmar}
          onClick={(event) => event.stopPropagation()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            onHide={() => {
              setNovaFrota();
              setCorrigirIdentificacaoModal(true);
              setConfirmar(false);
            }}
            closeButton
          >
            <Modal.Title>Atenção</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {novaFrota == null ? (
              <p>Por favor digite o número da frota.</p>
            ) : (
              <p>
                Deseja alterar o número da frota de{" "}
                <strong>{caminhao?.numeroFrota}</strong> para{" "}
                <strong>{novaFrota}</strong>?
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            {novaFrota == null ? null : (
              <div onClick={(e) => putRequest()}>
                <ButtonWithLoading
                  buttonVariant="outline-success"
                  spinnerVariant="success"
                  loading={loading}
                >
                  Salvar
                </ButtonWithLoading>
              </div>
            )}

            <Button
              variant="outline-danger"
              onClick={() => {
                setNovaFrota();
                setCorrigirIdentificacaoModal(true);
                setConfirmar(false);
              }}
              disabled={loading}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
