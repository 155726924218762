import React, { useContext, useEffect, useState, useRef } from "react";
import { useDidMount } from "hooks/useDidMount";
import { FilterContext } from "SGP-contexts/FilterContext";
import { ModalsContext } from "SGP-contexts/ModalsContext";

import SimpleCard from "../../@gull/components/cards/SimpleCard";
import ButtonWithLoading from "SGP-components/Common/ButtonWithLoading";
import RotasSelectField from "SGP-components/Common/CustomSelectField";

import { Formik, Form, Field } from "formik";
import { PlacaENumeroFrotaSchema } from "Schemas/PlacaENumeroFrotaSchema";
import { placaMask } from "Schemas/Masks";

import axios from "axios";
import { getRequestUrl } from "utils/filtrosPesagem";

const CardFiltragem = ({ cardsRequest, setDadosPesagem }) => {
  const apiUrl = process.env.REACT_APP_APIURL;

  const { balancas, setUrlUltimoRequest } = useContext(FilterContext);
  const { setNenhumRegistro } = useContext(ModalsContext);

  const [buscaPorIdentificacao, setBuscaPorIdentificacao] = useState({
    estado: false,
    numeroFrotaForm: true,
    placaForm: false,
  });
  const [buscaPorPeriodo, setBuscaPorPeriodo] = useState(false);
  const [buscaPorStatus, setBuscaPorStatus] = useState(false);
  const [buscaPorRota, setBuscaPorRota] = useState(false);

  const [canUpdateRequest, setCanUpdateRequest] = useState(false);

  const [timer, setTimer] = useState(0);

  const [loading, setLoading] = useState(false);

  const [rotas, setRotas] = useState([]);

  const requestUrl = useRef();

  const didMount = useDidMount();

  useEffect(() => {
    //Effeito disparado em loop para adicionar ao contador a cada 30 segundos (atualiza a página da pesagem)
    const pesagemInterval = setInterval(() => {
      setTimer((currentTime) => currentTime + 1);
    }, 30000);

    return () => clearInterval(pesagemInterval);
  });

  useEffect(() => {
    //Atualiza a tela da pesagem quando o timer mudar

    if (didMount) {
      cardsRequest();

      if (canUpdateRequest) {
        getPesagensFromAPI(requestUrl.current);
      }
    }
    // eslint-disable-next-line
  }, [timer]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/Rotas`)
      .then((res) => {
        const formatedResponse = res.data.map((rota) => {
          return {
            value: rota.rotaId,
            label: `${rota.rotaId} - ${rota.nome}`,
          };
        });

        setRotas([{ value: 0, label: "TODAS" }, ...formatedResponse]);
      })
      .catch((error) => console.log(error));
  }, []);

  const getPesagensFromAPI = (url) => {
    setUrlUltimoRequest(url);
    axios
      .get(url)
      .then((res) => {
        setDadosPesagem(res.data.retorno);
        setCanUpdateRequest(true);
        setLoading(false);
      })
      .catch((error) => {
        setCanUpdateRequest(false);
        setNenhumRegistro({
          estado: true,
          mensagemDeErro: error.response.data,
        });
        setLoading(false);
      });
  };

  const requestPesagens = (values) => {
    requestUrl.current = getRequestUrl(
      values,
      buscaPorIdentificacao,
      buscaPorPeriodo,
      buscaPorStatus,
      buscaPorRota
    );

    if (requestUrl === "Periodo inválido") {
      setNenhumRegistro({
        estado: true,
        mensagemDeErro:
          "Período inválido, verifique se o início do período para busca é anterior ao período final",
      });
      setLoading(false);

      return;
    } else getPesagensFromAPI(requestUrl.current);
  };

  return (
    <>
      <SimpleCard
        title="SGP: Sistema geral de pesagem"
        subtitle="Entre com os filtros abaixo para trazer os dados"
        className="card text-center"
      >
        <div className="row" style={{ marginRight: "20%", marginLeft: "20%" }}>
          <div className="card mb-5">
            <div className="card-body">
              <Formik
                onSubmit={(values) => {
                  setDadosPesagem([]);

                  setLoading(true);
                  // setParams(values);

                  requestPesagens(values);

                  // requestRegistros(values);
                }}
                initialValues={{
                  balanca: "TODAS",
                  quantidade: 30,
                  caminhoesVazios: false,
                  numeroFrota: "",
                  placa: "",
                  status: 0,
                  rotaId: 0,
                  timeRange: {
                    inicio: {
                      data: "",
                      hora: "00:00",
                    },
                    fim: {
                      data: "",
                      hora: "23:59",
                    },
                  },
                }}
                validationSchema={PlacaENumeroFrotaSchema}
              >
                {({ setFieldValue, errors, touched }) => (
                  <Form>
                    <div className="form-group row">
                      <label
                        htmlFor="pickerBalanca"
                        className="col-sm-2 col-form-label"
                      >
                        Balança
                      </label>
                      <div className="col-sm-10">
                        <Field
                          as="select"
                          required
                          id="pickerBalanca"
                          className="form-control"
                          name="balanca"
                        >
                          <option value="TODAS">TODAS</option>
                          {balancas.map((data) => {
                            if (data !== null) {
                              return (
                                <option key={data} value={data}>
                                  {data}
                                </option>
                              );
                            } else return null;
                          })}
                        </Field>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="quantRegistros"
                        className="col-sm-2 col-form-label"
                      >
                        Quantidade de registros
                      </label>
                      <div className="col-sm-10">
                        <Field
                          onWheel={(event) => event.target.blur()}
                          type="number"
                          className="form-control"
                          id="quantRegistros"
                          placeholder="Quantidade de registros"
                          name="quantidade"
                        />
                      </div>
                    </div>

                    <div className="form-group row ">
                      <label
                        htmlFor="caminhoesVazios"
                        className="col-sm-2 col-form-label"
                      >
                        Somente caminhões vazios
                      </label>
                      <div className="col-sm-10 my-auto">
                        <div className="form-check ">
                          <Field
                            type="checkbox"
                            className="form-check-input "
                            id="caminhoesVazios"
                            name="caminhoesVazios"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group row ">
                      <label
                        htmlFor="buscaPeriodo"
                        className="col-sm-2 col-form-label"
                      >
                        Buscar por período
                      </label>
                      <div className="col-sm-10 my-auto">
                        <div className="form-check ">
                          <input
                            type="checkbox"
                            className="form-check-input "
                            id="buscaPeriodo"
                            name="buscaPeriodo"
                            onChange={() =>
                              setBuscaPorPeriodo(!buscaPorPeriodo)
                            }
                            checked={buscaPorPeriodo}
                          />
                        </div>
                      </div>
                    </div>
                    {buscaPorPeriodo && (
                      <>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">De</label>
                          <div className="col-sm-10">
                            <div className="row">
                              <Field
                                type="date"
                                className="form-control col-auto mx-auto"
                                name="timeRange.inicio.data"
                                style={{ width: "45%" }}
                              />
                              <Field
                                type="time"
                                className="form-control col-auto mx-auto"
                                name="timeRange.inicio.hora"
                                style={{ width: "45%" }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">Até</label>
                          <div className="col-sm-10">
                            <div className="row">
                              <Field
                                type="date"
                                className="form-control col-auto mx-auto"
                                name="timeRange.fim.data"
                                style={{ width: "45%" }}
                              />
                              <Field
                                type="time"
                                className="form-control col-auto mx-auto"
                                id="horaMax"
                                name="timeRange.fim.hora"
                                style={{ width: "45%" }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="form-group row ">
                      <label
                        htmlFor="buscaFrota"
                        className="col-sm-2 col-form-label"
                      >
                        Identificação do veículo
                      </label>
                      <div className="col-sm-1 my-auto">
                        <div className="form-check ">
                          <input
                            type="checkbox"
                            className="form-check-input "
                            id="buscaFrota"
                            onChange={() =>
                              setBuscaPorIdentificacao({
                                ...buscaPorIdentificacao,
                                estado: !buscaPorIdentificacao.estado,
                              })
                            }
                          />
                        </div>
                      </div>

                      {buscaPorIdentificacao.estado && (
                        <>
                          <div className="row">
                            <div className="col-form-label col-sm-2 pt-0"></div>
                            <div className="col">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="frota"
                                  name="tipoVeiculo"
                                  checked={buscaPorIdentificacao.placaForm}
                                  onChange={() => {
                                    setBuscaPorIdentificacao({
                                      ...buscaPorIdentificacao,
                                      placaForm: true,
                                      numeroFrotaForm: false,
                                    });
                                    setFieldValue("numeroFrota", "");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="frota"
                                >
                                  Placa
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="carreta"
                                  name="tipoVeiculo"
                                  checked={
                                    buscaPorIdentificacao.numeroFrotaForm
                                  }
                                  onChange={() => {
                                    setBuscaPorIdentificacao({
                                      ...buscaPorIdentificacao,
                                      placaForm: false,
                                      numeroFrotaForm: true,
                                    });
                                    setFieldValue("placa", "");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="carreta"
                                >
                                  Numero de frota
                                </label>
                              </div>
                            </div>
                          </div>

                          {buscaPorIdentificacao.numeroFrotaForm && (
                            <div className="form-group row">
                              <label
                                htmlFor="numeroFrota"
                                className="col-sm-2 col-form-label"
                              >
                                Número da frota
                              </label>
                              <div className="col-sm-10 my-auto">
                                <Field
                                  type="text"
                                  name="numeroFrota"
                                  id="numeroFrota"
                                  className="form-control"
                                  placeholder="Entre com o número da frota"
                                />
                                {errors.numeroFrota && touched.numeroFrota ? (
                                  <small className="d-flex w-100 text-danger py-1">
                                    *{errors.numeroFrota}
                                  </small>
                                ) : null}
                              </div>
                            </div>
                          )}

                          {buscaPorIdentificacao.placaForm && (
                            <div className="form-group row">
                              <label
                                htmlFor="placa"
                                className="col-sm-2 col-form-label"
                              >
                                Placa
                              </label>
                              <div className="col-sm-10 my-auto">
                                <Field
                                  type="text"
                                  name="placa"
                                  id="placa"
                                  className="form-control"
                                  placeholder="Entre com a placa"
                                  onChange={(event) => {
                                    const value =
                                      event.currentTarget.value || "";
                                    setFieldValue(
                                      "placa",
                                      // cnpjMask(value)

                                      placaMask(value.toUpperCase())
                                    );
                                  }}
                                />
                                {errors.placa && touched.placa ? (
                                  <small className="d-flex w-100 text-danger py-1">
                                    *{errors.placa}
                                  </small>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="form-group row ">
                      <label
                        htmlFor="buscaStatus"
                        className="col-sm-2 col-form-label"
                      >
                        Buscar por status da pesagem
                      </label>
                      <div className="col-sm-10 my-auto">
                        <div className="form-check ">
                          <input
                            type="checkbox"
                            className="form-check-input "
                            id="buscaStatus"
                            name="buscaStatus"
                            onChange={() => setBuscaPorStatus(!buscaPorStatus)}
                            checked={buscaPorStatus}
                          />
                        </div>
                      </div>
                    </div>

                    {buscaPorStatus && (
                      <div className="form-group row">
                        <label
                          htmlFor="pickerStatus"
                          className="col-sm-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-sm-10">
                          <Field
                            as="select"
                            required
                            id="pickerStatus"
                            className="form-control"
                            name="status"
                          >
                            <option value={0}>Sem Status</option>
                            <option value={10}>Aguardando Identificação</option>
                            <option value={11}>Aguardando Rota</option>
                            <option value={12}>Aguardando Nota Fiscal</option>
                            <option value={13}>Aguardando Tarar</option>
                            <option value={14}>
                              Aguardando Aprovação de Rota
                            </option>
                            <option value={15}>Aguardando Cancelamento</option>
                            <option value={20}>Cancelada</option>
                            <option value={30}>Concluída</option>
                            <option value={31}>Tara</option>
                            <option value={32}>Rota Aprovada</option>
                            <option value={33}>Pesagem com NF</option>
                            <option value={34}>Pesagem com TBe</option>
                            <option value={99}>Erro Status</option>
                            <option value={100}>Pesagem Avulsa</option>
                            <option value={101}>Avulsa Aguardando Tara</option>
                          </Field>
                        </div>
                      </div>
                    )}

                    <div className="form-group row ">
                      <label
                        htmlFor="buscaRota"
                        className="col-sm-2 col-form-label"
                      >
                        Buscar por rota
                      </label>
                      <div className="col-sm-1 my-auto">
                        <div className="form-check ">
                          <Field
                            type="checkbox"
                            className="form-check-input "
                            id="buscaRota"
                            checked={buscaPorRota}
                            onChange={() => {
                              setBuscaPorRota((prevState) => !prevState);
                              setFieldValue("rotaId", {label: 'TODAS', value: 0});
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {buscaPorRota && (
                      <div className="form-group row">
                        <label
                          htmlFor="pickerRota"
                          className="col-sm-2 col-form-label"
                        >
                          Rota
                        </label>
                        <div className="col-sm-10">
                          <Field
                            component={RotasSelectField}
                            name="rotaId"
                            options={rotas}
                            noOptionsMessage={() => "Rota não encontrada!"}
                            defaultValue={rotas[0]}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group row">
                      <div className="text-center">
                        <ButtonWithLoading loading={loading}>
                          Pesquisar
                        </ButtonWithLoading>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </SimpleCard>
    </>
  );
};

export default CardFiltragem;
