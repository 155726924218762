export const phoneMask = (v) => {
  if (!v) return ""; 
  v = v.replace(/\D/g, ""); 
  v = v.replace(/^(\d\d)(\d)/g, "($1) $2"); 

  if (v.length >= 11) {
    v = v.replace(/(\d{5})(\d)/, "$1-$2"); 
  } else {
    v = v.replace(/(\d{4})(\d)/, "$1-$2"); 
  }

  return v;
};

export const cnpjMask = (v) => {
  if (!v) return ""; 
  v = v.replace(/\D/g, ""); 
  v = v.replace(/(\d{2})(\d)/, "$1.$2");
  v = v.replace(/(\d{3})(\d)/, "$1.$2");
  v = v.replace(/(\d{3})(\d)/, "$1/$2");
  v = v.replace(/(\d{4})(\d)/, "$1-$2");

  return v;
};

export const dateMask = (v) => {
  if (!v) return ""; 
  v = v.replace(/\D/g, ""); 
  const day = v.slice(0, 2);
  const month = v.slice(2, 4);
  const year = v.slice(4);

  return `${day}/${month}/${year}`;
};

export const placaMask = (v) => {
  if (!v) return ""; 
  if (!v.match(/-/)) {
    v = v.replace(/([A-Z0-9]{3})([A-Z0-9])/, "$1-$2"); 
  }

  v = v.replace(/(?<=^.{8})(.*)/, "");
  return v;
};

export const listaCaminhoesMask = (v) => {
  if (!v) return ""; 
  if (!v.match(/^\d+(( \d+)*|(\t\d+)*) ?$/g)) {
    v = v.replace(/[^\d\s]/g, "");
  }

  return v;
};

export const removeMasks = (v) => {
  if (!v) return ""; 
  v = v.replace(/\D/g, "");
  return v;
};