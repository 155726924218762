import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { ModalsContext } from "SGP-contexts/ModalsContext";

export const ResultadoTararModal = () => {
  const { sucessoTarar, setSucessoTarar, falhaTarar, setFalhaTarar } =
    useContext(ModalsContext);

  return (
    <>
      {sucessoTarar && (
        <div onClick={() => setSucessoTarar(false)}>
          <Modal show={sucessoTarar}>
            <Modal.Header closeButton onHide={() => setSucessoTarar(false)}>
              <Modal.Title>
                O registro da tara foi salvo com sucesso!
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button
                variant="outline-success"
                onClick={() => setSucessoTarar(false)}
              >
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}

      {falhaTarar.estado && (
        <div
          onClick={() => setFalhaTarar({ estado: false, mesagemDeErro: "" })}
        >
          <Modal show={falhaTarar.estado}>
            <Modal.Header
              closeButton
              onHide={() => setFalhaTarar({ estado: false, mesagemDeErro: "" })}
            >
              <Modal.Title>
                O salvamento do registro de tara falhou!
              </Modal.Title>
            </Modal.Header>
            {falhaTarar.mensagemDeErro && (
              <Modal.Body>
                <span>{falhaTarar.mensagemDeErro}</span>
              </Modal.Body>
            )}
            <Modal.Footer>
              <Button
                variant="outline-danger"
                onClick={() =>
                  setFalhaTarar({ estado: false, mesagemDeErro: "" })
                }
              >
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};
