import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "app/App";
import * as serviceWorker from "./serviceWorker";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authentication/authConfig.js";
import { LoginDataContextProvider } from "SGP-contexts/LoginDataContext";
import { BrowserRouter } from "react-router-dom";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <LoginDataContextProvider>
        <App />
      </LoginDataContextProvider>
    </BrowserRouter>
  </MsalProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
