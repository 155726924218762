import React, { createContext, useState } from "react";

export const CadastroBalancasContext = createContext();

export function CadastroBalancasContextProvider({ children }) {
  const [plataformaIndex, setPlataformaIndex] = useState(-1);

  const [balancasCriadas, setBalancasCriadas] = useState([]);

  const [indexSelecionado, setIndexSelecionado] = useState(-1);

  const [item, setItem] = useState({});

  const selecionarItem = (item, index) => {
    setIndexSelecionado(index);
    setItem(item);
  };

  return (
    <CadastroBalancasContext.Provider
      value={{
        balancasCriadas,
        setBalancasCriadas,
        selecionarItem,
        indexSelecionado,
        item,
        plataformaIndex,
        setPlataformaIndex,
      }}
    >
      {children}
    </CadastroBalancasContext.Provider>
  );
}
