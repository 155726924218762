import React, { useContext } from "react";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import { Modal, Button } from "react-bootstrap";

export const NenhumRegistroModal = () => {
  const { nenhumRegistro, setNenhumRegistro } = useContext(ModalsContext);

  return (
    <>
      {nenhumRegistro.estado && (
        <div
          onClick={() => {
            setNenhumRegistro({ estado: false, mensagemDeErro: "" });
          }}
        >
          <Modal
            show={nenhumRegistro.estado}
            onClick={(event) => event.stopPropagation()}
          >
            <Modal.Header
              closeButton
              onHide={() =>
                setNenhumRegistro({
                  estado: false,
                  mensagemDeErro: "",
                })
              }
            >
              <Modal.Title>
                Nenhum registro encontrado para a busca.
              </Modal.Title>
            </Modal.Header>
            {nenhumRegistro.mensagemDeErro && (
              <Modal.Body>
                <span>{nenhumRegistro.mensagemDeErro}</span>
              </Modal.Body>
            )}
            <Modal.Footer>
              <Button
                variant="outline-danger"
                onClick={() => {
                  setNenhumRegistro({
                    estado: false,
                    mensagemDeErro: "",
                  });
                }}
              >
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};
