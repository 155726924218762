import React, { useState, useEffect, useContext, Suspense } from "react";
import "../styles/app/app.scss";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import Page404 from "pages/Page404/Page404";
import Redirect from "SGP-components/Login/Redirect";
import PaginaLavagemPneus from "../pages/LavagemPneus/PaginaLavagemPneus";
import { CadastroBalancasContextProvider } from "../SGP-contexts/CadastroBalancasContext";
import { FilterContextProvider } from "SGP-contexts/FilterContext";
import { ModalsContextProvider } from "SGP-contexts/ModalsContext";
import PaginaLogin from "pages/Login/PaginaLogin";
import WithNav from "SGP-components/Routing/WithNav";
import WithoutNav from "SGP-components/Routing/WithoutNav";
import VisualizacaoPdfTbe from "../SGP-components/PDFQrCode/ViewerPdfQrCode";

import SimpleCard from "../@gull/components/cards/SimpleCard";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { LoginDataContext } from "SGP-contexts/LoginDataContext";

import PageFallback from "SGP-components/Common/PageFallback";
import { formatDate } from "utils/formatDate";
import PaginaPesagem from "../pages/Pesagem/PaginaPesagem";
import Axios from "axios";
import { NavigationBarUnauthenticated } from "SGP-components/NavigationBar";

const PaginaCadastroRotas = React.lazy(() =>
  import("pages/Rota/PaginaCadastroRota")
);

const PaginaRelatorioAnalitico = React.lazy(() =>
  import("pages/RelatorioAnalítico/PaginaRelatorioAnalitico")
);

const PaginaRelatorioAvulso = React.lazy(() =>
  import("pages/RelatorioAvulso/PaginaRelatorioAvulso")
);

const PaginaRelatorioAvulsoAnalitico = React.lazy(() =>
  import("pages/RelatorioAvulsoAnalítico/PaginaRelatorioAvulsoAnalitico")
);

const PaginaBalancas = React.lazy(() =>
  import("pages/Balancas/PaginaBalancas")
);
const PaginaCarretas = React.lazy(() => import("pages/Carreta/PaginaCarretas"));
const PaginaCadastrosBalancas = React.lazy(() =>
  import("../pages/Balancas/PaginaCadastrosBalancas")
);
const PaginaAmbiente = React.lazy(() =>
  import("pages/Ambiente/PaginaAmbiente")
);
const PaginaTransportadora = React.lazy(() =>
  import("pages/Transportadora/PaginaTransportadora")
);

const PaginaCaminhoes = React.lazy(() =>
  import("pages/Caminhoes/PaginaCaminhoes")
);

const PaginaCadastroTransportadora = React.lazy(() =>
  import("pages/Transportadora/PaginaCadastroTransportadora")
);
const PaginaRotas = React.lazy(() => import("pages/Rotas/PaginaRotas"));
const PaginaBalanceiro = React.lazy(() =>
  import("pages/Balanceiro/PaginaBalanceiro")
);
const PaginaRelatorios = React.lazy(() =>
  import("pages/Relatorios/PaginaRelatorios")
);

const PaginaNotaFiscal = React.lazy(() =>
  import("pages/NotaFiscal/PaginaNotaFiscal")
);

const PaginaPesagensTemporarias = React.lazy(() =>
  import("pages/PesagensTemporarias/PaginaPesagensTemporarias")
);

const PaginaDeAprovacoes = React.lazy(() =>
  import("pages/Aprovacoes/PaginaDeAprovacoes")
);

const PaginaEsteiras = React.lazy(() => import("pages/Esteira/PaginaEsteiras"));

const PaginaPowerBi = React.lazy(() => import("pages/PowerBi/PaginaPowerBi"));

const PaginaVisualizacao = React.lazy(() => import("pages/Visualizacao/PaginaVisualizacao"));

function App() {
  const { accounts, inProgress, instance } = useMsal();
  const { setAxiosInterceptor } = useContext(LoginDataContext);


  useEffect(() => {
    setAxiosInterceptor();
    // eslint-disable-next-line
  }, [accounts, inProgress, instance]);




  return (
    <CadastroBalancasContextProvider>
      <FilterContextProvider>
        <ModalsContextProvider>
          <AuthenticatedTemplate>
            <Suspense fallback={<PageFallback />}>
              <Routes>
                <Route element={<WithNav />}>
                  <Route path="/" element={<PaginaPesagem />} />

                  <Route path="/balancas" element={<PaginaBalancas />} />
                  <Route
                    path="/balancas/cadastro"
                    element={<PaginaCadastrosBalancas />}
                  />
                  <Route path="/carretas" element={<PaginaCarretas />} />

                  <Route
                    path="/transportadora"
                    element={<PaginaTransportadora />}
                  />
                  <Route
                    path="/transportadora/cadastro"
                    element={<PaginaCadastroTransportadora />}
                  />
                  <Route
                    path="/caminhoes/:action"
                    element={<PaginaCaminhoes />}
                  />
                  <Route path="/rotas" element={<PaginaRotas />} />
                  <Route path="/balanceiro" element={<PaginaBalanceiro />} />
                  <Route
                    path="/relatorios/sintetico"
                    element={<PaginaRelatorios />}
                  />
                  <Route
                    path="/relatorios/analitico"
                    element={<PaginaRelatorioAnalitico />}
                  />
                  <Route
                    path="/relatorios/avulso"
                    element={<PaginaRelatorioAvulso />}
                  />
                  <Route
                    path="/relatorios/avulsoAnalitico"
                    element={<PaginaRelatorioAvulsoAnalitico />}
                  />
                  <Route path="/ambiente" element={<PaginaAmbiente />} />
                  <Route path="/notaFiscal" element={<PaginaNotaFiscal />} />
                  <Route
                    path="/pesagensTemporarias"
                    element={<PaginaPesagensTemporarias />}
                  />
                  <Route path="/aprovacoes" element={<PaginaDeAprovacoes />} />
                  <Route path="/powerbi" element={<PaginaPowerBi />} />
                  <Route path="/pontoDeControle" element={<PaginaLavagemPneus />} />
                  <Route path="/dadosDeProcessos" element={<PaginaEsteiras />} />
                  <Route
                    path="/rota/:action"
                    element={<PaginaCadastroRotas />}
                  />
                  <Route path="/visualizacao" element={<PaginaVisualizacao />} />
                  <Route path="/tbe" element={<VisualizacaoPdfTbe />} />
                  <Route path="/tbe/*" element={<Page404 />} />
                  {/* <Route
                    path="/aprovacoes"
                    element={<PaginaDeAprovacaoDeRotaManual />}
                  /> */}

                  <Route path="*" element={<Page404 />} />
                </Route>
              </Routes>
            </Suspense>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Routes>
              <Route element={<WithoutNav />}>
                <Route path="/" element={<PaginaLogin />} />
                <Route path="*" element={<Redirect />} />
                <Route path="/tbe" element={<VisualizacaoPdfTbe />} />
                <Route path="/tbe/*" element={<Page404 />} />
              </Route>
            </Routes>
          </UnauthenticatedTemplate>
        </ModalsContextProvider>
      </FilterContextProvider>
    </CadastroBalancasContextProvider>
  );
}

export default App;