import React, { useContext, useEffect, useState, useRef } from "react";
import { useDidMount } from "hooks/useDidMount";
import { FilterContext } from "SGP-contexts/FilterContext";
import { ModalsContext } from "SGP-contexts/ModalsContext";

import SimpleCard from "../../@gull/components/cards/SimpleCard";
import ButtonWithLoading from "SGP-components/Common/ButtonWithLoading";
import RotasSelectField from "SGP-components/Common/CustomSelectField";

import { Formik, Form, Field } from "formik";
import { PlacaENumeroFrotaSchema } from "Schemas/PlacaENumeroFrotaSchema";
import { placaMask } from "Schemas/Masks";

import axios from "axios";
import { validateTimeRange, getRequestUrl } from "utils/filtrosLavagem";

const CardFiltragem = ({ setDadosPesagem, setRequestUrl }) => {
  const apiUrl = process.env.REACT_APP_APIURL;
  const MockData = [
    {
      id: "13f934a0-bad0-4d5f-acec-eb4adcbfd14b",
      FileName:
        "RUZ069_03/10/2023T02:03:59_4a611f43-2653-4f98-bcee-a8ec87f169bc.jpg",
      urlImagem:
        "https://images.pexels.com/photos/2676842/pexels-photo-2676842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      Tipo: 1,
      Plate: "RUZ069",
      Rota: "123",
      Frota: "1234",
      DateTimeUtc: "2023-10-03T02:03:59.287Z",
    },
    {
      id: "13f934a0-bad0-4d5f-acec-eb4adcbfd14b",
      FileName:
        "RUZ069_03/10/2023T02:03:59_4a611f43-2653-4f98-bcee-a8ec87f169bc.jpg",
      urlImagem:
        "https://images.pexels.com/photos/2676842/pexels-photo-2676842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      Tipo: 1,
      Plate: "RUZ069",
      Rota: "123",
      Frota: "1234",
      DateTimeUtc: "2023-10-03T02:03:59.287Z",
    },
    {
      id: "13f934a0-bad0-4d5f-acec-eb4adcbfd14b",
      FileName:
        "RUZ069_03/10/2023T02:03:59_4a611f43-2653-4f98-bcee-a8ec87f169bc.jpg",
      urlImagem:
        "https://images.pexels.com/photos/2676842/pexels-photo-2676842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      Tipo: 2,
      Plate: "RUZ069",
      Rota: "123",
      Frota: "1234",
      DateTimeUtc: "2023-10-03T02:03:59.287Z",
    },
  ];

  const { balancas, setUrlUltimoRequest } = useContext(FilterContext);
  const { setNenhumRegistro } = useContext(ModalsContext);

  const [buscaPorIdentificacao, setBuscaPorIdentificacao] = useState({
    estado: false,
    numeroFrotaForm: true,
    placaForm: false,
  });
  const [buscaPorPeriodo, setBuscaPorPeriodo] = useState(false);

  const [tipoVeiculo, setTipoVeiculo] = useState("--");

  const [tipoLeituraLpr, setTipoLeituraLpr] = useState("0");

  const [buscaPorTipo, setBuscaPorTipo] = useState(false);

  const [buscaPorRota, setBuscaPorRota] = useState(false);

  const [canUpdateRequest, setCanUpdateRequest] = useState(false);

  const [timer, setTimer] = useState(0);

  const [loading, setLoading] = useState(false);

  const [rotas, setRotas] = useState([]);

  const requestUrl = useRef();

  const didMount = useDidMount();

  useEffect(() => {
    //Effeito disparado em loop para adicionar ao contador a cada 30 segundos (atualiza a página da pesagem)
    const pesagemInterval = setInterval(() => {
      setTimer((currentTime) => currentTime + 1);
    }, 30000);

    return () => clearInterval(pesagemInterval);
  });

  useEffect(() => {
    //Atualiza a tela da pesagem quando o timer mudar

    if (didMount) {
      if (canUpdateRequest) {
        getRegistros(requestUrl.current);
      }
    }
    // eslint-disable-next-line
  }, [timer]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/Rotas`)
      .then((res) => {
        const formatedResponse = res.data.map((rota) => {
          return {
            value: rota.rotaId,
            label: `${rota.rotaId} - ${rota.nome}`,
          };
        });

        setRotas([{ value: 0, label: "TODAS" }, ...formatedResponse]);
      })
      .catch((error) => console.log(error));
  }, []);

  const getRegistros = (url) => {
    setUrlUltimoRequest(url);
    setLoading(true);
    setCanUpdateRequest(true);
    axios
      .get(url)
      .then((res) => {
        setDadosPesagem(res.data.retorno);
        setCanUpdateRequest(true);
        setLoading(false);
      })
      .catch((error) => {
        setCanUpdateRequest(false);
        setNenhumRegistro({
          estado: true,
          mensagemDeErro: error.response.data,
        });
        setLoading(false);
      });
  };
  const getRequestUrl = (
    params,
    buscaPorIdentificacao,
    buscaPorPeriodo,
    buscaPorRota
  ) => {
    const apiUrl = process.env.REACT_APP_APIURL;
    let requestUrl = `${apiUrl}/apiwebapp/LeituraLpr/BuscarLeituraLpr?QTD=${params.quantidade}`;
    let isValid = true;

    const addHours = (dateString, time, hoursToAdd) => {
      const [hour, minute] = time.split(":").map(Number);
      const date = new Date(`${dateString}T${time}:00`);
      date.setHours(date.getHours() + hoursToAdd);
      const adjustedDate = date.toISOString().split("T")[0];
      const adjustedTime = date.toTimeString().slice(0, 5);
      return { adjustedDate, adjustedTime };
    };

    if (buscaPorPeriodo) {
      isValid = validateTimeRange(params.timeRange);

      if (isValid) {
        let inicioAjustado, fimAjustado;

        if (params.timeRange.inicio.hora === "00:00" && params.timeRange.fim.hora === "23:59" && params.timeRange.inicio.data === params.timeRange.fim.data) {
          inicioAjustado = { adjustedDate: params.timeRange.inicio.data, adjustedTime: "00:00" };
          fimAjustado = { adjustedDate: params.timeRange.fim.data, adjustedTime: "23:59" };
        } else {
          inicioAjustado = addHours(params.timeRange.inicio.data, params.timeRange.inicio.hora, 3);
          fimAjustado = addHours(params.timeRange.fim.data, params.timeRange.fim.hora, 3);
        }

        requestUrl =
          requestUrl +
          `&Inicio=${inicioAjustado.adjustedDate}T${inicioAjustado.adjustedTime}:00` +
          `&Fim=${fimAjustado.adjustedDate}T${fimAjustado.adjustedTime}:59`;
      } else {
        return "Periodo inválido";
      }
    }

    if (buscaPorIdentificacao.estado) {
      if (buscaPorIdentificacao.numeroFrotaForm) {
        requestUrl = requestUrl + `&NumeroFrota=${params.numeroFrota}`;
      } else if (buscaPorIdentificacao.placaForm) {
        requestUrl = requestUrl + `&Placa=${params.placa}`;
      }
    }
    if (buscaPorRota) {
      requestUrl = requestUrl + `&RotaId=${params.rotaId.value}`;
    }

    if (tipoVeiculo !== "--") {
      var enumVeiculo = 0;
      if (tipoVeiculo === "INTERNO") {
        enumVeiculo = 1;
      }
      if (tipoVeiculo === "EXTERNO") {
        enumVeiculo = 2;
      }
      if (tipoVeiculo === "DESCONHECIDO") {
        enumVeiculo = 3;
      }

      requestUrl = requestUrl + `&TipoVeiculo=${enumVeiculo}`;
    }

    var enumTipoLeituraLpr = parseInt(tipoLeituraLpr);
    requestUrl = requestUrl + `&TipoLeituraLpr=${enumTipoLeituraLpr}`;

    setRequestUrl(requestUrl);
    return requestUrl;
  };

  const requestPesagens = (values) => {
    requestUrl.current = getRequestUrl(
      values,
      buscaPorIdentificacao,
      buscaPorPeriodo,
      buscaPorRota,
      tipoVeiculo,
      tipoLeituraLpr
    );
    if (requestUrl === "Periodo inválido") {
      setNenhumRegistro({
        estado: true,
        mensagemDeErro:
          "Período inválido, verifique se o início do período para busca é anterior ao período final",
      });
      setLoading(false);

      return;
    } else getRegistros(requestUrl.current);
  };

  const handleChangeTipoLeituraLpr = (event) => {
    setTipoLeituraLpr(event.target.value);
  };

  return (
    <>
      <SimpleCard
        title="Ponto de Controle"
        subtitle="Entre com os filtros abaixo para trazer os dados"
        className="card text-center"
      >
        <div className="row" style={{ marginRight: "20%", marginLeft: "20%" }}>
          <div className="card mb-5">
            <div className="card-body">
              <Formik
                onSubmit={(values) => {
                  setDadosPesagem([]);

                  setLoading(true);
                  // setParams(values);

                  requestPesagens(values);

                  // requestRegistros(values);
                }}
                initialValues={{
                  origem: "",
                  quantidade: 30,
                  numeroFrota: "",
                  placa: "",
                  rotaId: "",
                  timeRange: {
                    inicio: {
                      data: "",
                      hora: "00:00",
                    },
                    fim: {
                      data: "",
                      hora: "23:59",
                    },
                  },
                }}
                validationSchema={PlacaENumeroFrotaSchema}
              >
                {({ setFieldValue, errors, touched }) => (
                  <Form>
                    <div className="form-group row">
                      <label
                        htmlFor="pickerBalanca"
                        className="col-sm-2 col-form-label"
                      >
                        Origem Leitura
                      </label>
                      <div className="col-sm-10">
                        <select
                          className="form-control"
                          id="tipoVeiculoDropdown"
                          name="tipoVeiculoDropdown"
                          value={tipoLeituraLpr}
                          onChange={handleChangeTipoLeituraLpr}
                        >
                          <option value="0">TODOS</option>
                          <option value="1">LAVADOR</option>
                          <option value="2">ENTRADA</option>
                          <option value="3">SAIDA</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="quantRegistros"
                        className="col-sm-2 col-form-label"
                      >
                        Quantidade de registros
                      </label>
                      <div className="col-sm-10">
                        <Field
                          onWheel={(event) => event.target.blur()}
                          type="number"
                          className="form-control"
                          id="quantRegistros"
                          placeholder="Quantidade de registros"
                          name="quantidade"
                        />
                      </div>
                    </div>

                    <div className="form-group row ">
                      <label
                        htmlFor="buscaPeriodo"
                        className="col-sm-2 col-form-label"
                      >
                        Buscar por período
                      </label>
                      <div className="col-sm-10 my-auto">
                        <div className="form-check ">
                          <input
                            type="checkbox"
                            className="form-check-input "
                            id="buscaPeriodo"
                            name="buscaPeriodo"
                            onChange={() =>
                              setBuscaPorPeriodo(!buscaPorPeriodo)
                            }
                            checked={buscaPorPeriodo}
                          />
                        </div>
                      </div>
                    </div>
                    {buscaPorPeriodo && (
                      <>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">De</label>
                          <div className="col-sm-10">
                            <div className="row">
                              <Field
                                type="date"
                                className="form-control col-auto mx-auto"
                                name="timeRange.inicio.data"
                                style={{ width: "45%" }}
                              />
                              <Field
                                type="time"
                                className="form-control col-auto mx-auto"
                                name="timeRange.inicio.hora"
                                style={{ width: "45%" }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">Até</label>
                          <div className="col-sm-10">
                            <div className="row">
                              <Field
                                type="date"
                                className="form-control col-auto mx-auto"
                                name="timeRange.fim.data"
                                style={{ width: "45%" }}
                              />
                              <Field
                                type="time"
                                className="form-control col-auto mx-auto"
                                id="horaMax"
                                name="timeRange.fim.hora"
                                style={{ width: "45%" }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="form-group row ">
                      <label
                        htmlFor="buscaFrota"
                        className="col-sm-2 col-form-label"
                      >
                        Identificação do veículo
                      </label>
                      <div className="col-sm-1 my-auto">
                        <div className="form-check ">
                          <input
                            type="checkbox"
                            className="form-check-input "
                            id="buscaFrota"
                            onChange={() =>
                              setBuscaPorIdentificacao({
                                ...buscaPorIdentificacao,
                                estado: !buscaPorIdentificacao.estado,
                              })
                            }
                          />
                        </div>
                      </div>

                      {buscaPorIdentificacao.estado && (
                        <>
                          <div className="row">
                            <div className="col-form-label col-sm-2 pt-0"></div>
                            <div className="col">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="frota"
                                  name="tipoVeiculo"
                                  checked={buscaPorIdentificacao.placaForm}
                                  onChange={() => {
                                    setBuscaPorIdentificacao({
                                      ...buscaPorIdentificacao,
                                      placaForm: true,
                                      numeroFrotaForm: false,
                                      tipoVeiculoForm: false,
                                    });
                                    setFieldValue("numeroFrota", "");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="frota"
                                >
                                  Placa
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="carreta"
                                  name="tipoVeiculo"
                                  checked={
                                    buscaPorIdentificacao.numeroFrotaForm
                                  }
                                  onChange={() => {
                                    setBuscaPorIdentificacao({
                                      ...buscaPorIdentificacao,
                                      placaForm: false,
                                      numeroFrotaForm: true,
                                      tipoVeiculoForm: false,
                                    });
                                    setFieldValue("placa", "");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="carreta"
                                >
                                  Numero de frota
                                </label>
                              </div>
                            </div>
                          </div>

                          {buscaPorIdentificacao.numeroFrotaForm && (
                            <div className="form-group row">
                              <label
                                htmlFor="numeroFrota"
                                className="col-sm-2 col-form-label"
                              >
                                Número da frota
                              </label>
                              <div className="col-sm-10 my-auto">
                                <Field
                                  type="text"
                                  name="numeroFrota"
                                  id="numeroFrota"
                                  className="form-control"
                                  placeholder="Entre com o número da frota"
                                />
                                {errors.numeroFrota && touched.numeroFrota ? (
                                  <small className="d-flex w-100 text-danger py-1">
                                    *{errors.numeroFrota}
                                  </small>
                                ) : null}
                              </div>
                            </div>
                          )}

                          {buscaPorIdentificacao.placaForm && (
                            <div className="form-group row">
                              <label
                                htmlFor="placa"
                                className="col-sm-2 col-form-label"
                              >
                                Placa
                              </label>
                              <div className="col-sm-10 my-auto">
                                <Field
                                  type="text"
                                  name="placa"
                                  id="placa"
                                  className="form-control"
                                  placeholder="Entre com a placa"
                                  onChange={(event) => {
                                    const value =
                                      event.currentTarget.value || "";
                                    setFieldValue(
                                      "placa",
                                      // cnpjMask(value)

                                      placaMask(value.toUpperCase())
                                    );
                                  }}
                                />
                                {errors.placa && touched.placa ? (
                                  <small className="d-flex w-100 text-danger py-1">
                                    *{errors.placa}
                                  </small>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="form-group row ">
                      <label
                        htmlFor="buscaRota"
                        className="col-sm-2 col-form-label"
                      >
                        Buscar por Tipo de Veículo
                      </label>
                      <div className="col-sm-1 my-auto">
                        <div className="form-check ">
                          <Field
                            type="checkbox"
                            className="form-check-input "
                            id="buscaRota"
                            checked={buscaPorTipo}
                            onChange={() => {
                              setTipoVeiculo("--");
                              setBuscaPorTipo((prevState) => !prevState);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {buscaPorTipo && (
                      <div className="form-group row">
                        <label
                          htmlFor="tipoVeiculoDropdown"
                          className="col-sm-2 col-form-label"
                        >
                          Tipo de Veículo
                        </label>
                        <div className="col-sm-10">
                          <select
                            className="form-control"
                            id="tipoVeiculoDropdown"
                            name="tipoVeiculoDropdown"
                            value={tipoVeiculo}
                            onChange={(event) => {
                              setTipoVeiculo(event.target.value);
                            }}
                          >
                            <option value="--">Todos</option>
                            <option value="INTERNO">Interno</option>
                            <option value="EXTERNO">Externo</option>
                            <option value="DESCONHECIDO">Desconhecido</option>
                          </select>
                        </div>
                      </div>
                    )}

                    <div className="form-group row ">
                      <label
                        htmlFor="buscaRota"
                        className="col-sm-2 col-form-label"
                      >
                        Buscar por rota
                      </label>
                      <div className="col-sm-1 my-auto">
                        <div className="form-check ">
                          <Field
                            type="checkbox"
                            className="form-check-input "
                            id="buscaRota"
                            checked={buscaPorRota}
                            onChange={() => {
                              setBuscaPorRota((prevState) => !prevState);
                              setFieldValue("rotaId", {
                                label: "TODAS",
                                value: 0,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {buscaPorRota && (
                      <div className="form-group row">
                        <label
                          htmlFor="pickerRota"
                          className="col-sm-2 col-form-label"
                        >
                          Rota
                        </label>
                        <div className="col-sm-10">
                          <Field
                            component={RotasSelectField}
                            name="rotaId"
                            options={rotas}
                            noOptionsMessage={() => "Rota não encontrada!"}
                            defaultValue={rotas[0]}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group row">
                      <div className="text-center">
                        <ButtonWithLoading loading={loading}>
                          Pesquisar
                        </ButtonWithLoading>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </SimpleCard>
    </>
  );
};

export default CardFiltragem;
