import React, { useEffect, useContext, useState } from "react";
import LavagemCardFiltragem from "../../SGP-components/LavagemPneus/LavagemCardFiltragem";
import LavagemViewModal from "../../SGP-components/LavagemPneus/LavagemViewModal";
import LavagemEditModal from "SGP-components/LavagemPneus/LavagemEditModal";
import LavagemMainTable from "../../SGP-components/Common/TabelaRegistrosLavagem";
import { ImageModal } from "SGP-components/Common/ImageModal";
import { ViewModal as PesagemViewModal } from "SGP-components/Common/ViewModal";
import { FilterContext } from "SGP-contexts/FilterContext";
import axios from "axios";
import { NenhumRegistroModal } from "SGP-components/Common/NenhumRegistroModa";
import { LoginDataContext } from "SGP-contexts/LoginDataContext";
import { ResultadoCadastroModal as PesagemResultadoCadastroModal } from "SGP-components/Common/ResultadoCadastroModal";

const PaginaLavagemPneus = () => {
  const { accessToken } = useContext(LoginDataContext);
  const { setBalancas } = useContext(FilterContext);
  const [dadosPesagem, setDadosPesagem] = useState([]);
  const [dadosCardsPesagem, setDadosCardsPesagem] = useState([]);
  const [registroSelecionado, setRegistroSelecionado] = useState();
  const [requestUrl, setRequestUrl] = useState('');

  const apiUrl = process.env.REACT_APP_APIURL;
  useEffect(() => {console.log(registroSelecionado)},[registroSelecionado])

 
  return (
    <>
      <LavagemCardFiltragem
        setDadosPesagem={(apiData) => setDadosPesagem(apiData)}
        setRequestUrl={setRequestUrl}
      />
      <LavagemMainTable
        dados={dadosPesagem}
        selectItem={(id) => setRegistroSelecionado(id)}
        setDadosPesagem={(apiData) => setDadosPesagem(apiData)}
        requestUrl={requestUrl}
      />
      <LavagemViewModal
        dados={registroSelecionado}
      />
      <ImageModal />
      <NenhumRegistroModal />
      <LavagemEditModal
        dados={registroSelecionado}
        setDadosPesagem={(apiData) => setDadosPesagem(apiData)}
        requestUrl={requestUrl}
      />
      <PesagemResultadoCadastroModal />
    </>
  );
};

export default PaginaLavagemPneus;


//selectItemLavagem={(tokenLavagem) => {setTokenLavagemSelecionada(tokenLavagem)}}