import React, { createContext, useState } from "react";
import { loginRequest } from "../authentication/authConfig";
import axios from "axios";
import { useMsal } from "@azure/msal-react";

export const LoginDataContext = createContext();

export function LoginDataContextProvider({ children }) {
  const { instance, accounts } = useMsal();

  const [accessToken, setAccessToken] = useState("");
  const [graphData, setGraphData] = useState({});

  const refreshAccessToken = async () => {
    const request = {
      scopes: loginRequest.scopes.user,
      account: accounts[0],
    };

    let token = {};

    if (request.account) {
      token = await instance
        .acquireTokenSilent(request)
        .then((response) => {
          setAccessToken(response.accessToken);

          return response.accessToken;
        })
        .catch(() => {
          instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.accessToken);

            return response.accessToken;
          });
        });

      return token;
    }
  };

  const setAxiosInterceptor = async () => {
    axios.interceptors.request.use(async (config) => {
      const token = await refreshAccessToken();

      if (token) {
        config.headers.common["Authorization"] = `Bearer ${token}`;
      }
      return config;
    });
  };

  const getUserName = () => {
    if (accounts && accounts.length > 0) {
      return accounts[0].username;
    }
    return null;
  };

  return (
    <LoginDataContext.Provider
      value={{
        accessToken,
        setAccessToken,
        graphData,
        setGraphData,
        refreshAccessToken,
        setAxiosInterceptor,
        getUserName,
      }}
    >
      {children}
    </LoginDataContext.Provider>
  );
}