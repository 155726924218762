import React, { useContext, useState } from "react";
import SimpleCard from "../../@gull/components/cards/SimpleCard";
import {
  OverlayTrigger,
  Popover,
  Tooltip,
  Button,
  Spinner,
} from "react-bootstrap";
import { FilterContext } from "SGP-contexts/FilterContext";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import axios from "axios";
import { formatDate } from "utils/formatDate";
import { FaEdit, FaCheck, FaTimes  } from "react-icons/fa";

const TabelaRegistros = ({ dados, selectItem, requestUrl }) => {
  const renderTooltip = (props) => <Tooltip id="icon-tooltip">{props}</Tooltip>;
  const { urlUltimoRequest } = useContext(FilterContext);
  const [excelDownloadLoading, setExcelDownloadLoading] = useState(false);
  const {
    switchViewModal,
    setIdentificarLavagemModal,
    lavagemIdentificarModal,
  } = useContext(ModalsContext);
  const dateToUTC = (date) => {
    let dataFinal = date + "Z";
    return formatDate(dataFinal);
  };

  const apiUrl = process.env.REACT_APP_APIURL;
  const getRelatorioExcel = async () => {
    setExcelDownloadLoading(true);
    let baseRequestUrl = `${apiUrl}/apiwebapp/LeituraLpr/BaixarExcelRegistrosLeituraLpr`;

    const searchValues = requestUrl.split("?");
    baseRequestUrl += `?${searchValues[1]}`;

    try {
      const response = await axios.get(baseRequestUrl, {
        responseType: "blob", // Set the response type to 'blob'
      });

      // Create a temporary anchor element
      const anchor = document.createElement("a");
      anchor.href = URL.createObjectURL(new Blob([response.data]));

      // Set the file name
      let fileName = "Registros de Leituras Lpr.xlsx";
      anchor.download = fileName;

      // Append the anchor to the body and click it to initiate the download
      document.body.appendChild(anchor);
      anchor.click();

      // Remove the anchor from the body
      document.body.removeChild(anchor);
    } catch (error) {
      console.error("Error downloading the Excel sheet:", error);
    } finally {
      setExcelDownloadLoading(false);
    }
  };

  const exibeTipoLeituraLpr = (tipoLeituraLpr) => {
    if (tipoLeituraLpr === 1) return "Lavador";
    if (tipoLeituraLpr === 2) return "Entrada";
    if (tipoLeituraLpr === 3) return "Saída";
  };

  const checkStatusLavagem = (boolean, origem) => {
    const leituraLpr = exibeTipoLeituraLpr(origem);
    if (leituraLpr === "Saída" && boolean === true) {
      return (
        <FaCheck color="green" size={18} />
      );
    }
    else if (leituraLpr === "Saída" && boolean === false) {
      return (
        <FaTimes color="red" size={18} />
      );
    }
    else {
      return "--";
    }
  };

  return (
    <div className="card  mx-3 my-4 overflow-auto">
      <div>
        <SimpleCard>
          <div className="simple-card-title d-flex flex-wrap justify-content-between align-items-center">
            <h3 className="text-capitalize mb-2">Registros</h3>
            {dados.length > 0 ? (
              <Button
                className="mb-2"
                style={{ width: "150px" }}
                onClick={getRelatorioExcel}
              >
                {excelDownloadLoading ? (
                  <Spinner animation="border" size="sm" className="mx-2" />
                ) : (
                  "Baixar Planilha"
                )}
              </Button>
            ) : null}
          </div>
          <div className="table-responsive">
            <table
              style={{ minWidth: "1293px" }}
              id="tabelaDeRegistros"
              className="table table-striped text-center"
            >
              <thead>
                <tr>
                  <th scope="col">Data e hora</th>
                  <th scope="col">Placa</th>
                  <th scope="col">Rota</th>
                  <th scope="col">Identificação</th>
                  <th scope="col">Tipo do veículo</th>
                  <th scope="col">Origem</th>
                  <th scope="col">Lavado</th>
                </tr>
              </thead>
              <tbody>
                {dados &&
                  dados.map((data, index) => {
                    const dataFormatada = dateToUTC(data.dataHoraUTC);

                    return (
                      <OverlayTrigger
                        key={`${index} ${data.id}`}
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={ImgPopover(data.imagemURL)}
                      >
                        <tr
                          onClick={() => {
                            console.log(data);
                            selectItem(data);
                            switchViewModal();
                          }}
                        >
                          <td
                            style={{ cursor: "pointer" }}
                            className="text-break"
                          >
                            {dataFormatada}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {data.placa !== " " ? data.placa : "--"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {data.rotaNome ? data.rotaNome : "--"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {data.numeroFrota ? data.numeroFrota : "--"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {data.tipoVeiculo === 1
                              ? "Interno"
                              : data.tipoVeiculo === 2
                              ? "Externo"
                              : "Desconhecido"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {exibeTipoLeituraLpr(data.tipoLeituraLpr)}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {checkStatusLavagem(
                              data.lavado,
                              data.tipoLeituraLpr
                            )}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {data.tipoVeiculo === 3 && (
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                trigger={["hover", "focus"]}
                                overlay={renderTooltip("Identificar veículo")}
                              >
                                <span className="cursor-pointer mx-1 text-info">
                                  <FaEdit
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      selectItem(data);
                                      lavagemIdentificarModal();
                                    }}
                                    color="#1E2E4F"
                                    size={18}
                                  />
                                </span>
                              </OverlayTrigger>
                            )}
                          </td>
                        </tr>
                      </OverlayTrigger>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </SimpleCard>
      </div>
    </div>
  );
};

const ImgPopover = (imagem) => {
  return (
    <Popover style={{ maxWidth: "700px" }}>
      <Popover.Body>
        <div className="col-sm mw-25">
          <img
            src={imagem}
            alt="Imagem do caminhão"
            className="h-100 rounded"
          />
        </div>
      </Popover.Body>
    </Popover>
  );
};

export default TabelaRegistros;
