import React, { useContext, useMemo, useState } from "react";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import { Modal, Button } from "react-bootstrap";
import { Form, Formik, Field } from "formik";
import ButtonWithLoading from "./ButtonWithLoading";
import axios from "axios";
import { FilterContext } from "SGP-contexts/FilterContext";

const CancelarPesagemModal = ({
  tokenPesagemSelecionada,
  dadosPesagem,
  setDadosPesagem,
}) => {
  const {
    cancelarPesagemModal,
    setCancelarPesagemModal,
    setSucessoCadastro,
    setFalhaCadastro,
  } = useContext(ModalsContext);
  const { urlUltimoRequest } = useContext(FilterContext);

  const [loading, setLoading] = useState(false);

  const [selectedItem] = useMemo(() => {
    if (Array.isArray(dadosPesagem)) {
      return dadosPesagem.filter(
        (item) => item.tokenPesagem === tokenPesagemSelecionada
      );
    } else return [];
  }, [dadosPesagem, tokenPesagemSelecionada]);

  const apiUrl = process.env.REACT_APP_APIURL;

  const solicitarCancelamento = ({ tokenPesagem, descricao }) => {
    setLoading(true);
    axios
      .post(
        `${apiUrl}/apiwebapp/Pesagem/SolicitarCancelamentoPesagem?TokenPesagem=${tokenPesagem}&Descricao=${descricao}`
      )
      .then(() => {
        updateTable();
        setCancelarPesagemModal(false);
        setSucessoCadastro({ estado: true, mensagem: "" });
      })
      .catch((error) => setFalhaCadastro({ estado: true, mensagemDeErro: "" }))
      .finally(() => setLoading(false));
  };

  const updateTable = () => {
    axios
      .get(urlUltimoRequest)
      .then((res) => setDadosPesagem(res.data.retorno))
      .catch((error) => console.log(error));
  };

  return (
    <>
      {cancelarPesagemModal && (
        <div
          onClick={() => {
            setCancelarPesagemModal(false);
          }}
        >
          <Modal
            show={cancelarPesagemModal}
            onClick={(event) => event.stopPropagation()}
          >
            <Modal.Header
              closeButton
              onHide={() => setCancelarPesagemModal(!cancelarPesagemModal)}
            >
              <Modal.Title>Requisição de cancelamento da pesagem</Modal.Title>
            </Modal.Header>

            <Formik
              initialValues={{
                tokenPesagem: selectedItem.tokenPesagem || "",
                descricao: "",
              }}
              onSubmit={(values) => solicitarCancelamento(values)}
            >
              {() => (
                <Form>
                  <Modal.Body>
                    <div className="form-group row">
                      <label
                        htmlFor="idPesagem"
                        className="col-sm-2 col-form-label text-center"
                      >
                        Id da pesagem
                      </label>
                      <div className="col-sm-10">
                        <Field
                          type="text"
                          className="form-control"
                          id="idPesagem"
                          placeholder="Id da pesagem"
                          name="tokenPesagem"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="descricao"
                        className="col-sm-2 col-form-label text-center"
                      >
                        Justificativa*
                      </label>
                      <div className="col-sm-10">
                        <Field
                          type="text"
                          as="textarea"
                          className="form-control"
                          id="descricao"
                          placeholder="Justificativa..."
                          name="descricao"
                          required
                        />
                      </div>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <ButtonWithLoading
                      loading={loading}
                      buttonVariant="outline-success"
                      spinnerVariant="sucess"
                    >
                      Solicitar cancelamento
                    </ButtonWithLoading>
                    <Button
                      variant="outline-danger"
                      onClick={() =>
                        setCancelarPesagemModal(!cancelarPesagemModal)
                      }
                    >
                      Cancelar
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
      )}
    </>
  );
};

export default CancelarPesagemModal;
