import React from "react";
import PdfTbeComum from "./pdfTbeComum";
import TemplatePdfTbe from "./TemplatePdfTbe";

const PdfTbe = ({ relatorioData }) => {
  return (
    <TemplatePdfTbe
      relatorioData={relatorioData}
    >
      <PdfTbeComum
        dadosRelatorio={relatorioData}
      />

    </TemplatePdfTbe>
  );
};

export default PdfTbe;
