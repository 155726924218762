import React from "react";
import { Navbar, Nav, NavDropdown, Container, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { HiOutlineLogout } from "react-icons/hi";
import styles from "../styles/Custom/navBar.module.css";
import { useMsal } from "@azure/msal-react";
import ItaLogo from "../Assets/logo-itaminas-branco.png";

const handleLogout = (instance) => {
  instance.logoutRedirect().catch((error) => console.log(error));
};

const NavigationBar = () => {
  const { instance, accounts } = useMsal();

  let navigate = useNavigate();

  return (
    <Navbar className={styles.navBar} sticky="top" variant="dark" expand="xl">
      <Container fluid>
        <Navbar.Brand onClick={() => navigate("/")} style={{cursor: 'pointer'}}>
          <img
            style={{
              width: "200px",
              height: "100%",
              padding: "10px 0 10px 20px",
            }}
            alt="Itaminas logo"
            src={ItaLogo}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ms-auto">
            <Nav.Link onClick={() => navigate("/")}>Pesagem</Nav.Link>
            <Nav.Link onClick={() => navigate("/pesagensTemporarias")}>
              Pesagens temporárias
            </Nav.Link>
            <Nav.Link onClick={() => navigate("/rotas")}>Rotas</Nav.Link>
            <Nav.Link onClick={() => navigate("/balanceiro")}>
              Balanceiro
            </Nav.Link>
            <Nav.Link onClick={() => navigate("/pontoDeControle")}>
              Ponto de Controle
            </Nav.Link>
            <NavDropdown title="Documentos">
              <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/relatorios/sintetico")}>
                  Relatório Sintético
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/relatorios/analitico")}>
                  Relatório Analítico
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/relatorios/avulso")}>
                  Relatório Avulso Sintético
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/relatorios/avulsoAnalitico")}>
                  Relatório Avulso Analítico
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/notaFiscal")}>Documentos Fiscais</span>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/aprovacoes")}>Aprovações</span>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/powerbi")}>PowerBI</span>
              </NavDropdown.Item>
              {/* <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/pontoDeControle")}>Ponto de Controle</span>
              </NavDropdown.Item> */}
            </NavDropdown>

            <NavDropdown title="Cadastros">
              <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/carretas")}>Carretas</span>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/caminhoes/tara")}>
                  Caminhões
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/balancas")}>Balanças</span>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/transportadora")}>
                  Transportadoras
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropDownNavItem}>
                <span onClick={() => navigate("/rota/alterardmt")}>Rotas</span>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={() => navigate("/dadosDeProcessos")}>Dados de Processos</Nav.Link>
            {/* <Nav.Link onClick={() => navigate("/visualizacao")}>Visualização</Nav.Link> */}
            {/* <Nav.Link onClick={() => navigate("/esteiras")}>Esteiras</Nav.Link> */}

            <Dropdown
              id="nav-dropdown"
              className="navbar-item mx-2 text-white"
              drop="start"
            >
              <Dropdown.Toggle className={styles.dropDownIcon}>
                <FaUserCircle size={30} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {accounts[0] && (
                  <div className={styles.nomeContainer}>
                    <span className={styles.nomeContainerText}>
                      {accounts[0].name}
                    </span>
                    <span className={styles.nomeContainerText}>
                      {accounts[0].username}
                    </span>
                  </div>
                )}

                <Dropdown.Divider />

                <Dropdown.Item
                  eventKey="4.1"
                  onClick={() => {
                    handleLogout(instance);
                  }}
                >
                  <div className="row text-danger ">
                    <div className="col ">Sair</div>
                    <div className="col-sm-2 ">
                      <HiOutlineLogout size={20} className="pr-3" />
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
//FaUserCircle
export default NavigationBar;


export const NavigationBarUnauthenticated = () => {
  let navigate = useNavigate();

  return (
    <Navbar className={styles.navBar} sticky="top" variant="dark" expand="xl">
      <Container fluid>
        <Navbar.Brand style={{cursor: 'pointer'}}>
          <img
            style={{
              width: "200px",
              height: "100%",
              padding: "10px 0 10px 20px",
            }}
            alt="Itaminas logo"
            src={ItaLogo}
          />
        </Navbar.Brand>
      </Container>
    </Navbar>
  )
}