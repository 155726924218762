export const validateTimeRange = (time) => {
  const d1 = new Date(`${time.inicio.data}T${time.inicio.hora + ":00"}`);
  const d2 = new Date(`${time.fim.data}T${time.fim.hora + ":59"}`);

  if (d2.getTime() <= d1.getTime()) {
    return false;
  } else return true;
};

export const getRequestUrl = (
  params,
  buscaPorIdentificacao,
  buscaPorPeriodo,
  buscaPorStatus,
  buscaPorRota
) => {
  const apiUrl = process.env.REACT_APP_APIURL;
  let requestUrl = `${apiUrl}/apiwebapp/Pesagem/BuscarPesagens?Balanca=${params.balanca}&QTD=${params.quantidade}&SomenteVazios=${params.caminhoesVazios}`;
  let isValid = true;

  const addHours = (dateString, time, hoursToAdd) => {
    const [hour, minute] = time.split(":").map(Number);
    const date = new Date(`${dateString}T${time}:00`);
    date.setHours(date.getHours() + hoursToAdd);
    const adjustedDate = date.toISOString().split("T")[0];
    const adjustedTime = date.toTimeString().slice(0, 5);
    return { adjustedDate, adjustedTime };
  };

  if (buscaPorPeriodo) {
    isValid = validateTimeRange(params.timeRange);

    if (isValid) {
      let inicioAjustado, fimAjustado;

      if (params.timeRange.inicio.hora === "00:00" && params.timeRange.fim.hora === "23:59" && params.timeRange.inicio.data === params.timeRange.fim.data) {
        inicioAjustado = { adjustedDate: params.timeRange.inicio.data, adjustedTime: "00:00" };
        fimAjustado = { adjustedDate: params.timeRange.fim.data, adjustedTime: "23:59" };
      } else {
        inicioAjustado = addHours(params.timeRange.inicio.data, params.timeRange.inicio.hora, 3);
        fimAjustado = addHours(params.timeRange.fim.data, params.timeRange.fim.hora, 3);
      }

      requestUrl =
        requestUrl +
        `&Inicio=${inicioAjustado.adjustedDate}T${inicioAjustado.adjustedTime}:00` +
        `&Fim=${fimAjustado.adjustedDate}T${fimAjustado.adjustedTime}:59`;
    } else {
      return "Periodo inválido";
    }
  }

  if (buscaPorIdentificacao.estado) {
    if (buscaPorIdentificacao.numeroFrotaForm) {
      requestUrl = requestUrl + `&NumeroFrota=${params.numeroFrota}`;
    } else if (buscaPorIdentificacao.placaForm) {
      requestUrl = requestUrl + `&Placa=${params.placa}`;
    }
  }

  if (buscaPorStatus) {
    requestUrl =
      requestUrl + `&TodosOsStatus=false&StatusDesejado=${params.status}`;
  }

  if (buscaPorRota) {
    requestUrl = requestUrl + `&RotaId=${params.rotaId.value}`;
  } else requestUrl = requestUrl + `&RotaId=0`;

  return requestUrl;
};
