export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AD_CLIENTID,
    authority: process.env.REACT_APP_AD_AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    //redirectUri: "http://localhost:3000",
    redirectUri: process.env.REACT_APP_FRONT_URL,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: {
    user: [process.env.REACT_APP_AD_USERSCOPE],
  },
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com",
};
