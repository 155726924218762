import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { FilterContext } from "SGP-contexts/FilterContext";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import { formatDate } from "utils/formatDate";
import { placaMask } from "Schemas/Masks";
import PDFTicketLavagem from "./../Common/PDFTicketLavagem";
import { cnpjMask } from "Schemas/Masks";
import ButtonWithLoading from "../Common/ButtonWithLoading";

const LavagemEditModal = ({ dados, setDadosPesagem, requestUrl }) => {
  const {
    identificarLavagemModal,
    setIdentificarLavagemModal,
    setShowImage,
    setImage,
    setSucessoCadastro,
    setFalhaCadastro,
  } = useContext(ModalsContext);

  const apiUrl = process.env.REACT_APP_APIURL;
  const [loading, setLoading] = useState(false);

  const dataFormatada = formatDate(dados?.dataHoraUTC + "Z");
  const data = {
    id: dados?.id,
    Data: dataFormatada,
    Placa: dados?.placa,
    Caminhão: dados?.numeroFrota,
    imagemURL: dados?.imagemURL,
    "Tipo do Veículo": dados?.tipoVeiculo == 1 ? "Interno" : "Externo",
    Rota: dados?.rotaNome,
  };

  const [placa, setPlaca] = useState("");
  const [placaError, setPlacaError] = useState("");

  const getRequest = () => {
    let baseRequestUrl = `${apiUrl}/apiwebapp/LeituraLpr/BuscarLeituraLpr`;

    const searchValues = requestUrl.split("?");
    baseRequestUrl += `?${searchValues[1]}`;

    axios
      .get(baseRequestUrl)
      .then((res) => {
        setDadosPesagem(res.data.retorno);
        setSucessoCadastro({
          estado: true,
          mensagem: "Veículo identificado com sucesso",
        });
        setPlaca("");
        setLoading(false);
        setIdentificarLavagemModal(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handlePlacaChange = async () => {
    setLoading(true);
    try {
      if (!placa.trim()) {
        setPlacaError("Campo obrigatório");
        return;
      }
      setPlacaError("");
      await axios
        .put(
          `${apiUrl}/apiwebapp/LeituraLpr/IdentificarLeituraLpr?id=${data.id}&placa=${placa}`
        )
        .then(() => {
          getRequest();
        });
      setPlaca("");
    } catch (error) {
      setLoading(false);
      console.log("Erro ao requisitar api: " + error);
      let errorMessage = "Erro desconhecido";
      if (error.response && error.response.data) {
        errorMessage = error.response.data;
      }
      setFalhaCadastro({
        estado: true,
        mensagemDeErro: "Erro ao identificar veículo, " + errorMessage,
      });
      setPlaca("");
      setIdentificarLavagemModal(false);
    }
  };

  return (
    <>
      {identificarLavagemModal && (
        <div>
          <Modal
            size="lg"
            show={identificarLavagemModal}
            scrollable={true}
            onClick={(event) => event.stopPropagation()}
          >
            <Modal.Header
              closeButton
              onHide={() => {
                setIdentificarLavagemModal(false);
              }}
            >
              <Modal.Title>Identificar Veículo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <form>
                  <div>
                    <div className="row">
                      <div className="col">
                        <label htmlFor="idPesagem">ID do registro</label>
                        <input
                          type="text"
                          className="form-control"
                          name="idPesagem"
                          value={dados.id}
                          disabled
                          id="idPesagem"
                        />
                      </div>
                    </div>

                    <br />
                    <div className="row">
                      <div className="col-md-6 form-group ">
                        <label htmlFor="Data">Data</label>
                        <input
                          type="text"
                          className="form-control"
                          name="data"
                          value={dataFormatada}
                          disabled
                          id="Data"
                        />
                      </div>

                      {dados.numeroFrota && (
                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="numeroFrota">Identificacao</label>
                          <input
                            type="text"
                            className="form-control"
                            name="numeroFrota"
                            value={dados.numeroFrota}
                            id="numeroFrota"
                            disabled
                          />
                        </div>
                      )}
                      {dados.rotaNome && (
                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="numeroFrota">Rota</label>
                          <input
                            type="text"
                            className="form-control"
                            name="numeroFrota"
                            value={dados.rotaNome}
                            id="numeroFrota"
                            disabled
                          />
                        </div>
                      )}

                      {dados.placa !== undefined && (
                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="placaCarreta">Placa</label>
                          <input
                            type="text"
                            className={`form-control ${
                              placaError ? "is-invalid" : ""
                            }`}
                            name="placaCarreta"
                            placeholder="Digite a placa"
                            value={placa}
                            id="placaCarreta"
                            onChange={(e) => {
                              const value = e.target.value;
                              setPlaca(placaMask(value.toUpperCase()));
                              setPlacaError("");
                            }}
                            required
                          />
                          {placaError && (
                            <div className="invalid-feedback">{placaError}</div>
                          )}
                        </div>
                      )}
                      {dados.tipoVeiculo && (
                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="placaCarreta">Tipo</label>
                          <input
                            type="text"
                            className="form-control"
                            name="placaCarreta"
                            value={
                              dados.tipoVeiculo === 1
                                ? "Interno"
                                : dados.tipoVeiculo === 2
                                ? "Externo"
                                : dados.tipoVeiculo === 3
                                ? "Desconhecido"
                                : ""
                            }
                            id="placaCarreta"
                            disabled
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </form>
                <section>
                  <br />
                  <Modal.Title>Fotos</Modal.Title>
                  <div className="custom-separator"></div>
                  <div className="row text-center ">
                    {dados.imagemURL !== null && (
                      <div>
                        <div className="col">
                          <img
                            src={dados.imagemURL}
                            alt="Imagem do caminhão"
                            className="w-75 m-2 rounded cursor-pointer"
                            onClick={() => {
                              setShowImage(true);
                              setImage(dados.imagemURL);
                            }}
                          />
                        </div>
                        <div className="w-100"></div>
                      </div>
                    )}
                  </div>
                </section>
              </>
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center">
              <Button
                type={"submit"}
                variant={"outline-success"}
                disabled={loading}
                onClick={() => {
                  if (!placa.trim()) {
                    setPlacaError("Preencha este campo.");
                    return;
                  }
                  setPlacaError("");
                  handlePlacaChange();
                }}
              >
                {loading ? (
                  <Spinner animation="border" variant={"success"} size="sm" />
                ) : (
                  <span>Salvar</span>
                )}
              </Button>
              <Button
                variant="outline-danger"
                onClick={() => {
                  setLoading(false);
                  setPlaca("");
                  setIdentificarLavagemModal(false);
                }}
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default LavagemEditModal;
