import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { ModalsContext } from "SGP-contexts/ModalsContext";

const CreateModal = () => {
  const { createModal, switchCreateModal, setCreateModal } =
    useContext(ModalsContext);

  return (
    <>
      {createModal && (
        <div onClick={() => setCreateModal(false)}>
          <Modal
            show={createModal}
            onClick={(event) => event.stopPropagation()}
          >
            <Modal.Header closeButton onHide={() => setCreateModal(false)}>
              <Modal.Title>Adicionar veículo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <form>
                  <div className="modal-body">
                    <div className="row">
                      <div className="form-group ">
                        <label htmlFor="inputEmail4" className="ul-form__label">
                          Placa
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entre com a placa do veículo"
                        />
                      </div>
                      <div className="form-group ">
                        <label htmlFor="inputEmail4" className="ul-form__label">
                          Modelo
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entre com o modelo do veículo"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4" className="ul-form__label">
                          Motorista
                        </label>
                        <input
                          type="text"
                          className="form-control mb-2"
                          placeholder="Entre com o nome do(a) motorista"
                        />

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entre com a cnh do(a) motorista"
                        />
                      </div>
                    </div>

                    <div className="form-group ">
                      <label htmlFor="inputEmail4" className="ul-form__label">
                        Tag RFID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entre com a tag RIFD do veículo"
                      />
                    </div>

                    <div className="col-md-12 form-group mb-3">
                      <label className="ul-form__label" htmlFor="picker1">
                        Select
                      </label>
                      <select
                        id="picker1"
                        className="form-control"
                        name="select"
                      >
                        <option value="" selected hidden disabled>
                          Esolha sua rota
                        </option>
                        <option value="opt1">Option 1</option>
                        <option value="opt2">Option 2</option>
                        <option value="opt3">Option 3</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-info">Adicionar</Button>
              <Button
                variant="outline-danger"
                onClick={() => switchCreateModal()}
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default CreateModal;
