import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import ItaminasLogo from "./../../Assets/logo-itaminas-branco.png";
import { formatDate } from "utils/formatDate";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "http://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf",
      fontWeight: 100,
    }, // font-style: normal, font-weight: normal
    {
      src: "http://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf",
      fontWeight: 100,
      fontStyle: "italic",
    },
    {
      src: "http://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf",
      fontWeight: 300,
    },
    {
      src: "http://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf",
      fontWeight: 300,
      fontStyle: "italic",
    },
    {
      src: "http://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf",
      fontStyle: "italic",
    },
    {
      src: "http://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
    },
    {
      src: "http://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf",
      fontWeight: 700,
    },
    {
      src: "http://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf",
      fontWeight: 700,
      fontStyle: "italic",
    },
    {
      src: "http://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf",
      fontWeight: 900,
    },
    {
      src: "http://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf",
      fontWeight: 900,
      fontStyle: "italic",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  body: {
    // fontFamily: 'Roboto',
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  header: {
    marginBottom: "30px",
    fontSize: 11,
    fontWeight: 900,
    paddingBottom: "5px",
    borderBottom: "3px dotted gray",
  },

  headerImageContainer: {
    padding: "20px",
    width: "100%",
    backgroundColor: "#12274A",
    marginBottom: "10px",
  },

  headerImage: {
    width: "130px",
    height: "40px",
  },

  headerContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
  },
  headerDate: {
    marginTop: "2px",
    marginBottom: "3px",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  biggerTitle: {
    padding: "10px",
  },
});

// Create Document Component
const TemplateRelatorio = ({
  relatorioData,
  title,
  includeDates,
  children,
}) => {
  const datasRelatorio = () => {
    if (includeDates) {
      const inicio = formatDate(new Date(relatorioData.periodoInicial));
      const fim = formatDate(new Date(relatorioData.periodoFinal));
      const emissao = formatDate(new Date(relatorioData.dataExportação));

      return { inicio, fim, emissao };
    }
  };

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header} fixed>
          <View style={styles.headerImageContainer}>
            <Image src={ItaminasLogo} style={styles.headerImage} />
          </View>

          <View style={styles.headerContainer}>
            <Text style={!includeDates && styles.biggerTitle}>{title}</Text>

            {includeDates && (
              <View>
                <View>
                  <Text style={styles.headerDate}>
                    Emissão: {datasRelatorio().emissao}
                  </Text>
                  <Text style={styles.headerDate}>
                    Início: {datasRelatorio().inicio}
                  </Text>
                  <Text style={styles.headerDate}>
                    Fim: {datasRelatorio().fim}
                  </Text>
                  {relatorioData.pesoLiquidoTotalizado > 0 &&
                    relatorioData.quantidadeViagensTotalizado > 0 && (
                      <>
                        <Text style={styles.headerDate}>
                          Peso líquido total:{" "}
                          {new Intl.NumberFormat("pt-BR").format(
                            relatorioData.pesoLiquidoTotalizado / 1000
                          )}{" "}
                          t
                        </Text>
                        <Text style={styles.headerDate}>
                          Quantidade de viagens:{" "}
                          {relatorioData.quantidadeViagensTotalizado}
                        </Text>
                      </>
                    )}
                </View>
              </View>
            )}
          </View>
        </View>

        {children}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default TemplateRelatorio;
