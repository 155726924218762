import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const FilterContext = createContext();

export function FilterContextProvider({ children }) {
  let location = useLocation();

  //Estado que guarda um objeto com dados para filtragem
  const [filterParams, setFilterParams] = useState({});

  const apiUrl = "";

  const [dadosCardsPesagem, setDadosCardsPesagem] = useState([]);

  const [dadosPesagem, setDadosPesagem] = useState([]); //Estado que irá guardar os dados vindos da Api.

  const [balancas, setBalancas] = useState([]);

  const [loading, setLoading] = useState(false);

  const [tagRequest, setTagRequest] = useState(false);

  const [dadosBalanceiro, setDadosBalanceiro] = useState([]);

  // const [dadosCaminhoes, setDadosCaminhoes] = useState([]);

  const [newObj, setNewObj] = useState({});

  const [transportadorasCadastradas, setTransportadorasCadastradas] = useState(
    []
  );

  const [routes, setRoutes] = useState([
    {
      veiculosNaRota: [],
    },
  ]);

  const [carretas, setCarretas] = useState([]);

  const [dadosTararCaminhao, setDadosTararCaminhao] = useState([]);

  const selectData = (data) => {
    setNewObj(data);
  };

  const [dadosTaraManual, setDadosTaraManual] = useState({});

  const [pontosDeRota, setPontosDeRota] = useState([[], []]);

  const [urlUltimoRequest, setUrlUltimoRequest] = useState("");

  useEffect(() => {
    setDadosBalanceiro([]);
    setDadosPesagem([]);
    setFilterParams({});
    setCarretas([]);
    setUrlUltimoRequest("");
    setDadosTararCaminhao([]);
    setRoutes([
      {
        veiculoNaRota: [],
      },
    ]);
  }, [location]);

  //Função que seleciona os dados da seção da tabela que foi clicada, usado por mainTable

  return (
    <FilterContext.Provider
      value={{
        setBalancas,
        dadosPesagem,
        setDadosPesagem,
        newObj,
        setNewObj,
        selectData,
        filterParams,
        setFilterParams,
        balancas,
        loading,
        setLoading,
        tagRequest,
        setTagRequest,
        dadosBalanceiro,
        setDadosBalanceiro,
        transportadorasCadastradas,
        setTransportadorasCadastradas,
        carretas,
        setCarretas,
        apiUrl,
        dadosCardsPesagem,
        setDadosCardsPesagem,
        dadosTararCaminhao,
        setDadosTararCaminhao,
        dadosTaraManual,
        setDadosTaraManual,
        routes,
        setRoutes,
        pontosDeRota,
        setPontosDeRota,
        urlUltimoRequest,
        setUrlUltimoRequest,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}
