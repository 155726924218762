import React, { useContext } from "react";
import SimpleCard from "../../@gull/components/cards/SimpleCard";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { FilterContext } from "SGP-contexts/FilterContext";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import { ReactComponent as NFiconTrue } from "../../Assets/notaFiscalTrue.svg";
import axios from "axios";

import { GiWeight } from "react-icons/gi";
import { VscFileSymlinkFile } from "react-icons/vsc";
import {
  FaTrashAlt,
  FaRoute,
  FaFileInvoiceDollar,
  FaEdit,
} from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { formatDate } from "utils/formatDate";
import { RiRouteFill } from "react-icons/ri";
import { TiCancel } from "react-icons/ti";

const COR_STATUS = {
  Concluida: { backgroundColor: "#5F8D4E", color: "aliceblue " },
  Cancelada: { backgroundColor: "#E97777", color: "aliceblue " },
};

const TIPO_VEICULO = {
  0: 'Nenhum',
  1 : "Carreta",
  2 : "Frota Interna",
  3 : "Veículo Avulso",
}

const TabelaRegistros = ({ dados, selectItem, setDadosPesagem }) => {
  const renderTooltip = (props) => <Tooltip id="icon-tooltip">{props}</Tooltip>;
  const { urlUltimoRequest } = useContext(FilterContext);
  const {
    switchViewModal,
    setTaraModal,
    setNfModal,
    setIdentificarModal,
    setCorrigirIdentificacaoModal,
    setCancelarPesagemModal,
    setAdicionarRotaManualModal,
    setTrocarRotaModal,
    setRetirarNF,
  } = useContext(ModalsContext);

  const getRequest = async () => {
    const res = await axios.get(urlUltimoRequest);
    setDadosPesagem(res.data.retorno);
  };

  return (
    <div className="card mx-3 my-4 overflow-auto">
      <div>
        <SimpleCard title="Registros">
          <div className="table-responsive">
            <table
              style={{ minWidth: "1293px" }}
              id="tabelaDeRegistros"
              className="table table-striped text-center"
            >
              <thead>
                <tr>
                  <th scope="col">Data e hora</th>
                  <th scope="col">Balança</th>
                  <th scope="col">Plataforma</th>
                  <th scope="col">Sentido</th>
                  <th scope="col">Rota</th>
                  <th scope="col">Peso (kg)</th>
                  <th scope="col">Peso líquido (kg)</th>
                  <th scope="col">Tipo do veículo</th>
                  <th scope="col">Identificação</th>
                  <th scope="col">Imagens</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {dados &&
                  dados.map((data, index) => {
                    //Usa uma função para colocar separador de milhares no número
                    const pesoFormatado = new Intl.NumberFormat("de-DE").format(
                      data.peso
                    );

                    const pesoLiquidoFormatado = new Intl.NumberFormat(
                      "de-DE"
                    ).format(data.pesoLiquido);

                    const dataFormatada = formatDate(data.dataHoraUTCWeb);

                    const corStatus = COR_STATUS[data.statusDescricao];

                    return (
                      <OverlayTrigger
                        key={`${index} ${data.balanca}`}
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={ImgPopover(data.urlImagens)}
                      >
                        <tr
                          onClick={() => {
                            selectItem(data.tokenPesagem);
                            switchViewModal();
                          }}
                        >
                          <td
                            style={{ cursor: "pointer" }}
                            className="text-break"
                          >
                            {dataFormatada}
                          </td>

                          <td style={{ cursor: "pointer" }}>{data.balanca}</td>
                          <td style={{ cursor: "pointer" }}>
                            {data.plataforma}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {data.sentido ? data.sentido : "--"}
                          </td>

                          <td style={{ cursor: "pointer" }}>
                            {data.rota ? data.rota : "--"}
                          </td>

                          <td style={{ cursor: "pointer" }}>
                            {pesoFormatado}
                            {/*
                              Peso com o símbolo indicando se usou a regra de queda de peso
                            <div className="w-100 d-flex">
                              <div className="w-75 justify-self-start">
                                {pesoFormatado}
                              </div>
                              <div className="w-25 justify-self-end">
                                {data.usouRegraDeQuedaPeso ? (
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    trigger={["hover", "focus"]}
                                    overlay={renderTooltip(
                                      "Registro usou a regra de peso"
                                    )}
                                  >
                                    <span className="text-danger me-2">
                                      <BsCheckCircle size={20} />
                                    </span>
                                  </OverlayTrigger>
                                ) : null}
                              </div>
                            </div> */}
                          </td>

                          <td style={{ cursor: "pointer" }}>
                            {data.pesoLiquido ? pesoLiquidoFormatado : "--"}
                          </td>

                          <td style={{ cursor: "pointer" }}>
                            {TIPO_VEICULO[data.tipoVeiculo]}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {data.identificacaoVeiculo
                              ? data.identificacaoVeiculo
                              : "--"}
                          </td>

                          <td style={{ cursor: "pointer" }}>
                            {data.numeroImagens}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            <div className="d-flex justify-content-center">
                              <span
                                style={{
                                  ...corStatus,
                                  borderRadius: "20px",
                                  padding: "1px 3px",
                                }}
                                className=""
                              >
                                {data.statusDescricao || "--"}
                              </span>
                            </div>
                          </td>

                          <td>
                            <div className="w-100 d-flex justify-content-start h-100 align-items-center">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                trigger={["hover", "focus"]}
                                overlay={renderTooltip(
                                  "Abrir janela de visualização"
                                )}
                              >
                                <span className="cursor-pointer">
                                  <i className="nav-icon font-weight-bold mx-1 ">
                                    <AiFillEye
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        selectItem(data.tokenPesagem);
                                        switchViewModal();
                                      }}
                                      size={20}
                                    />
                                  </i>
                                </span>
                              </OverlayTrigger>

                              {(data.statusDescricao !== "Cancelada" && data.statusDescricao !== "Aguardando Cancelamento")&& (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  trigger={["hover", "focus"]}
                                  overlay={renderTooltip("Cancelar pesagem")}
                                >
                                  <span className="cursor-pointer">
                                    <i className="nav-icon font-weight-bold mx-1 text-danger">
                                      <FaTrashAlt
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setCancelarPesagemModal(true);
                                          selectItem(data.tokenPesagem);
                                        }}
                                        size={18}
                                      />
                                    </i>
                                  </span>
                                </OverlayTrigger>
                              )}

                              {!data.identificacaoVeiculo ? (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  trigger={["hover", "focus"]}
                                  overlay={renderTooltip("Identificar veículo")}
                                >
                                  <span className="cursor-pointer mx-1 text-info">
                                    <FaEdit
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        selectItem(data.tokenPesagem);
                                        setIdentificarModal(true);
                                      }}
                                      color="#1E2E4F" size={18}
                                    />
                                  </span>
                                </OverlayTrigger>
                              ) : null}

                              {data.numeroFrota && data.tipoVeiculo === 2 ? (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  trigger={["hover", "focus"]}
                                  overlay={renderTooltip("Corrigir identificação")}
                                >
                                  <span className="cursor-pointer mx-1 text-info">
                                    <FaEdit
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        selectItem(data.tokenPesagem);
                                        setCorrigirIdentificacaoModal(true);
                                      }}
                                      color="#7F8487" size={18}
                                    />
                                  </span>
                                </OverlayTrigger>
                              ) : null}

                              {data.permiteAcaoDeRequirirRotaManual ? (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  trigger={["hover", "focus"]}
                                  overlay={renderTooltip(
                                    "Adicionar rota manual"
                                  )}
                                >
                                  <span className="cursor-pointer mx-1 text-info">
                                    <FaRoute
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        selectItem(data.tokenPesagem);
                                        setAdicionarRotaManualModal(true);
                                      }}
                                      size={18}
                                    />
                                  </span>
                                </OverlayTrigger>
                              ) : null}

                              {data.chaveNFS ? (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  trigger={["hover", "focus"]}
                                  overlay={renderTooltip(
                                    "Registro possui nota fiscal de saída"
                                  )}
                                >
                                  <span className="mx-2">
                                    <i className="nav-icon font-weight-bold">
                                      <NFiconTrue
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    </i>
                                  </span>
                                </OverlayTrigger>
                              ) : null}
                              {data.chaveNFE ? (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  trigger={["hover", "focus"]}
                                  overlay={renderTooltip(
                                    "Registro possui nota fiscal de entrada"
                                  )}
                                >
                                  <span className="mx-2">
                                    <i className="nav-icon text-info font-weight-bold">
                                      <FaFileInvoiceDollar size={18} />
                                    </i>
                                  </span>
                                </OverlayTrigger>
                              ) : null}

                              {data.permiteAcaoDeIncluirNotaFiscal && (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  trigger={["hover", "focus"]}
                                  overlay={renderTooltip(
                                    "Adicionar documento fiscal"
                                  )}
                                >
                                  <span className="cursor-pointer">
                                    <i className="nav-icon font-weight-bold mx-1">
                                      <VscFileSymlinkFile
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          selectItem(data.tokenPesagem);
                                          setNfModal({
                                            estado: true,
                                            callback: async function () {
                                              await getRequest();
                                            },
                                          });
                                        }}
                                        style={{
                                          width: "18px",
                                          height: "18px",
                                        }}
                                      />
                                    </i>
                                  </span>
                                </OverlayTrigger>
                              )}
                              {data.statusDescricao?.toLowerCase() ===
                                "concluida" && (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  trigger={["hover", "focus"]}
                                  overlay={renderTooltip("Trocar rota")}
                                >
                                  <span className="cursor-pointer">
                                    <i className="nav-icon font-weight-bold mx-1">
                                      <RiRouteFill
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          selectItem(data.tokenPesagem);
                                          setTrocarRotaModal(true);
                                        }}
                                        style={{
                                          width: "18px",
                                          height: "18px",
                                        }}
                                      />
                                    </i>
                                  </span>
                                </OverlayTrigger>
                              )}

                              {data.permiteAcaoDeTarar && (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  trigger={["hover", "focus"]}
                                  overlay={renderTooltip("Tarar")}
                                >
                                  <span className="cursor-pointer">
                                    <i className="nav-icon font-weight-bold ">
                                      <GiWeight
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          selectItem(data.tokenPesagem);
                                          setTaraModal({
                                            estado: true,
                                            callback: async function () {
                                              await getRequest();
                                            },
                                          });
                                        }}
                                        size={18}
                                      />
                                    </i>
                                  </span>
                                </OverlayTrigger>
                              )}
                              {data.chaveNFE ? (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  trigger={["hover", "focus"]}
                                  overlay={renderTooltip(
                                    "Retirar nota fiscal da pesagem"
                                  )}
                                >
                                  <span className="cursor-pointer">
                                    <i className="nav-icon text-danger font-weight-bold">
                                      <TiCancel
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          selectItem(data.tokenPesagem);
                                          setRetirarNF(true);
                                        }}
                                        size={23}
                                      />
                                    </i>
                                  </span>
                                </OverlayTrigger>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      </OverlayTrigger>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </SimpleCard>
      </div>
    </div>
  );
};

const ImgPopover = (imagensArr) => {
  return (
    <Popover style={{ maxWidth: "700px" }}>
      <Popover.Body>
        <div className="row">
          {imagensArr !== null &&
            imagensArr.map((imagem, index) => (
              <div className="col-sm mw-25" key={index}>
                <img
                  src={imagem}
                  alt="Imagem do caminhão"
                  className="h-100 rounded"
                />
              </div>
            ))}
        </div>
      </Popover.Body>
    </Popover>
  );
};

export default TabelaRegistros;
