import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    fontSize: 10,
    fontFamily: "Helvetica",
  },
  titles: {
    flexDirection: "row",
    width: "100%",
  },
  titleDest: {
    alignItems: "flex-start",
    width: "50%",
    fontFamily: "Times-Bold",
    fontSize: 9,
  },
  fieldLabel: {
    fontSize: 6,
    textAlign: "left",
  },
  fieldValue: {
    paddingTop: 2,
    paddingLeft: 3,
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
    textAlign: "left",
  },
  formRow: {
    paddingTop: 5,
    flexDirection: "row",
  },
  formRowNoMT: {
    flexDirection: "row",
  },
  sectionDestinatario: {
    flexDirection: "row",
    maxHeight: 87,
    minHeight: 87,
    marginBottom: 5,
    border: "0.5px solid black",
  },
  sectionColumnDestinatario: {
    minWidth: "50%",
    maxWidth: "50%",
    flexDirection: "column",
    paddingLeft: 3,
  },
  sectionColumnEntrega: {
    minWidth: "50%",
    maxWidth: "50%",
    flexDirection: "column",
    borderLeft: "0.5px solid black",
    paddingLeft: 3,
  },
  sectionTitle: {
    textAlign: "left",
    paddingLeft: 3,
  },
  form: {
    minWidth: "25%",
    maxWidth: "25%",
  },
  formTwo: {
    minWidth: "64%",
    maxWidth: "64%",
  },
  formTwoEnd: {
    minWidth: "36%",
    maxWidth: "36%",
  },
  formThreeCenter: {
    minWidth: "67%",
    maxWidth: "67%",
  },
  formThreeEnd: {
    minWidth: "8%",
    maxWidth: "8%",
  },
  sectionTransportador: {
    flexDirection: "row",
    border: "0.5px solid black",
    maxHeight: 45,
    minHeight: 45,
    marginBottom: 5,
  },
  sectionColumnTransportador: {
    flexDirection: "column",
    minWidth: "100%",
    maxWidth: "100%",
    paddingLeft: 3,
  },
  transThree: {
    minWidth: "63%",
    maxWidth: "63%",
  },
  transThreeCenter: {
    minWidth: "19%",
    maxWidth: "19%",
  },
  transThreeEnd: {
    minWidth: "18%",
    maxWidth: "18%",
  },
  transFive: {
    minWidth: "28%",
    maxWidth: "28%",
  },
  transFiveTwo: {
    minWidth: "19%",
    maxWidth: "19%",
  },
  transFiveThree: {
    minWidth: "35%",
    maxWidth: "35%",
  },
  transFiveFour: {
    minWidth: "14%",
    maxWidth: "14%",
  },
  transFiveEnd: {
    minWidth: "4%",
    maxWidth: "4%",
  },
  sectionCarga: {
    flexDirection: "row",
    border: "0.5px solid black",
    maxHeight: 23,
    minHeight: 23,
    marginBottom: 5,
  },
  sectionColumnCarga: {
    flexDirection: "column",
    minWidth: "100%",
    maxWidth: "100%",
    paddingLeft: 3,
  },
  cargaFive: {
    minWidth: "59%",
    maxWidth: "59%",
  },
  cargaFiveTwo: {
    minWidth: "15%",
    maxWidth: "15%",
  },
  cargaFiveThree: {
    minWidth: "10%",
    maxWidth: "10%",
  },
  cargaFiveFour: {
    minWidth: "6%",
    maxWidth: "6%",
  },
  cargaFiveEnd: {
    minWidth: "10%",
    maxWidth: "10%",
  },

  sectionInfo: {
    flexDirection: "row",
    border: "0.5px solid black", 
    maxHeight: 45,
    minHeight: 45,
    marginBottom: 5,
  },
  sectionColumnInfo: {
    width: "100%",
    flexDirection: "row",
    paddingLeft: 3,
  },
  infoTexto: {
    width: "82%",
  },
  infoOperador: {
    width: "18%",
    borderLeft: "0.5px solid black",
    paddingLeft: 3,
    flexWrap: "nowrap",
    textOverflow: "ellipsis",
  },

  titleInfo: {
    marginTop: 5,
    flexDirection: "row",
    width: "100%",
  },

  titleInfoText: {
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "40%",
  },
  sectionTitleInfo: {
    fontFamily: "Times-Bold",
    textAlign: "left",
    paddingLeft: 3,
    fontSize: 9,
  },
  infoRow: {
    paddingTop: 2,
    flexDirection: "row",
    width: "100%",
  },
  infoAss: {
    width: "35%",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "0.8px solid black",
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 5,
  },
  ass: {
    fontSize: 7,
  },
  fieldValueOp: {
    paddingTop: 2,
    paddingLeft: 3,
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
    textAlign: "left",
    maxLines : 1,
  },
  fieldValueInfo: {
    paddingTop: 2,
    paddingLeft: 3,
    fontSize: 7,
    fontFamily: "Helvetica-Bold",
    textAlign: "left",
  },
});
const PdfTbeComum = (dadosRelatorio) => {
  const tbeData = {
    destinatarioRazaoSocial:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.cliente.nome,
    destinatarioCNPJ:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.cliente.cnpj,
    destinatarioInscEstadual:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.cliente.ie,
    destinatarioEndereco:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.cliente.endereco,
    destinatarioBairro: "--",
    destinatarioCEP: dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.cliente.cep,
    destinatarioMunicipio: "--",
    destinatarioUF: "--",
    localEntregaRazaoSocial:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.localEntrega.nome,
    localEntregaCNPJ:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.localEntrega.cnpj,
    localEntregaInscEstadual:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.localEntrega.ie,
    localEntregaEndereco:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.localEntrega.endereco,
    localEntregaBairro: "--",
    localEntregaCEP:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.localEntrega.cep,
    localEntregaMunicipio: "--",
    localEntregaUF: "--",
    transportadorRazaoSocial:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.transportadora.razaoSocial,
    transportadorCNPJ: dadosRelatorio.dadosRelatorio.agendamentoFluig.transportadora.cnpj,
    transportadorInscEstadual:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.transportadora.inscricaoEstadual,
    responsavelFrete:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.transportadora.respFrete,
    codigoANTT: "--",
    motorista: "--",
    placa: dadosRelatorio.dadosRelatorio.pesagem.placaCarreta,
    ufPlaca: dadosRelatorio.dadosRelatorio.agendamentoFluig.transportadora.uf,
    material: dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.itens[0].descricao,
    pedidoItem:
      dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.itens[0].itemCodigo,
    pesoBruto: dadosRelatorio.dadosRelatorio.pesagem.peso,
    tara: dadosRelatorio.dadosRelatorio.pesagem.tara,
    pesoLiquido: dadosRelatorio.dadosRelatorio.pesagem.pesoLiquido,
    infoComplementares: `--\n${dadosRelatorio.dadosRelatorio.agendamentoFluig.pedidoVenda.itens[0].itemCodigo}`,
    rotaTransporte: dadosRelatorio.dadosRelatorio.pesagem.rota,
  };

  return (
    <View style={styles.body}>
      <View style={styles.titles}>
        <View style={styles.titleDest}>
          <Text style={styles.sectionTitle}>DESTINATÁRIO</Text>
        </View>
        <View style={styles.titleDest}>
          <Text style={styles.sectionTitle}>LOCAL ENTREGA</Text>
        </View>
      </View>
      <View style={styles.sectionDestinatario}>
        <View style={styles.sectionColumnDestinatario}>
          <Text style={styles.fieldLabel}>Razão Social:</Text>
          <Text style={styles.fieldValue}>
            {tbeData.destinatarioRazaoSocial}
          </Text>
          <View style={styles.formRow}>
            <View style={styles.formTwo}>
              <Text style={styles.fieldLabel}>CNPJ:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.destinatarioCNPJ}
              </Text>
            </View>
            <View style={styles.formTwoEnd}>
              <Text style={styles.fieldLabel}>Inscrição Estadual:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.destinatarioInscEstadual}
              </Text>
            </View>
          </View>
          <View style={styles.formRow}>
            <View style={styles.formTwo}>
              <Text style={styles.fieldLabel}>Endereço:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.destinatarioEndereco}
              </Text>
            </View>
            <View style={styles.formTwoEnd}>
              <Text style={styles.fieldLabel}>Bairro:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.destinatarioBairro}
              </Text>
            </View>
          </View>
          <View style={styles.formRow}>
            <View style={styles.form}>
              <Text style={styles.fieldLabel}>CEP:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.destinatarioCEP}
              </Text>
            </View>
            <View style={styles.formThreeCenter}>
              <Text style={styles.fieldLabel}>Município:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.destinatarioMunicipio}
              </Text>
            </View>
            <View style={styles.formThreeEnd}>
              <Text style={styles.fieldLabel}>UF:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.destinatarioUF}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.sectionColumnEntrega}>
          <Text style={styles.fieldLabel}>Razão Social:</Text>
          <Text style={styles.fieldValue}>
            {tbeData.localEntregaRazaoSocial}
          </Text>
          <View style={styles.formRow}>
            <View style={styles.formTwo}>
              <Text style={styles.fieldLabel}>CNPJ:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.localEntregaCNPJ}
              </Text>
            </View>
            <View style={styles.formTwoEnd}>
              <Text style={styles.fieldLabel}>Inscrição Estadual:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.destinatarioInscEstadual}
              </Text>
            </View>
          </View>
          <View style={styles.formRow}>
            <View style={styles.formTwo}>
              <Text style={styles.fieldLabel}>Endereço:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.localEntregaEndereco}
              </Text>
            </View>
            <View style={styles.formTwoEnd}>
              <Text style={styles.fieldLabel}>Bairro:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.localEntregaBairro}
              </Text>
            </View>
          </View>
          <View style={styles.formRow}>
            <View style={styles.form}>
              <Text style={styles.fieldLabel}>CEP:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.localEntregaCEP}
              </Text>
            </View>
            <View style={styles.formThreeCenter}>
              <Text style={styles.fieldLabel}>Município:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.localEntregaMunicipio}
              </Text>
            </View>

            <View style={styles.formThreeEnd}>
              <Text style={styles.fieldLabel}>UF:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.localEntregaUF}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.titles}>
        <View style={styles.titleDest}>
          <Text style={styles.sectionTitle}>TRANSPORTADOR</Text>
        </View>
      </View>
      <View style={styles.sectionTransportador}>
        <View style={styles.sectionColumnTransportador}>
          <View style={styles.formRowNoMT}>
            <View style={styles.transThree}>
              <Text style={styles.fieldLabel}>Razão Social:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.transportadorRazaoSocial}
              </Text>
            </View>

            <View style={styles.transThreeCenter}>
              <Text style={styles.fieldLabel}>CNPJ:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.transportadorCNPJ}
              </Text>
            </View>

            <View style={styles.transThreeEnd}>
              <Text style={styles.fieldLabel}>Inscrição Estadual:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.transportadorInscEstadual}
              </Text>
            </View>
          </View>

          <View style={styles.formRow}>
            <View style={styles.transFive}>
              <Text style={styles.fieldLabel}>Responsável Frete:</Text>
              <Text style={styles.fieldValue}>
                {tbeData.responsavelFrete}
              </Text>
            </View>

            <View style={styles.transFiveTwo}>
              <Text style={styles.fieldLabel}>Código ANTT:</Text>
              <Text style={styles.fieldValue}>{tbeData.codigoANTT}</Text>
            </View>

            <View style={styles.transFiveThree}>
              <Text style={styles.fieldLabel}>Motorista:</Text>
              <Text style={styles.fieldValue}>{tbeData.motorista}</Text>
            </View>

            <View style={styles.transFiveFour}>
              <Text style={styles.fieldLabel}>Placa:</Text>
              <Text style={styles.fieldValue}>{tbeData.placa}</Text>
            </View>

            <View style={styles.transFiveEnd}>
              <Text style={styles.fieldLabel}>UF:</Text>
              <Text style={styles.fieldValue}>{tbeData.ufPlaca}</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.titles}>
        <View style={styles.titleDest}>
          <Text style={styles.sectionTitle}>CARGA</Text>
        </View>
      </View>
      <View style={styles.sectionCarga}>
        <View style={styles.sectionColumnCarga}>
          <View style={styles.formRowNoMT}>
            <View style={styles.cargaFive}>
              <Text style={styles.fieldLabel}>Material: </Text>
              <Text style={styles.fieldValue}>{tbeData.material}</Text>
            </View>

            <View style={styles.cargaFiveTwo}>
              <Text style={styles.fieldLabel}>Pedido/Item :</Text>
              <Text style={styles.fieldValue}>{tbeData.pedidoItem}</Text>
            </View>
            <View style={styles.cargaFiveThree}>
              <Text style={styles.fieldLabel}>Peso Bruto (t) :</Text>
              <Text style={styles.fieldValue}>{tbeData.pesoBruto}</Text>
            </View>

            <View style={styles.cargaFiveFour}>
              <Text style={styles.fieldLabel}>Tara (t) :</Text>
              <Text style={styles.fieldValue}>{tbeData.tara}</Text>
            </View>

            <View style={styles.cargaFiveEnd}>
              <Text style={styles.fieldLabel}>Peso Líquido (t) :</Text>
              <Text style={styles.fieldValue}>
                {tbeData.pesoLiquido}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.titleInfo}>
        <View style={styles.infoRow}>
          <View style={styles.titleInfoText}>
            <Text style={styles.sectionTitleInfo}>INFORMAÇÕES ADICIONAIS</Text>
          </View>

          <View style={styles.infoAss}>
            <Text style={styles.ass}>Assinatura do Balanceiro (Operador)</Text>
          </View>
          <View style={styles.infoAss}>
            <Text style={styles.ass}>Assinatura do Motorista</Text>
          </View>
        </View>
      </View>
      <View style={styles.sectionInfo}>
        <View style={styles.sectionColumnInfo}>
          <View style={styles.infoTexto}>
            <Text style={styles.fieldLabel}>Informações Complementares: </Text>
            <Text style={styles.fieldValueInfo}>
              {tbeData.infoComplementares}
            </Text>
          </View>

          <View style={styles.infoOperador}>
            <Text style={styles.fieldLabel}>Operador:</Text>
            <Text style={styles.fieldValueOp}>{tbeData.operador}</Text>
            <Text style={styles.fieldLabel}>Rota de Transporte:</Text>
            <Text style={styles.fieldValueOp}>
              {tbeData.rotaTransporte}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PdfTbeComum;
