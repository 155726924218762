import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const Page404 = () => {
  let navigate = useNavigate();

  return (
    <div className="d-flex justify-content-center my-4">
      <div className="w-50 h-50">
        <div className="col-md-auto">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <div className="card-title">
                  <p>Página não encontrada. Erro 404!</p>
                </div>
                <Button
                  variant="outline-info"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Voltar para a tela inicial
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
