import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import { formatDate } from "utils/formatDate";
import PDFTicketLavagem from "./../Common/PDFTicketLavagem";
import { cnpjMask } from "Schemas/Masks";

const ViewModal = ({ dados }) => {
  const { switchViewModal, viewModal, setShowImage, setImage, setViewModal } =
    useContext(ModalsContext);

  const apiUrl = process.env.REACT_APP_APIURL;

  const dataFormatada = formatDate(dados?.dataHoraUTC+'Z');
  const relatorioData = {
    id: dados?.id,
    Data: dataFormatada,
    Placa: dados?.placa,
    Caminhão: dados?.numeroFrota,
    imagemURL: dados?.imagemURL,
    "Tipo do Veículo": dados?.tipoVeiculo == 1 ? "Interno" : "Externo",
    Rota: dados?.rotaNome
  };


  const handleClick = (event) => {
    event.preventDefault();
  };
  return (
    <>
      {viewModal && (
        <div>
          <Modal
            size="lg"
            show={viewModal}
            scrollable={true}
            onClick={(event) => event.stopPropagation()}
          >
            <Modal.Header
              closeButton
              onHide={() => {
                setViewModal(false);
              }}
            >
              <Modal.Title>Visualização do caminhão</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <form>
                  <div>
                    <div className="row">
                      <div className="col">
                        <label htmlFor="idPesagem">ID do registro</label>
                        <input
                          type="text"
                          className="form-control"
                          name="idPesagem"
                          value={dados.id}
                          disabled
                          id="idPesagem"
                        />
                      </div>
                    </div>

                    <br />
                    <div className="row">
                      <div className="col-md-6 form-group ">
                        <label htmlFor="Data">Data</label>
                        <input
                          type="text"
                          className="form-control"
                          name="data"
                          value={dataFormatada}
                          disabled
                          id="Data"
                        />
                      </div>

                      {dados.numeroFrota && (
                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="numeroFrota">Identificacao</label>
                          <input
                            type="text"
                            className="form-control"
                            name="numeroFrota"
                            value={dados.numeroFrota}
                            id="numeroFrota"
                            disabled
                          />
                        </div>
                      )}
                      {dados.rotaNome && (
                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="numeroFrota">Rota</label>
                          <input
                            type="text"
                            className="form-control"
                            name="numeroFrota"
                            value={dados.rotaNome}
                            id="numeroFrota"
                            disabled
                          />
                        </div>
                      )}

                      {dados.placa && (
                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="placaCarreta">Placa</label>
                          <input
                            type="text"
                            className="form-control"
                            name="placaCarreta"
                            value={dados.placa}
                            id="placaCarreta"
                            disabled
                          />
                        </div>
                      )}
                      {dados.tipoVeiculo && (
                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="placaCarreta">Tipo</label>
                          <input
                            type="text"
                            className="form-control"
                            name="placaCarreta"
                            value={
                              dados.tipoVeiculo == 1 ? "Interno" : "Externo"
                            }
                            id="placaCarreta"
                            disabled
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </form>
                <section>
                  <br />
                  <Modal.Title>Fotos</Modal.Title>
                  <div className="custom-separator"></div>
                  <div className="row text-center ">
                    {dados.imagemURL !== null && (
                      <div>
                        <div className="col">
                          <img
                            src={dados.imagemURL}
                            alt="Imagem do caminhão"
                            className="w-75 m-2 rounded cursor-pointer"
                            onClick={() => {
                              setShowImage(true);
                              setImage(dados.imagemURL);
                            }}
                          />
                        </div>
                        <div className="w-100"></div>
                      </div>
                    )}
                  </div>
                </section>
              </>
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center">
              <Button
                variant="outline-danger"
                onClick={() => {
                  switchViewModal();
                }}
              >
                Voltar
              </Button>
              <PDFDownloadLink
                document={<PDFTicketLavagem relatorioData={relatorioData} />}
                fileName="Ticket da Lavagem.pdf"
              >
                {({ loading }) => (
                  <div
                    onClick={loading ? handleClick : null}
                    style={{
                      cursor: loading ? "auto" : "pointer",
                    }}
                  >
                    <Button
                      variant="info"
                      className="mb-4 my-auto"
                      disabled={loading}
                    >
                      Baixar PDF
                    </Button>
                  </div>
                )}
              </PDFDownloadLink>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ViewModal;
