import React, { useEffect } from "react";
import { loginRequest } from "../../authentication/authConfig.js";
import { useMsal } from "@azure/msal-react";

const TelaLogin = () => {
  const { instance } = useMsal();

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest.scopes).catch((e) => {
      console.error(e);
    });
  }

  useEffect(() => {
    handleLogin(instance);
    // eslint-disable-next-line
  }, []);
  return <></>;
};

export default TelaLogin;
