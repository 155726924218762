import * as Yup from "yup";

const numberOnlyRegex = /^[0-9]*$/;

const placaRegex = /[A-Z]{3}-[0-9][0-9A-Z][0-9]{2}/;

export const NotaFiscalDeSaidaSchema = Yup.object().shape({
  placaCarreta: Yup.string()
    .matches(placaRegex, "O formato da placa é inválido")
    .required("Campo obrigatório"),
});

export const NotaFiscalDeEntradaSchema = Yup.object().shape({
  chaveNFEntrada: Yup.string()
    .matches(
      numberOnlyRegex,
      "A chave de nota fiscal deve conter somente valores numéricos"
    )
    .min(
      44,
      "A quantidade de caracteres é inválida, a chave deve ser constituída de 44 números"
    )
    .max(
      44,
      "A quantidade de caracteres é inválida, a chave deve ser constituída de 44 números"
    )
    .required("A chave da nota fiscal de entrada é obrigatória"),

  placaCarreta: Yup.string()
    .matches(placaRegex, "O formato da placa é inválido")
    .required("Campo obrigatório"),
});
