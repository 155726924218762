import { useEffect, useRef } from "react";

export const useDidMount = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  return isMounted.current;
};
