import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const Redirect = () => {
  let navigate = useNavigate();

  // eslint-disable-next-line
  useEffect(() => navigate("/"), []);

  return <></>;
};

export default Redirect;
