import React from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import { formatDate } from "utils/formatDate";

const PesagemCardsBalancas = ({ dadosCardsPesagem }) => {
  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={30}
      centerInsufficientSlides={true}
      pagination={{ clickable: true }}
    >
      <div className="row">
        <div className="d-flex">
          {dadosCardsPesagem &&
            dadosCardsPesagem.map((card, index) => {
              const intervalo1 = {
                inicio: formatDate(card.inicioIntervalo1),
                fim: formatDate(card.fimIntervalo1),
              };

              const intervalo2 = {
                inicio: formatDate(card.inicioIntervalo2),
                fim: formatDate(card.fimIntervalo2),
              };

              return (
                <SwiperSlide key={index}>
                  <div className="col-md-auto">
                    <div className="card my-4 mx-2" style={{ width: "100%" }}>
                      <div className="card-body">
                        <div className="text-center">
                          <h5 className="card-title text-info">
                            {card.balanca}
                          </h5>
                          <p className="text-muted mt-2 mb-0 text-capitalize">
                            {`Intervalo atual: ${intervalo1.inicio} - ${intervalo1.fim}`}
                          </p>
                          <p className="text-muted mt-2 mb-2 text-capitalize">
                            {`Intervalo anterior: ${intervalo2.inicio} - ${intervalo2.fim}`}
                          </p>
                          <table id="tabelaComparacaoDeTempo" className="table">
                            <thead>
                              <tr>
                                <th scope="col">Plataforma</th>
                                <th scope="col">Intervalo atual</th>
                                <th scope="col">Intervalo anterior</th>
                                <th scope="col">Diferença em porcentagem</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{card.plataforma}</td>
                                <td>{card.pesagensIntervaloAtual}</td>
                                <td>{card.pesagensIntervaloAnterior}</td>
                                <td>
                                  {`${card.pesagensDiferencaPorcentagem}% `}
                                  {card.pesagensDiferencaPorcentagem < 0 ? (
                                    <AiOutlineArrowDown />
                                  ) : (
                                    <AiOutlineArrowUp />
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </div>
      </div>
    </Swiper>
  );
};

export default React.memo(PesagemCardsBalancas);
