import React from "react";
import { View, Text, StyleSheet, Image } from "@react-pdf/renderer";
import TemplateRelatorio from "./TemplateRelatorio";

const styles = StyleSheet.create({
  tokenPesagem: {
    padding: "0 12px 5px 12px",
    fontSize: "10px",
  },

  ticketArea: {
    fontSize: "9px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },

  group: {
    display: "flex",
    flexGrow: "1",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "5px 12px",
    width: "45%",
  },

  field: {
    width: "100%",
    height: "12px",
    marginBottom: "5px",
  },
  value: {
    backgroundColor: "#EEEEEE",
    padding: "5px 5px 3px 10px",
    width: "100%",
    height: "22px",
    borderRadius: "5px",
    border: "1px solid #CED4DA",
  },

  fotos: {
    fontSize: "12px",
    marginTop: "30px",
    padding: "0 12px",
  },

  imagesContainer: {
    width: "100%",
    display: "flex",
    flexGrow: "1",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: "15px",
  },

  image: {
    width: "30%",
    borderRadius: "5px",
  },
});

const PDFTicketPesagem = ({ relatorioData }) => {
  return (
    <TemplateRelatorio
      relatorioData={[]}
      title="TICKET DA LAVAGEM"
      includeDates={false}
    >
      <View style={styles.tokenPesagem}>
        <Text style={styles.field}>Token da Lavagem</Text>
        <Text style={styles.value}>{relatorioData?.id}</Text>
      </View>

      <View style={styles.ticketArea}>
        {Object.entries(relatorioData).map((item) => {
          //Não coloca os campos de imagem no corpo comum do pdf, eles são colocados em lugares especiais
          if (item[0] === "imagemURL") return null;
          if (item[0] === "id") return null;
          if (!item[1]) return null;
          return (
            <View style={styles.group} key={item[0]}>
              <Text style={styles.field}>{item[0]}</Text>
              <Text style={styles.value}>{item[1] || "--"}</Text>
            </View>
          );
        })}
      </View>

      <View>
        <Text style={styles.fotos}>Fotos</Text>

        <View style={styles.imagesContainer}>
          <Image src={`${relatorioData.imagemURL}`} style={{ ...styles.image, width: '75%' }} />
        </View>
      </View>
    </TemplateRelatorio>
  );
};

export default PDFTicketPesagem;
