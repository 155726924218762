import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import { formatDate } from "utils/formatDate";
import PDFTicketPesagem from "./PDFTicketPesagem";
import PDFTicketTbe from "../PDF TBE/PDF/pdfTbe";
import { cnpjMask } from "Schemas/Masks";
import { da } from "date-fns/locale";
import { set } from "lodash";

export const ViewModal = ({ dadosPesagem, tokenPesagemSelecionada }) => {
  const { switchViewModal, viewModal, setShowImage, setImage, setViewModal, setFalhaCadastro } =
    useContext(ModalsContext);

  const [dadosAdicionaisNF, setDadosadicionaisNF] = useState({});
  const [loadingDadosNf, setLoadingDadosNf] = useState(false);
  const [loadingDadosTBe, setLoadingDadosTBe] = useState(false);
  const [dadosTBe, setDadosTBe] = useState(null);
  const [loadingDadosAdcTbe, setLoadingDadosAdcTbe] = useState(false);

  const [dadosPedido, setDadosPedido] = useState({
    status: false,
    data: {},
  });

  const [dadosAdcTbe, setDadosAdcTbe] = useState({
    status: false,
    data: {}
  });

  const [caminhao] = useMemo(
    () =>
      dadosPesagem.filter(
        (item) => item.tokenPesagem === tokenPesagemSelecionada
      ),
    [dadosPesagem, tokenPesagemSelecionada]
  );

  const apiUrl = process.env.REACT_APP_APIURL;

  const getDadosNFE = () => {
    setLoadingDadosNf(true);
    axios
      .get(
        `${apiUrl}/apiwebapp/Pesagem/BuscarDadosAdicionaisNotaEntrada?ChaveNFE=${caminhao?.chaveNFE}`
      )
      .then((res) => setDadosadicionaisNF(res.data.retorno))
      .catch((error) => console.log(error))
      .finally(() => setLoadingDadosNf(false));
  };

  const getDadosNFS = () => {
    setLoadingDadosNf(true);
    axios
      .get(
        `${apiUrl}/apiwebapp/Pesagem/BuscarDadosAdicionaisNotaSaida?TokenPesagem=${caminhao?.tokenPesagem}`
      )
      .then((res) => setDadosadicionaisNF(res.data.retorno))
      .catch((error) => console.log(error))
      .finally(() => setLoadingDadosNf(false));
  };
  const getDadosTBe = (tokenPesagem) => {
    setLoadingDadosTBe(true);
    setLoadingDadosAdcTbe(true);
    axios
      .get(`${apiUrl}/api/TBe/BuscaTbePorToken?TokenPesagem=${tokenPesagem}`)
      .then((res) => {
        setDadosTBe(res.data);
        const md5 = res.data.md5;
        axios.get(`${apiUrl}/api/TBe/BuscaTbePorMD5?md5=${md5}`)
          .then((res) => {
            setDadosAdcTbe({
              status: res.data.sucesso,
              data: res.data.retorno
            });
            setLoadingDadosAdcTbe(false);
            setLoadingDadosNf(false);
            setLoadingDadosTBe(false);
          })
          .catch((error) => {
            setLoadingDadosAdcTbe(false);
            setLoadingDadosNf(false);
            setLoadingDadosTBe(false);
            console.log(error);
            setDadosAdcTbe({
              status: true,
              data: {
                CnpjCliente: '',
                RazaoSocialCliente: '',
                RazaoSocialTransportadora: '',
                CodigoProduto: '',
                ProtocoloCarregamento: '',
                NomeProduto: '',
                CnpjTransportadora: '',
                ChaveTbe: '',
              }
            });
          })
          .finally(() => setLoadingDadosAdcTbe(false));
      })
      .catch((error) => {
        console.log(error);
        setLoadingDadosAdcTbe(false);
        setLoadingDadosNf(false);
        setLoadingDadosTBe(false);
      })
      .finally(() => setLoadingDadosTBe(false));
  };

  const clearPed = () => {
    setDadosPedido({ status: false, data: {} });
    setDadosTBe({});
    setDadosAdcTbe({ status: false, data: {} });
    setLoadingDadosAdcTbe(false);
  };

  const getDadosPedido = (tokenPesagem) => {
    setLoadingDadosNf(true);
    axios
      .get(
        `${apiUrl}/apiwebapp/PesagemAvulsa/BuscaPedidoPesagemAvulsa?TokenPesagem=${tokenPesagem}`
      )
      .then((res) => {
        if (res.data.retorno === null) {
          setDadosPedido({
            status: true,
            data: {
              id: 0,
              tokenPesagem: "",
              numero: "",
              dataEmissao: "",
              fornecedor: "",
              produto: "",
              descProduto: "",
              nomeForncedor: "",
            },
          });
        } else {
          setDadosPedido({
            status: true,
            data: res.data.retorno,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setDadosPedido({
          status: false,
          data: {},
        });
      })
      .finally(() => {
        setLoadingDadosNf(false);
      });
    setLoadingDadosNf(false);
  };

  useEffect(() => {

    if (viewModal) {
      if (caminhao?.chaveNFE) {
        getDadosNFE();
      } else if (caminhao?.chaveNFS) {
        getDadosNFS();
      } else if (
        caminhao?.statusAtual === 100 ||
        caminhao?.statusAtual === 101
      ) {
        getDadosPedido(caminhao?.tokenPesagem);
      }
      if (caminhao?.statusAtual === 34) {
        getDadosTBe(caminhao?.tokenPesagem);
      }
    }
    // eslint-disable-next-line
  }, [caminhao, viewModal]);

  const pesoFormatado = new Intl.NumberFormat("pt-BR").format(caminhao?.peso);
  const pesoLiquidoFormatado = new Intl.NumberFormat("pt-BR").format(
    caminhao?.pesoLiquido
  );
  const dataFormatada = formatDate(
    caminhao?.dataHoraUTCWeb || caminhao?.dataHoraUTC
  );

  const relatorioData = {
    tokenPesagem: caminhao?.tokenPesagem,
    Data: dataFormatada,
    Balança: caminhao?.balanca,
    Plataforma: caminhao?.plataforma,
    "Peso (kg)": pesoFormatado,
    "Peso líquido (kg)": pesoLiquidoFormatado || "--",
    Carreta: caminhao?.placaCarreta,
    Caminhão: caminhao?.numeroFrota,
    Sentido: caminhao?.sentido,
    imagens: caminhao?.urlImagens,
    descricaoCancelamento:
      caminhao?.statusDescricao.toLowerCase() === "cancelada"
        ? caminhao?.statusDetalhes || "Sem detalhes do cancelamento"
        : "",
    "Tipo de nota fiscal": caminhao?.tipoNotaFiscal,
    "Chave da nota fiscal de entrada": caminhao?.chaveNFE,
    "Chave da nota fiscal de saída": caminhao?.chaveNFS,
  };

  const relatorioComDadosNFE = {
    ...relatorioData,
    "Cnpj do fornecedor": dadosAdicionaisNF?.cnpjFornecedor
      ? cnpjMask(dadosAdicionaisNF.cnpjFornecedor)
      : null,
    "Razao social do fornecedor": dadosAdicionaisNF?.razaoSocialFornecedor,
    "Cnpj da transportadora": dadosAdicionaisNF?.cnpjTransportadora
      ? cnpjMask(dadosAdicionaisNF.cnpjTransportadora)
      : null,
    "Razao social transportadora": dadosAdicionaisNF?.razaoSocialTransportadora,
    "Data e hora da integracao": dadosAdicionaisNF?.dataHoraIntegracao,
    "Codigo do produto": dadosAdicionaisNF?.codigoProduto,
    "Nome do produto": dadosAdicionaisNF.nomeProduto,
  };

  const relatorioComDadosNFS = {
    ...relatorioData,
    "Cnpj do cliente": dadosAdicionaisNF?.cnpjCliente
      ? cnpjMask(dadosAdicionaisNF.cnpjCliente)
      : null,
    "Razao social do cliente": dadosAdicionaisNF?.razaoSocialCliente,
    "Cnpj da transportadora": dadosAdicionaisNF?.cnpjTransportadora
      ? cnpjMask(dadosAdicionaisNF.cnpjTransportadora)
      : null,
    "Razao social transportadora": dadosAdicionaisNF?.razaoSocialTransportadora,
    "Codigo do produto": dadosAdicionaisNF?.codigoProduto,
    "Nome do produto": dadosAdicionaisNF?.nomeProduto,
    "Numero da nota fiscal": dadosAdicionaisNF?.notaNumero,
    "Série da nota fiscal": dadosAdicionaisNF?.notaSerie,
    "Protocolo de carregamento": dadosAdicionaisNF?.protocoloCarregamento,
  };

  const relatorioComPedido = {
    "Número do pedido": dadosPedido?.data.numero,
    "Data de emissão do pedido": dadosPedido?.data.dataEmissao,
    "Código do fornecedor": dadosPedido?.data.fornecedor,
    "Código do produto": dadosPedido?.data.produto,
    "Descrição do produto": dadosPedido?.data.descProduto,
    "Nome do fornecedor": dadosPedido?.data.nomeFornecedor,
  };

  const getPdfTbe = () => {
    axios.get(`${apiUrl}/api/TBe/BuscaPDFTbePorToken?TokenPesagem=${caminhao?.tokenPesagem}`).then((res) => {
      if (res.data.sucesso === true) {
        const link = document.createElement('a');
        link.href = res.data.retorno;
        link.setAttribute('download', 'Ticket do TB-e.pdf');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      else {
        setFalhaCadastro({
          estado: true,
          mensagemDeErro: "Erro ao baixar PDF: O arquivo especificado não foi encontrado.",
        });
      }
    }).catch((error) => {
      setFalhaCadastro({
        estado: true,
        mensagemDeErro: "Erro ao baixar PDF: O arquivo especificado não existe.",
      });
      console.log(error);
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
  };
  return (
    <>
      {viewModal && (
        <div
          onClick={() => {
            setDadosadicionaisNF({});
            setViewModal(false);
            clearPed();
          }}
        >
          <Modal
            size="lg"
            show={viewModal}
            scrollable={true}
            onClick={(event) => event.stopPropagation()}
          >
            <Modal.Header
              closeButton
              onHide={() => {
                setDadosadicionaisNF({});
                setViewModal(false);
                clearPed();
              }}
            >
              <Modal.Title>Visualização do caminhão</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {!loadingDadosNf ? (
                <>
                  <form>
                    <div>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="idPesagem">ID da pesagem</label>
                          <input
                            type="text"
                            className="form-control"
                            name="idPesagem"
                            value={caminhao.tokenPesagem}
                            disabled
                            id="idPesagem"
                          />
                        </div>
                      </div>
                      {
                        //Se o status da pesagem é cancelada, mostra o motivo do cancelamento
                        caminhao.statusDescricao.toLowerCase() ===
                        "cancelada" && (
                          <>
                            <br />
                            <div className="row">
                              <div className="col">
                                <label htmlFor="statusDetalhes">
                                  Descrição do cancelamento
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="statusDetalhes"
                                  value={
                                    caminhao.statusDetalhes ||
                                    "Sem detalhes do cancelamento"
                                  }
                                  disabled
                                  id="statusDetalhes"
                                />
                              </div>
                            </div>
                          </>
                        )
                      }
                      <br />
                      <div className="row">
                        <div className="col-md-6 form-group ">
                          <label htmlFor="Data">Data</label>
                          <input
                            type="text"
                            className="form-control"
                            name="data"
                            value={dataFormatada}
                            disabled
                            id="Data"
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="Plataforma">Balança</label>
                          <input
                            type="text"
                            className="form-control"
                            name="balanca"
                            value={caminhao.balanca}
                            disabled
                            id="Balanca"
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="Plataforma">Plataforma</label>
                          <input
                            type="text"
                            className="form-control"
                            name="plataforma"
                            value={caminhao.plataforma}
                            disabled
                            id="Plataforma"
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="Peso">Peso (kg)</label>
                          <input
                            type="text"
                            className="form-control"
                            name="peso"
                            value={pesoFormatado}
                            disabled
                            id="Peso"
                          />
                        </div>

                        {caminhao?.pesoLiquido !== undefined &&
                          caminhao?.pesoLiquido !== null && (
                            <div className="col-md-6 form-group mb-3">
                              <label htmlFor="Peso">Peso líquido (kg)</label>
                              <input
                                type="text"
                                className="form-control"
                                name="pesoAtual"
                                value={pesoLiquidoFormatado}
                                disabled
                                id="PesoAtual"
                              />
                            </div>
                          )}
                        {caminhao.numeroFrota !== undefined &&
                          caminhao.numeroFrota !== null && (
                            <div className="col-md-6 form-group mb-3">
                              <label htmlFor="numeroFrota">Caminhão</label>
                              <input
                                type="text"
                                className="form-control"
                                name="numeroFrota"
                                value={caminhao.numeroFrota}
                                id="numeroFrota"
                                disabled
                              />
                            </div>
                          )}

                        {caminhao.placaCarreta !== undefined &&
                          caminhao.placaCarreta !== null && (
                            <div className="col-md-6 form-group mb-3">
                              <label htmlFor="placaCarreta">
                                {caminhao.tipoVeiculo === 1
                                  ? "Carreta"
                                  : caminhao.tipoVeiculo === 3
                                    ? "Veículo Avulso"
                                    : null}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="placaCarreta"
                                value={caminhao.placaCarreta}
                                id="placaCarreta"
                                disabled
                              />
                            </div>
                          )}

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="sentido">Sentido</label>
                          <input
                            type="text"
                            className="form-control"
                            name="sentido"
                            value={caminhao.sentido ? caminhao.sentido : "--"}
                            id="sentido"
                            disabled
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="RFID">RFID</label>
                          <input
                            type="text"
                            className="form-control"
                            name="RFID"
                            value={
                              caminhao.tagRFID
                                ? caminhao.tagRFID
                                : "Tag não identificada"
                            }
                            disabled
                            id="RFID"
                          />
                        </div>
                        {caminhao.temNotaFiscal ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="Nota fiscal">
                              Tipo da nota fiscal
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="notaFiscal"
                              value={caminhao.tipoNotaFiscal}
                              disabled
                              id="Nota fiscal"
                            />
                          </div>
                        ) : null}

                        {caminhao.chaveNFE ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="Nota fiscal">
                              Chave da nota fiscal de entrada
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="notaFiscal"
                              value={caminhao.chaveNFE}
                              disabled
                              id="Nota fiscal"
                            />
                          </div>
                        ) : null}

                        {caminhao.chaveNFS ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="Nota fiscal">
                              Chave da nota fiscal de saída
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="notaFiscal"
                              value={caminhao.chaveNFS}
                              disabled
                              id="Nota fiscal"
                            />
                          </div>
                        ) : null}

                        {dadosAdicionaisNF.cnpjFornecedor ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="cnpjFornecedor">
                              CNPJ do fornecedor
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="cnpjFornecedor"
                              value={
                                dadosAdicionaisNF.cnpjFornecedor &&
                                cnpjMask(dadosAdicionaisNF.cnpjFornecedor)
                              }
                              disabled
                              id="cnpjFornecedor"
                            />
                          </div>
                        ) : null}
                        {dadosAdicionaisNF.cnpjCliente ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="cnpjCliente">CNPJ do cliente</label>
                            <input
                              type="text"
                              className="form-control"
                              name="cnpjCliente"
                              value={
                                dadosAdicionaisNF.cnpjCliente &&
                                cnpjMask(dadosAdicionaisNF.cnpjCliente)
                              }
                              disabled
                              id="cnpjCliente"
                            />
                          </div>
                        ) : null}
                        {dadosAdicionaisNF.razaoSocialFornecedor ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="razaoSocialFornecedor">
                              Razão social do fornecedor
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="razaoSocialFornecedor"
                              value={dadosAdicionaisNF.razaoSocialFornecedor}
                              disabled
                              id="razaoSocialFornecedor"
                            />
                          </div>
                        ) : null}
                        {dadosAdicionaisNF.razaoSocialCliente ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="razaoSocialCliente">
                              Razão social do cliente
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="razaoSocialCliente"
                              value={dadosAdicionaisNF.razaoSocialCliente}
                              disabled
                              id="razaoSocialCliente"
                            />
                          </div>
                        ) : null}
                        {dadosAdicionaisNF.cnpjTransportadora ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="cnpjTransportadora">
                              CNPJ da transportadora
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="cnpjTransportadora"
                              value={
                                dadosAdicionaisNF.cnpjTransportadora &&
                                cnpjMask(dadosAdicionaisNF.cnpjTransportadora)
                              }
                              disabled
                              id="cnpjTransportadora"
                            />
                          </div>
                        ) : null}
                        {dadosAdicionaisNF.razaoSocialTransportadora ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="razaoSocialTransportadora">
                              Razão social da transportadora
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="razaoSocialTransportadora"
                              value={
                                dadosAdicionaisNF.razaoSocialTransportadora
                              }
                              disabled
                              id="razaoSocialTransportadora"
                            />
                          </div>
                        ) : null}
                        {dadosAdicionaisNF.dataHoraIntegracao ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="dataHoraIntegracao">
                              Data e hora da integração
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="dataHoraIntegracao"
                              value={dadosAdicionaisNF.dataHoraIntegracao}
                              disabled
                              id="dataHoraIntegracao"
                            />
                          </div>
                        ) : null}
                        {dadosAdicionaisNF.codigoProduto ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="codigoProduto">
                              Código do produto
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="codigoProduto"
                              value={dadosAdicionaisNF.codigoProduto}
                              disabled
                              id="codigoProduto"
                            />
                          </div>
                        ) : null}
                        {dadosAdicionaisNF.nomeProduto ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="nomeProduto">Nome do produto</label>
                            <input
                              type="text"
                              className="form-control"
                              name="nomeProduto"
                              value={dadosAdicionaisNF.nomeProduto}
                              disabled
                              id="nomeProduto"
                            />
                          </div>
                        ) : null}
                        {dadosAdicionaisNF.notaNumero ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="notaNumero">
                              Número da nota fiscal
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="notaNumero"
                              value={dadosAdicionaisNF.notaNumero}
                              disabled
                              id="notaNumero"
                            />
                          </div>
                        ) : null}
                        {dadosAdicionaisNF.notaSerie ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="notaSerie">
                              Série da nota fiscal
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="notaSerie"
                              value={dadosAdicionaisNF.notaSerie}
                              disabled
                              id="notaSerie"
                            />
                          </div>
                        ) : null}
                        {dadosAdicionaisNF.protocoloCarregamento ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="protocoloCarregamento">
                              Protocolo do carregamento
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="protocoloCarregamento"
                              value={dadosAdicionaisNF.protocoloCarregamento}
                              disabled
                              id="protocoloCarregamento"
                            />
                          </div>
                        ) : null}

                        {/* SEÇÃO QUE MOSTRA OS DADOS DO PEDIDO DE PESAGEM AVULSA */}
                        {dadosPedido.data.numero ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="numeroPedido">
                              Número do pedido
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="numeroPedido"
                              value={dadosPedido.data.numero}
                              disabled
                              id="numeroPedido"
                            />
                          </div>
                        ) : null}
                        {dadosPedido.data.dataEmissao ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="dataEmissao">Data de emissão</label>
                            <input
                              type="text"
                              className="form-control"
                              name="dataEmissao"
                              value={dadosPedido.data.dataEmissao}
                              disabled
                              id="dataEmissao"
                            />
                          </div>
                        ) : null}
                        {dadosPedido.data.fornecedor ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="fornecedor">
                              Código do fornecedor
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="fornecedor"
                              value={dadosPedido.data.fornecedor}
                              disabled
                              id="fornecedor"
                            />
                          </div>
                        ) : null}
                        {dadosPedido.data.produto ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="fornecedor">
                              Código do produto
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="produto"
                              value={dadosPedido.data.produto}
                              disabled
                              id="produto"
                            />
                          </div>
                        ) : null}
                        {dadosPedido.data.descProduto ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="descProduto">
                              Descrição do produto
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="descProduto"
                              value={dadosPedido.data.descProduto}
                              disabled
                              id="descProduto"
                            />
                          </div>
                        ) : null}
                        {dadosPedido.data.nomeFornecedor ? (
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="descProduto">
                              Nome do fornecedor
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="nomeForncedor"
                              value={dadosPedido.data.nomeFornecedor}
                              disabled
                              id="nomeForncedor"
                            />
                          </div>
                        ) : null}


                        {/* SEÇÃO QUE MOSTRA OS DADOS ADICIONAIS DO TBE - quando pesagem com tbe */}
                        {caminhao?.statusAtual === 34 && (
                          <div className="col-md-12 form-group mb-3">
                            <label htmlFor="CnpjCliente">
                              Dados Adicionais Tb-e
                            </label>
                          </div>
                        )}
                        {loadingDadosAdcTbe ? (
                          <div className="w-100 d-flex justify-content-center">
                            <Spinner animation="border" variant="info" size="sm" />
                          </div>
                        ) : (
                          <>
                            {dadosAdcTbe?.data?.agendamentoFluig?.pedidoVenda?.cliente?.cnpj ? (
                              <div className="col-md-6 form-group mb-3">
                                <label htmlFor="CnpjCliente">
                                  Cnpj Cliente
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="CnpjCliente"
                                  value={cnpjMask(dadosAdcTbe?.data?.agendamentoFluig?.pedidoVenda?.cliente?.cnpj)}
                                  disabled
                                  id="CnpjCliente"
                                />
                              </div>
                            ) : null}
                            {dadosAdcTbe?.data?.agendamentoFluig?.pedidoVenda?.cliente?.nome ? (
                              <div className="col-md-6 form-group mb-3">
                                <label htmlFor="RazaoSocialCliente">
                                  Razão Social Cliente
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="RazaoSocialCliente"
                                  value={dadosAdcTbe?.data?.agendamentoFluig?.pedidoVenda?.cliente?.nome}
                                  disabled
                                  id="RazaoSocialCliente"
                                />
                              </div>
                            ) : null}
                            {dadosAdcTbe?.data?.agendamentoFluig?.transportadora?.razaoSocial ? (
                              <div className="col-md-6 form-group mb-3">
                                <label htmlFor="RazaoSocialTransportadora">
                                  Razão Social Transportadora
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="RazaoSocialTransportadora"
                                  value={dadosAdcTbe?.data?.agendamentoFluig?.transportadora?.razaoSocial}
                                  disabled
                                  id="RazaoSocialTransportadora"
                                />
                              </div>
                            ) : null}
                            {dadosAdcTbe?.data?.agendamentoFluig?.pedidoVenda?.itens?.[0]?.itemCodigo ? (
                              <div className="col-md-6 form-group mb-3">
                                <label htmlFor="CodigoProduto">
                                  Código Produto
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="CodigoProduto"
                                  value={dadosAdcTbe?.data?.agendamentoFluig?.pedidoVenda?.itens?.[0]?.itemCodigo}
                                  disabled
                                  id="CodigoProduto"
                                />
                              </div>
                            ) : null}
                            {dadosAdcTbe?.data?.agendamentoFluig?.fluig?.protocolo ? (
                              <div className="col-md-6 form-group mb-3">
                                <label htmlFor="ProtocoloCarregamento">
                                  Protocolo Carregamento
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ProtocoloCarregamento"
                                  value={dadosAdcTbe.data.agendamentoFluig.fluig.protocolo}
                                  disabled
                                  id="ProtocoloCarregamento"
                                />
                              </div>
                            ) : null}
                            {dadosAdcTbe?.data?.agendamentoFluig?.pedidoVenda?.itens?.[0]?.descricao ? (
                              <div className="col-md-6 form-group mb-3">
                                <label htmlFor="NomeProduto">
                                  Nome Produto
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="NomeProduto"
                                  value={dadosAdcTbe?.data?.agendamentoFluig?.pedidoVenda?.itens?.[0]?.descricao}
                                  disabled
                                  id="NomeProduto"
                                />
                              </div>
                            ) : null}
                            {dadosAdcTbe?.data?.agendamentoFluig?.transportadora?.cnpj ? (
                              <div className="col-md-6 form-group mb-3">
                                <label htmlFor="CnpjTransportadora">
                                  Cnpj Transportadora
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="CnpjTransportadora"
                                  value={cnpjMask(dadosAdcTbe?.data?.agendamentoFluig?.transportadora?.cnpj)}
                                  disabled
                                  id="CnpjTransportadora"
                                />
                              </div>
                            ) : null}
                            {dadosAdcTbe?.data?.md5 ? (
                              <div className="col-md-6 form-group mb-3">
                                <label htmlFor="ChaveTbe">
                                  Chave Tbe
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ChaveTbe"
                                  value={dadosAdcTbe.data.md5}
                                  disabled
                                  id="ChaveTbe"
                                />
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                  <section>
                    <br />
                    <Modal.Title>Fotos</Modal.Title>
                    <div className="custom-separator"></div>
                    <div className="row text-center ">
                      {caminhao.urlImagens !== null &&
                        caminhao.urlImagens.map((info, index) => (
                          <div key={index}>
                            <div className="col">
                              <img
                                src={info}
                                alt="Imagem do caminhão"
                                className="w-75 m-2 rounded cursor-pointer"
                                onClick={() => {
                                  setShowImage(true);
                                  setImage(info);
                                }}
                              />
                            </div>
                            <div className="w-100"></div>
                          </div>
                        ))}
                    </div>
                  </section>
                </>
              ) : (
                <div className="w-100 d-flex justify-content-center">
                  <Spinner animation="border" variant="info" size="sm" />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center">
              <Button
                variant="outline-danger"
                onClick={() => {
                  setDadosadicionaisNF({});
                  switchViewModal();
                  clearPed();
                }}
              >
                Voltar
              </Button>

              <PDFDownloadLink
                document={
                  <PDFTicketPesagem
                    relatorioData={
                      caminhao?.chaveNFS
                        ? relatorioComDadosNFS
                        : relatorioComDadosNFE
                      // relatorioComDadosNFE
                    }
                    pedidoData={
                      dadosPedido.status &&
                      (caminhao?.statusAtual === 100 ||
                        caminhao?.statusAtual === 101
                        ? relatorioComPedido
                        : null)
                    }
                  />
                }
                fileName="Ticket da pesagem.pdf"
              >
                {({ loading }) => (
                  <div
                    onClick={loading || loadingDadosNf ? handleClick : null}
                    style={{
                      cursor: loading || loadingDadosNf ? "auto" : "pointer",
                    }}
                  >
                    <Button
                      variant="info"
                      className="mb-4 my-auto"
                      disabled={loading || loadingDadosNf}
                    >
                      Baixar Ticket PDF
                    </Button>
                  </div>
                )}
              </PDFDownloadLink>

              {caminhao.statusAtual === 34 ? (
                <Button
                  variant="info"
                  className="mb-4 my-auto"
                  disabled={loadingDadosTBe}
                  onClick={() => {
                    getPdfTbe();
                  }}
                >
                  Baixar PDF TB-e
                </Button>
              ) : null}

            </Modal.Footer>
          </Modal>
        </div >
      )}
    </>
  );
};
