import React from "react";
import NavigationBar from "SGP-components/NavigationBar";
import { Outlet } from "react-router";

const WithNav = () => {
  return (
    <>
      <NavigationBar />
      <Outlet />
    </>
  );
};

export default WithNav;
